import { useState, useEffect, useMemo } from 'react';

// * MUI
import { Autocomplete as MuiAutocomplete, Box, TextField } from '@mui/material';

// * Services
import api from '@/services/api';

interface IAutocompleteProps {
  item: IDataFilterSchema;
  data: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  values: any;
  className?: string;
  id: string;
  setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Autocomplete({
  item,
  data,
  setFieldValue,
  values,
  className,
  id,
  setDisabled,
}: IAutocompleteProps): JSX.Element {
  const [conditionalOptions, setConditionalOptions] = useState<string[]>([]);

  const { allowAll, fieldCondition, entity, required, firstOption, firstOptionById, field, label } = item;

  const optionsMemo = useMemo(() => {
    const options = ['', ...(data?.map(({ id }: { id: string }) => String(id)) || [])];

    if (firstOptionById) {
      options.splice(options.indexOf(String(firstOptionById)), 1);
      options.unshift(String(firstOptionById));
    }

    return !item?.allowAll ? options.slice(1) : options;
  }, [data, item, firstOptionById]);

  useEffect(() => {
    if (!fieldCondition) return;

    if (values[fieldCondition]) {
      api
        .get<Array<{ id: string | number }>>(`/filters/${entity}s?${fieldCondition}=${values[fieldCondition]}`)
        .then((res) => setConditionalOptions(res.data.map(({ id }) => String(id))));
    }

    setConditionalOptions((prev) => ['', ...prev]);

    return () => setFieldValue(entity, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.[fieldCondition as string]]);

  useEffect(() => {
    if (!required || !setDisabled) return;
    setDisabled(!values[entity]);
  }, [required, values, entity, setDisabled]);

  useEffect(() => {
    if (!firstOption || values[entity]) return;

    if (fieldCondition) setFieldValue(entity, conditionalOptions[0]);
    else setFieldValue(entity, optionsMemo[0]);
  }, [firstOption, values, fieldCondition, entity, optionsMemo, conditionalOptions, setFieldValue]);

  const handleChange = (_: any, value: string | null) => setFieldValue(name, value ?? '');
  const getOptionLabel = (option: string) =>
    data?.find((dataItem: { id: string | number }) => String(dataItem.id) === String(option))?.name || 'Todos';
  const isOptionEqualToValue = (option: string, value: string) => String(option) === String(value);

  const name = field ?? entity;
  const disableClearable = Object.keys(item).includes('allowAll') && !allowAll;
  const optionsToDisplay = fieldCondition ? conditionalOptions : optionsMemo;

  return (
    <MuiAutocomplete
      disableClearable={disableClearable}
      disablePortal
      onChange={handleChange}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      clearOnBlur
      clearOnEscape
      className={className}
      id={id}
      options={optionsToDisplay}
      value={values[name]}
      key={label + name}
      sx={{ width: 210 }}
      renderInput={(params) => <TextField {...params} name={name} />}
      renderOption={(props, option) => (
        <Box {...props} component="li" key={name + option} id={name} style={{ display: optionsMemo ? 'flex' : 'none' }}>
          {getOptionLabel(option)}
        </Box>
      )}
    />
  );
}

export default Autocomplete;
