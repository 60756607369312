import { ReactElement, cloneElement, ChangeEvent } from 'react';

// * Mui
import { MenuItem, SelectChangeEvent } from '@mui/material';

// * Components
import { ErrorMessage } from '@/components/ErrorMessage';
import { InputWrapper, InputWrapperLabelContainer, InputWrapperContainer } from './components';
import Password from './Password';
import Timepicker from './Timepicker';
import Select from './Select';
import Editor from './Editor';
import Datepicker from './Datepicker';
import Checkbox from './Checkbox';
import Switch from './Switch';
import NumberField from './Number';

// * Hooks & Utils
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Field, FormikValues } from 'formik';

type InputType =
  | 'password'
  | 'select'
  | 'checkbox'
  | 'switch'
  | 'datepicker'
  | 'timepicker'
  | 'editor'
  | 'file'
  | 'number'
  | 'text'
  | 'email'
  | 'url';

type HTMLElementEvent<T extends HTMLElement> = Event & { target: T };

interface IInputOption {
  id: string | number;
  name?: string;
  [key: string]: any;
}

interface IInputPropsCommon {
  name: string;
  field?: string;
  placeholder?: string;
  icon?: ReactElement;
  required?: boolean;
  readOnly?: boolean;
  id?: string;
  label?: string;
  values?: FormikValues;
  handleChange?: any;
  customHandleChange?: any;
  options?: IInputOption[];
  noneOption?: boolean;
  editorState?: EditorState;
  setEditorState?: React.Dispatch<React.SetStateAction<EditorState>>;
  multiple?: boolean;
  excludeEmpty?: boolean;
  order?: string;
  sx?: React.CSSProperties | undefined;
  handleOnInput?: any;
  accept?: string;
  pattern?: string;
}

interface IPickersInputProps extends IInputPropsCommon {
  type: 'datepicker' | 'timepicker';
  handleChange: (newValue: Date | null) => void;
}

interface IEditorInputProps extends IInputPropsCommon {
  type: 'editor';
  customHandleChange: (content: string) => void;
}

interface ISelectInputProps extends IInputPropsCommon {
  type: 'select';
  customHandleChange?: (e: SelectChangeEvent<any>) => void;
}

interface ISwitchInputProps extends IInputPropsCommon {
  type: 'switch';
  customHandleChange?: (e: ChangeEvent<any>) => void;
}

interface INumberInputProps extends IInputPropsCommon {
  type: 'number';
  handleOnInput?: (e: HTMLElementEvent<any>) => void;
}

interface IInputProps extends IInputPropsCommon {
  type: Exclude<InputType, 'datepicker' | 'timepicker' | 'select' | 'switch' | 'editor' | 'number'>;
}

type InputProps =
  | IPickersInputProps
  | ISelectInputProps
  | ISwitchInputProps
  | IEditorInputProps
  | INumberInputProps
  | IInputProps;

const Input = ({
  type,
  name,
  field,
  placeholder,
  icon,
  required,
  readOnly,
  id,
  label,
  values,
  handleChange,
  customHandleChange,
  options = [],
  noneOption,
  editorState,
  setEditorState,
  multiple,
  excludeEmpty,
  order,
  sx,
  handleOnInput,
  accept,
  pattern,
}: InputProps) => {
  const types = ['password', 'select', 'checkbox', 'switch', 'datepicker', 'timepicker', 'editor', 'file', 'number'];
  const hasIcon = !(icon == null);
  const Icon = hasIcon && cloneElement(icon, { className: 'form-input-icon' });

  let sortedOptions = options;

  if (type === 'select' && order)
    sortedOptions = options.sort((a, b) => (a[order] < b[order] ? -1 : a[order] > b[order] ? 1 : 0));

  const commonProps = {
    required: !!required,
    disabled: !!readOnly,
    name,
    id,
    placeholder,
  };

  const pickersProps = {
    value: values?.[name],
    setValue: handleChange as IPickersInputProps['handleChange'],
  };

  return (
    <InputWrapper style={sx}>
      {label && (
        <InputWrapperLabelContainer>
          <label htmlFor={id}>{label}</label>
        </InputWrapperLabelContainer>
      )}

      <InputWrapperContainer className={type}>
        {hasIcon && Icon}

        {type === 'password' && <Password name={name} placeholder={placeholder ?? ''} />}

        {type === 'select' && (
          <Select
            {...commonProps}
            multiple={multiple}
            value={values?.[name] || ''}
            onChange={(e) => {
              const value = e.target.value;
              if (handleChange) handleChange(name, value);
              if (customHandleChange) customHandleChange(e);
            }}
          >
            {!excludeEmpty && <MenuItem value="" />}

            {noneOption && (
              <MenuItem value="none">
                <em>Nenhum</em>
              </MenuItem>
            )}

            {sortedOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {field ? option[field] : option.name}
              </MenuItem>
            ))}
          </Select>
        )}

        {type === 'checkbox' && (
          <Checkbox
            {...commonProps}
            checked={values?.[name]}
            onChange={(e) => {
              if (handleChange) handleChange(name, e);

              if (customHandleChange) customHandleChange(e);
            }}
          />
        )}

        {type === 'switch' && (
          <Switch
            {...commonProps}
            checked={values?.[name]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (handleChange) handleChange(name, e);

              if (customHandleChange) customHandleChange(e);
            }}
          />
        )}

        {type === 'datepicker' && <Datepicker {...commonProps} {...pickersProps} />}

        {type === 'timepicker' && <Timepicker {...commonProps} {...pickersProps} />}

        {type === 'editor' && (
          <Editor
            editorState={editorState}
            readOnly={!!readOnly}
            onEditorStateChange={(newState) => {
              setEditorState?.(newState);
              const value = draftToHtml(convertToRaw(newState.getCurrentContent()));
              customHandleChange(value);
            }}
          />
        )}

        {type === 'file' && (
          <input
            {...commonProps}
            type="file"
            accept={accept}
            multiple={!!multiple}
            placeholder={placeholder}
            className="form-input"
            onChange={handleChange}
          />
        )}

        {type === 'number' && <NumberField {...commonProps} onInput={handleOnInput} />}

        {!types.includes(type) && (
          <Field {...commonProps} type={type} className="form-input" {...(type === 'url' && { pattern })} />
        )}
      </InputWrapperContainer>

      <ErrorMessage name={name} />
    </InputWrapper>
  );
};

export { Input, InputWrapper, InputWrapperLabelContainer, InputWrapperContainer };
