import { styled } from '@mui/system';

export const Wrapper = styled('div')({ padding: '1.4rem', display: 'flex', flexDirection: 'column' });

export const Head = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '0.8rem',
  borderBottom: '1px solid #E1E1E1',
  marginBottom: '1.4rem',
});

export const Title = styled('div')({ fontSize: '1.4rem', fontWeight: 700 });

export const Container = styled('div')({
  padding: '0 10rem 0 0',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  marginBottom: '2.4rem',
  gap: '1rem',
});

interface IItemProps {
  fullWidth?: boolean;
}

export const Item = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<IItemProps>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
  gap: '0.4rem',
  ...(props.fullWidth && { gridColumn: 'span 3', flexDirection: 'column' }),
}));

export const Label = styled('div')({ textTransform: 'capitalize', fontSize: '1.2rem', fontWeight: 600 });

export const Value = styled('div')({ fontSize: '1.2rem', fontWeight: 400 });
