/**
 * Handler that convert two date times to string diff
 * @param startDate
 * @param finishDate
 * @return {number}
 */

export const formatShortDateString = (date: Date) =>
  new Date(date).toLocaleString('pt-BR', {
    timeStyle: 'short',
    dateStyle: 'short',
  });

export const stringDiffBetweenTwoDateTimes = (startDate: string | Date, finishDate: string | Date): string => {
  const start = new Date(startDate);
  const finish = new Date(finishDate);

  return `${formatShortDateString(start)} até ${formatShortDateString(finish)}`;
};
