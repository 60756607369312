// * Mui
import { Checkbox as _Checkbox, CheckboxProps } from '@mui/material';

function Checkbox({ required, disabled, name, id, checked, onChange }: CheckboxProps): JSX.Element {
  return (
    <_Checkbox
      required={required}
      disabled={disabled}
      name={name}
      className="form-input"
      id={id}
      checked={checked}
      onChange={onChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}

export default Checkbox;
