// * MUI
import { Close, Delete, Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Modal, IconButton, Button } from '@mui/material';

// * Components
import { DeleteModalContainer } from './components';

interface IDeleteModalProps {
  toggleDelete: any;
  handleClose: () => void;
  fieldName: string;
  handleDelete: () => void;
  loading: boolean;
}

export const DeleteModal = ({ toggleDelete, handleClose, fieldName, handleDelete, loading }: IDeleteModalProps) => (
  <Modal open={!!toggleDelete} onClose={handleClose} style={{ display: 'grid', placeItems: 'center' }}>
    <DeleteModalContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          // color: '#000000',
          color: '#17162E',
          marginBottom: '1.4rem',
        }}
      >
        <h1 style={{ margin: 0 }}>Deletar {toggleDelete?.[fieldName]}?</h1>

        <div>
          <IconButton
            onClick={handleClose}
            sx={{
              'transition': '0.2s ease-in',
              'cursor': 'pointer',
              // 'color': '#000000',
              'color': '#17162E',
              'svg': { fontSize: '1.8rem' },
              '&:hover': { transition: '0.2s ease-in', color: 'hsl(0, 60%, 65%)' },
            }}
          >
            <Close />
          </IconButton>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingBottom: '1rem',
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        <div>Tem certeza que deseja deletar {toggleDelete?.[fieldName]}?</div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '2rem' }}>
        <LoadingButton
          onClick={handleDelete}
          loading={loading}
          loadingPosition="center"
          variant="contained"
          startIcon={<Delete />}
        >
          Deletar
        </LoadingButton>

        <Button variant="outlined" onClick={handleClose} startIcon={<Cancel />}>
          Cancelar
        </Button>
      </div>
    </DeleteModalContainer>
  </Modal>
);
