// * MUI
import { Button } from '@mui/material';
import { Check } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';

export const requestsColDef = (renderSelect: (rowId: string) => void): GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
    editable: false,
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'user',
    headerName: 'Solicitante',
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    renderCell: ({ value }) => <>{value && value.name}</>,
  },
  {
    field: 'title',
    headerName: 'Título',
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    renderCell: ({ value }) => <>{value && value}</>,
  },
  {
    field: 'priority',
    headerName: 'Prioridade',
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    renderCell: ({ value }) => <>{value && value.name}</>,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    cellClassName: 'color-cell',
    headerAlign: 'center',
    align: 'center',
    editable: false,
    sortable: true,
    filterable: false,
    renderCell: ({ value }) => (
      <div
        className="jobs-item-status"
        style={{ backgroundColor: value.color, overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        <p style={{ color: 'white' }}>{value.name}</p>
      </div>
    ),
  },
  {
    field: 'date_created',
    headerName: 'Data da solicitação',
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    renderCell: ({ value }) => <>{value ? new Date(value).toLocaleString() : ''}</>,
  },
  {
    field: 'select',
    headerName: 'Selecionar',
    width: 200,
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    disableExport: true,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => (
      <Button variant="contained" color="primary" size="small" endIcon={<Check />} onClick={() => renderSelect(row.id)}>
        Selecionar
      </Button>
    ),
  },
];
