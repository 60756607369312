import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Dashboards = loadable(async () => import('pages/app/Dashboard'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      home: components.DashboardHome,
      dev: components.DashboardDev,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `dashboards-${props.page}`,
});
