// * MUI
import { Android, Apple } from '@mui/icons-material';

// * Components
import { Button, Container, Content, Logo } from './components';

// * Icons
import { ReactComponent as Avanti } from '@/assets/images/avanti-long.svg';

interface IMobileProps {
  os: 'AndroidOS' | 'iOS';
  link: string;
}

export const Mobile = ({ os, link }: IMobileProps) => {
  const redirectStore = () => {
    window.location.href = link;
  };

  const osIcon = { AndroidOS: <Android />, iOS: <Apple /> };

  return (
    <Container>
      <Logo>
        <Avanti />
      </Logo>

      <Content>
        <h1>Avanti App</h1>

        <p>Baixe o aplicativo da Avanti e obtenha os recursos atualizados gratuitamente.</p>

        <Button variant="contained" endIcon={osIcon[os]} onClick={redirectStore}>
          Baixar para {os}
        </Button>
      </Content>
    </Container>
  );
};
