import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Jobs = loadable(async () => import('pages/app/Data'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      'home': components.DataJobsHome,
      'details': components.DataJobsDetails,
      'add': components.DataJobsAdd,
      'edit': components.DataJobsEdit,
      'duplicate': components.DataJobsDuplicate,
      'import': components.DataJobsImport,
      'add-with-request': components.DataJobsAddWithRequest,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `jobs-${props.page}`,
});
