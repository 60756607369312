// * Hooks & Utils
import { convertFromHTML, ContentState, EditorState } from 'draft-js';

/**
 * Handler that convert HTML blocks into draft editor state
 * @param html
 */

export const HTMLToDraft = (html: string) => {
  const blocksFromHTML = convertFromHTML(html);
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

  return EditorState.createWithContent(state);
};
