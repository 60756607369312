// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { UseMutationResult } from 'react-query';

// * Services
import { StartResponse, StartProps, DoResponse, DoProps, FinishResponse, FinishProps } from '@/services';
import { uploadFile } from './uploadFile';
import { AxiosProgressEvent } from 'axios';

type StartMutation = UseMutationResult<StartResponse, unknown, StartProps, unknown>;
type DoMutation = UseMutationResult<DoResponse, unknown, DoProps, unknown>;
type FinishMutation = UseMutationResult<FinishResponse, unknown, FinishProps, unknown>;

interface IUploadFilesProps {
  endpointName: 'app' | 'job' | 'request' | 'service';
  endpointId: number | undefined;
  startMutation: StartMutation;
  doMutation: DoMutation;
  finishMutation: FinishMutation;
  snackbar: ICustomSnackbar;
  onError: (error: unknown) => void;
  onFinish: () => void;
  files: File[];
  name: string;
}

export const uploadFiles = async ({
  endpointName,
  endpointId,
  startMutation,
  doMutation,
  finishMutation,
  snackbar,
  onError,
  onFinish,
  files,
  name,
}: IUploadFilesProps) => {
  const filesProgress = Array.from({ length: files.length }, () => 0);

  for (let i = 0, len = files.length; i < len; i++) {
    const file = files[i];

    const doProgressCallback = (progressEvent: AxiosProgressEvent) => {
      const fileProgress = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 0));
      filesProgress[i] = fileProgress;

      const totalProgress = Math.round(filesProgress.reduce((a, b) => a + b, 0) / files.length);
      snackbar.showInfo(`Enviando screenshot(s)... ${totalProgress}%`);
    };

    await uploadFile({
      endpointName,
      endpointId,
      startMutation,
      doMutation,
      finishMutation,
      doProgressCallback,
      onError,
      file,
      name,
    });

    if (i === len - 1) onFinish();
  }
};
