// * MUI
import { Box, Drawer as MuiDrawer, styled } from '@mui/material';

const common = { '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 'auto' } };

export const Drawer = styled(MuiDrawer)((props) => ({
  ...common,
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'block',
  [props.theme.breakpoints.up('md')]: { display: 'none' },
}));

interface MenuContainerProps {
  active?: boolean;
}

export const MenuContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<MenuContainerProps>((props) => ({
  ...common,
  'position': 'absolute',
  'zIndex': 16,
  'height': 'calc(100% - 55px)',
  'marginTop': 55,
  'marginBottom': 55,
  'left': 0,
  'minWidth': 55,
  'maxWidth': 55,
  'width': 300,
  'backgroundColor': '#FEFEFE',
  'borderRight': '1px solid #E1E1E1',
  'overflowX': 'hidden',
  'overflowY': 'auto',
  'transition': props.theme.transitions.easing.easeOut,
  'boxShadow': '0px 0px 5px rgba(0, 0, 0, 0.2)',
  'display': 'none',
  [props.theme.breakpoints.up('md')]: { display: 'block' },
  '&::-webkit-scrollbar': { width: 5 },
  '&::-webkit-scrollbar-track': { background: '#F1F1F1' },
  '&::-webkit-scrollbar-thumb': { background: '#888' },
  '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
  ...(props.active && { maxWidth: 260, transition: '0.3s ease-in' }),
}));
