// * MUI
import { styled } from '@mui/material';

// * Formik
import { Form as FormikForm } from 'formik';

export const DataGridFilterContainer = styled('div')({
  padding: '0.4rem 2rem',
  border: '1px solid rgba(224, 224, 224, 1)',
  borderBottom: 0,
});

export const DataGridFilterContainerTitle = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: 40,
  alignItems: 'center',
  justifyContent: 'space-between',
  label: { fontSize: '1.1rem', fontWeight: 500 },
});

interface IDataGridFilterContainerContentProps {
  active?: boolean;
}

export const DataGridFilterContainerContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<IDataGridFilterContainerContentProps>((props) => ({
  [props.theme.breakpoints.down('md')]: { ...(props.active && { overflow: 'auto', maxHeight: 200 }) },
  ...(props.active
    ? {
        overflow: 'hidden',
        transition: '0.4s',
        maxHeight: 300,
        display: 'block',
      }
    : { maxHeight: 0, display: 'none' }),
  '.MuiAutocomplete-popupIndicator': { width: 28, height: 28, margin: '0 0.2rem' },
}));

export const DataGridFilterContainerContentForm = styled(FormikForm)({
  'height': 'auto',
  'display': 'flex',
  'flexDirection': 'row',
  'alignItems': 'center',
  'justifyContent': 'flex-start',
  'flexWrap': 'wrap',
  'gap': '0 1rem',
  ':.MuiLoadingButton-root': { height: 40 },
  '.MuiFormControl-root, .MuiAutocomplete-root': { width: '100% !important', minWidth: 275 },
  '.MuiOutlinedInput-notchedOutline': { borderWidth: '0 !important', outline: 'none' },
  '.MuiOutlinedInput-input': { padding: '0 1rem !important' },
});

export const DataGridFilterContainerContentFormInput = styled('div')({ width: 275 });

export const DataGridFilterContainerContentFormInputWrapper = styled('div')({ margin: '0.4rem 0' });

export const DataGridFilterContainerContentFormInputWrapperContainer = styled('div')({
  'backgroundColor': '#FEFEFE',
  'width': '100%',
  'height': 40,
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'border': '1px solid #E1E1E1',
  'borderRadius': 4,
  '&.switch, &.checkbox': { width: 'max-content', height: 40, margin: 0, border: 'none' },
  '&.editor': { height: 'max-content' },
  '&.editor, &.datepicker, &.timepicker': { justifyContent: 'flex-start' },
  'input': {
    'border': 'none',
    'borderLeft': '1px solid #E1E1E1',
    'width': '100%',
    'height': '100%',
    'paddingLeft': '0.8rem',
    'fontSize': 16,
    '&::placeholder': { color: '#9A9E9E' },
  },
  '.css-i4bv87-MuiSvgIcon-root': {
    margin: '0 0.8rem',
    width: 18,
    height: '100%',
    color: '#262D33',
  },
  '.input-toggle-password': {
    'cursor': 'pointer',
    'margin': 0,
    'width': 40,
    'height': 38,
    'padding': 10,
    'transition': '0.1s ease-in',
    '&:hover': { backgroundColor: '#F9F9F9', transition: '0.1s ease-in' },
  },
});

export const DataGridFilterContainerContentFormActions = styled('div')(({ theme }) => ({
  'display': 'flex',
  'gap': '1rem',
  'justifyContent': 'flex-end',
  'flexGrow': 1,
  [theme.breakpoints.down('md')]: { marginTop: '1rem', marginBottom: '1rem', justifyContent: 'center' },
  '.MuiLoadingButton-root': { height: 40 },
}));
