// * Hooks & Utils
import { Field } from 'formik';

type HTMLElementWheelEvent<T extends HTMLElement> = WheelEvent & { target: T };

interface IProps {
  required?: boolean;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  onInput?: any;
  id?: string;
}

function Number({ required, disabled, name, placeholder, onInput, id }: IProps): JSX.Element {
  return (
    <Field
      as="input"
      type="number"
      required={required}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      onInput={onInput}
      onWheel={(e: HTMLElementWheelEvent<HTMLInputElement>) => {
        if (e.target.type === 'number' && e.target.classList.contains('noscroll')) e.target.blur();
      }}
      className="form-input noscroll"
      id={id}
    />
  );
}

export default Number;
