// * Mui
import { Switch as _Switch, SwitchProps } from '@mui/material';

// * Hooks & Utils
import { brazilianizeBooleans } from '@/helpers';

function Switch({ required, readOnly, name, id, checked, onChange }: SwitchProps): JSX.Element {
  return (
    <>
      <_Switch
        required={required}
        disabled={readOnly}
        name={name}
        className="form-input"
        id={id}
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      {`${brazilianizeBooleans(!!checked)}`}
    </>
  );
}

export default Switch;
