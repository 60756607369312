// * React-Router-Dom
import { Navigate, Route } from 'react-router-dom';

// * Pages
import {
  Accounts,
  Apps,
  Bonus,
  Customers,
  Dashboards,
  Events,
  FAQs,
  Groups,
  Jobs,
  Packages,
  Posts,
  Projects,
  Reports,
  Requests,
  RequestsPackage,
  RequestsProject,
  Schedules,
  Services,
  Status,
  Users,
  WalletPackages,
} from './pages';

const commonRoutes = (
  <Route>
    <Route path="/" element={<Dashboards page="home" />} />

    <Route path="/dev" element={<Dashboards page="dev" />} />

    <Route path="/requests">
      <Route index element={<Navigate to="/" />} />
      <Route path="all" element={<Requests page="all" />} />
      <Route path="new" element={<Requests page="add" />} />

      <Route path="package">
        <Route index element={<RequestsPackage page="home" />} />

        <Route path=":packageId">
          <Route index element={<RequestsPackage page="requests" />} />
          <Route path="details/:requestId" element={<RequestsPackage page="details" />} />
          <Route path="new" element={<RequestsPackage page="add" />} />
          <Route path="edit/:requestId" element={<RequestsPackage page="edit" />} />
        </Route>
      </Route>

      <Route path="project">
        <Route index element={<RequestsProject page="home" />} />
        <Route path=":projectId">
          <Route index element={<RequestsProject page="requests" />} />
          <Route path="details/:requestId" element={<RequestsProject page="details" />} />
          <Route path="new" element={<RequestsProject page="add" />} />
          <Route path="edit/:requestId" element={<RequestsProject page="edit" />} />
        </Route>
      </Route>
    </Route>

    <Route path="profile">
      <Route index element={<Accounts page="profile" />} />
      <Route path="change-password" element={<Accounts page="change-password" />} />
      <Route path="create-password" element={<Accounts page="create-password" />} />
    </Route>
  </Route>
);

const eventsRoutes = <Route path="/events" element={<Events page="home" />} />;

const customersRoutes = (
  <Route path="/customers">
    <Route index element={<Customers page="home" />} />
    <Route path="details/:id" element={<Customers page="details" />} />
    <Route path="new" element={<Customers page="add" />} />
    <Route path="edit/:id" element={<Customers page="edit" />} />
  </Route>
);

const statusRoutes = (
  <Route path="/status">
    <Route index element={<Status page="home" />} />
    <Route path="details/:id" element={<Status page="details" />} />
  </Route>
);

const packagesRoutes = (
  <Route path="/packages">
    <Route index element={<Packages page="home" />} />
    <Route path="details/:id" element={<Packages page="details" />} />
    <Route path="new" element={<Packages page="add" />} />
    <Route path="edit/:id" element={<Packages page="edit" />} />
  </Route>
);

const walletPackagesRoutes = (
  <Route path="/walletpackages">
    <Route index element={<WalletPackages page="home" />} />
    <Route path="details/:id" element={<WalletPackages page="details" />} />
    <Route path="new" element={<WalletPackages page="add" />} />
    <Route path="edit/:id" element={<WalletPackages page="edit" />} />
  </Route>
);

const bonusRoutes = (
  <Route path="/bonus">
    <Route index element={<Bonus page="home" />} />
    <Route path="details/:id" element={<Bonus page="details" />} />
  </Route>
);

const usersRoutes = (
  <Route path="/users">
    <Route index element={<Users page="home" />} />
    <Route path="details/:id" element={<Users page="details" />} />
    <Route path="new" element={<Users page="add" />} />
    <Route path="edit/:id" element={<Users page="edit" />} />
  </Route>
);

const groupsRoutes = (
  <Route path="/groups">
    <Route index element={<Groups page="home" />} />
  </Route>
);

const projectsRoutes = (
  <Route path="/projects">
    <Route index element={<Projects page="home" />} />
    <Route path="details/:id" element={<Projects page="details" />} />
    <Route path="new" element={<Projects page="add" />} />
    <Route path="edit/:id" element={<Projects page="edit" />} />
  </Route>
);

const reportsRoutes = (
  <Route path="/reports">
    <Route path="note" element={<Reports page="notes" />} />
    <Route path="customer" element={<Reports page="customer" />} />
    <Route path="job" element={<Reports page="jobs" />} />
    <Route path="package" element={<Reports page="packages" />} />
    <Route path="insights">
      <Route index element={<Reports page="insights-home" />} />
      <Route path=":id" element={<Reports page="insights-report" />} />
    </Route>
  </Route>
);

const schedulesRoutes = (
  <Route path="/schedules">
    <Route path="production" element={<Schedules page="production" />} />
    <Route path="deadline" element={<Schedules page="deadline" />} />
  </Route>
);

const jobsRoutes = (
  <Route path="/jobs">
    <Route index element={<Jobs page="home" />} />
    <Route path="details/:id" element={<Jobs page="details" />} />
    <Route path="import" element={<Jobs page="import" />} />
    <Route path="new">
      <Route index element={<Jobs page="add" />} />
      <Route path="request">
        <Route path=":requestId" element={<Jobs page="add-with-request" />} />
      </Route>
    </Route>

    <Route path="edit/:id" element={<Jobs page="edit" />} />
    <Route path="duplicate/:id" element={<Jobs page="duplicate" />} />
  </Route>
);

const appsRoutes = (
  <Route path="/apps">
    <Route index element={<Apps page="home" />} />
    <Route path="details/:id" element={<Apps page="details" />} />
    <Route path="new" element={<Apps page="add" />} />
    <Route path="edit/:id" element={<Apps page="edit" />} />
  </Route>
);

const faqsRoutes = (
  <Route path="/faqs">
    <Route index element={<FAQs page="home" />} />
    <Route path="details/:id" element={<FAQs page="details" />} />
    <Route path="new" element={<FAQs page="add" />} />
    <Route path="edit/:id" element={<FAQs page="edit" />} />
  </Route>
);

const postsRoutes = (
  <Route path="/posts">
    <Route index element={<Posts page="home" />} />
    <Route path="details/:id" element={<Posts page="details" />} />
    <Route path="new" element={<Posts page="add" />} />
    <Route path="edit/:id" element={<Posts page="edit" />} />
  </Route>
);

const servicesRoutes = (
  <Route path="/services">
    <Route index element={<Services page="home" />} />
    <Route path="details/:id" element={<Services page="details" />} />
    <Route path="new" element={<Services page="add" />} />
    <Route path="edit/:id" element={<Services page="edit" />} />
  </Route>
);

const routes = [
  commonRoutes,
  eventsRoutes,
  customersRoutes,
  statusRoutes,
  packagesRoutes,
  walletPackagesRoutes,
  bonusRoutes,
  usersRoutes,
  groupsRoutes,
  projectsRoutes,
  reportsRoutes,
  schedulesRoutes,
  jobsRoutes,
  appsRoutes,
  faqsRoutes,
  postsRoutes,
  servicesRoutes,
];

export const appRoutes = routes;
