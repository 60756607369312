import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Schedules = loadable(async () => import('pages/app/Schedule'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      deadline: components.ScheduleDeadline,
      production: components.ScheduleProduction,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `schedules-${props.page}`,
});
