// * MUI
import { TableContainer, TableHead, TableRow, TableCell, TableBody, Table as MuiTable } from '@mui/material';

// * Components
import EditCell from './EditCell';
import CustomTableCell from './CustomTableCell';

// * Interfaces
import type { IContract } from '.';

interface ITableProps {
  contracts: IContract[];
  onChange: (name: keyof IContract, value: string | number | Date | boolean | null, id: number) => void;
  deleteContract: (id: number, isNew: boolean) => void;
  onEdit: (row: IContract) => void;
}

export const Table = ({ contracts, onChange, deleteContract, onEdit }: ITableProps) => {
  const tableHeader: { label: string; data: keyof IContract }[] = [
    { label: 'Horas', data: 'hours' },
    { label: 'Valor da hora', data: 'hour_price' },
    { label: 'Valor da hora extra', data: 'hour_price_extra' },
    { label: 'A partir de', data: 'date' },
    { label: 'Até', data: 'validity' },
    { label: 'É indeterminado?', data: 'is_indeterminate' },
  ];

  return (
    <TableContainer>
      <MuiTable>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            {tableHeader.map((cell) => (
              <TableCell key={cell.data}>{cell.label}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {contracts.map((row, index) => {
            const isLastContract = index === contracts.length - 1;

            const isRowWithoutHours = Object.entries(row).some(([key, value]) => key === 'hours' && value === 0);
            const isOnEditModeAndAnyNumberNegative =
              row.isEditMode &&
              (Number(row.hours) < 0 || Number(row.hour_price) < 0 || Number(row.hour_price_extra) < 0);
            const isEdittingDifferentContract = contracts.some(
              (contract) => contract.isEditMode && contract.id !== row.id,
            );
            const isOnEditModeAndNotIndeterminateAndWithoutValidity =
              row.isEditMode && !row.is_indeterminate && !row.validity;

            const disabledRow =
              isRowWithoutHours ||
              isOnEditModeAndAnyNumberNegative ||
              isEdittingDifferentContract ||
              isOnEditModeAndNotIndeterminateAndWithoutValidity;

            return (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>

                {tableHeader.map((cell) => (
                  <CustomTableCell
                    key={cell.data}
                    contractsLength={contracts.length}
                    row={row}
                    name={cell.data}
                    onChange={onChange}
                    isEditable={contracts[contracts.length - 1].id === row.id}
                  />
                ))}

                <EditCell
                  contractsLength={contracts.length}
                  row={row}
                  onEdit={() => onEdit(row)}
                  onDelete={() => deleteContract(row.id, row.isNew)}
                  disabled={disabledRow}
                  isLastContract={isLastContract}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
