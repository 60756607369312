import { RequestPaginated, ProjectData, ProjectFilterData, ProjectPostData, ProjectPutData } from '@/models';
import { sortBetweenName } from '@/helpers';
import api from '@/services/api';

const ENDPOINT = '/projects';

export const fetchProjects = async (page = 0, pageSize = 10, filters?: any): Promise<RequestPaginated<ProjectData>> => {
  const params = filters ? `&${new URLSearchParams(filters).toString()}` : '';
  const { data } = await api.get(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}${params}`);
  return data;
};

export const fetchProjectsToFilter = async (): Promise<ProjectFilterData[]> => {
  const { data } = await api.get('/filters/projects');
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const fetchProjectById = async (id: number): Promise<ProjectData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

export const fetchProjectsByCustomerId = async (customerId: number): Promise<ProjectData[]> => {
  const { data } = await api.get(`${ENDPOINT}?customer=${customerId}&paginate=false`);
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const fetchActiveProjectsByCustomerId = async (customerId: number): Promise<ProjectData[]> => {
  const { data } = await api.get(`${ENDPOINT}?customer=${customerId}&is_active=true&paginate=false`);
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const createProject = async (data: ProjectPostData): Promise<ProjectData> => {
  const { data: response } = await api.post(ENDPOINT, data);
  return response;
};

export const updateProject = async (data: ProjectPutData): Promise<ProjectData> => {
  const { data: response } = await api.put(`${ENDPOINT}/${data.id}`, data);
  return response;
};
