// * MUI
import { styled } from '@mui/material';

export const HeaderContainer = styled('div')({
  zIndex: 24,
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  // backgroundColor: '#000000',
  backgroundColor: '#17162E',
  boxShadow: '0px 0px 5px rgb(0 0 0 / 20%)',
});

const center = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

export const HeaderContainerStart = styled('div')({ ...center });

export const HeaderContainerStartLogo = styled('div')({
  ...center,
  width: 'calc(90px + 1rem)',
  height: 55,
  paddingLeft: '1rem',
});

export const HeaderContainerEnd = styled('div')({ ...center, padding: '0 10px', height: 55 });

const active = (transition: string) => ({
  // backgroundColor: '#00244F',
  backgroundColor: '#3F19FF',
  transition,
  borderRadius: '0%',
});

interface IHeaderContainerStartMenuIconContainerProps {
  active?: boolean;
}

export const HeaderContainerStartMenuIconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<IHeaderContainerStartMenuIconContainerProps>((props) => ({
  ...center,
  'width': 54,
  'height': 55,
  'cursor': 'pointer',
  'color': '#E1E1E1',
  'borderRight': 'none',
  'transition': props.theme.transitions.easing.easeIn,
  'borderRadius': '50%',
  '&:hover': { ...active(props.theme.transitions.easing.easeIn) },
  ...(props.active && { ...active(props.theme.transitions.easing.easeIn) }),
}));

export const HeaderContainerStartMenuIconContainerContent = styled('div')({
  width: 26,
  height: 24,
  padding: '4px 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const common = { width: 26, height: 2, borderRadius: 4, backgroundColor: '#E1E1E1' };

const after = {
  ...common,
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  transition: '1s cubic-bezier(0.6, -0.28, 0, 1.5)',
};

interface IHeaderContainerStartMenuIconContainerContentBarProps {
  active?: boolean;
}

export const HeaderContainerStartMenuIconContainerContentBar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<IHeaderContainerStartMenuIconContainerContentBarProps>((props) => ({
  ...common,
  'transition': '1s cubic-bezier(0.6, -0.28, 0, 1.5)',
  '&:nth-of-type(2)': {
    'position': 'relative',
    'transform': 'rotate(0deg)',
    '&::after': { ...after, transform: 'rotate(0deg)' },
  },
  ...(props.active && {
    '&:nth-of-type(1), &:nth-of-type(3)': { backgroundColor: 'transparent' },
    '&:nth-of-type(2)': { 'transform': 'rotate(225deg)', '&::after': { ...after, transform: 'rotate(270deg)' } },
  }),
}));
