/**
 * Handler to convert minutes to hours and minutes
 * @param totalMinutes
 * @returns "HH:MM"
 */

const padTo2Digits = (num: number) => num.toString().padStart(2, '0');

export const toHoursAndMinutes = (totalMinutes: number) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}h:${padTo2Digits(minutes)}m`;
};
