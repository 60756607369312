import { NoteReportData, ReportGenerateData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/reports/note';

type ReportNoteFilters = {
  start: string;
  finish: string;
  customer?: string;
  project?: string;
  package?: string;
  status?: string;
  responsible?: string;
};

export const fetchReportNote = async (filters: ReportNoteFilters): Promise<NoteReportData[]> => {
  const params = `?${String(new URLSearchParams(filters))}`;
  const { data } = await api.get(`${ENDPOINT}${params}`);
  return data;
};

export const fetchGenerateReportNote = async (filters: ReportNoteFilters): Promise<ReportGenerateData> => {
  const params = `?${String(new URLSearchParams(filters))}&generate=true`;
  const { data } = await api.get(`${ENDPOINT}${params}`);
  return data;
};
