// * MUI
import { styled } from '@mui/material';

export const SpanBolder = styled('span')({ fontWeight: 'bolder', fontStyle: 'italic', color: '#292c2e' });

export const SpanMedium = styled('span')({ fontSize: 12, fontStyle: 'italic', fontWeight: 'medium', color: '#707980' });

interface IPrimaryItemProps {
  firstNode: React.ReactNode;
  secondNode: React.ReactNode;
}

export const PrimaryItem = ({ firstNode, secondNode }: IPrimaryItemProps) => (
  <>
    <SpanBolder>{firstNode}</SpanBolder> - <SpanMedium>{secondNode}</SpanMedium>
  </>
);
