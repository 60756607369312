import * as Yup from 'yup';

/**
 * ? Validation schema for job
 */

export const jobValidation = Yup.object().shape(
  {
    customer: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('O campo cliente é obrigatório.'),
    project: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when('package', (val, schema) =>
        val === undefined || val === null || val === ''
          ? schema.required('Deve ser informado um pacote ou um projeto.')
          : schema,
      ),
    package: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when('project', (val, schema) =>
        val === undefined || val === null || val === ''
          ? schema.required('Deve ser informado um pacote ou um projeto.')
          : schema,
      ),
    request: Yup.number()
      .required('O campo solicitação é obrigatório.')
      .transform((value) => (isNaN(value) ? undefined : value)),
    package_year: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when('package', (val, schema) => (val ? schema.required('Deve ser informado o ano do pacote.') : schema)),
    package_month: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when('package', (val, schema) => (val ? schema.required('Deve ser informado o mês do pacote.') : schema)),
    requester: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('O campo requisitante é obrigatório.'),
    responsible: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('O campo responsável é obrigatório.'),
    start: Yup.date()
      .nullable()
      .typeError('Data inválida.')
      .min(new Date('1970-01-01'), 'A data deve ser maior que 1970.')
      .required('O campo início é obrigatório.')
      .when('internal_term', (val, schema) =>
        val
          ? schema.max(Yup.ref('internal_term'), 'A data de início deve ser menor que a data do prazo interno.')
          : schema,
      ),
    publication_date: Yup.date().nullable(),
    estimated_time_hours: Yup.number(),
    estimated_time_minutes: Yup.number()
      .min(parseInt('00'), 'O campo minutos deve ser maior ou igual a 00.')
      .max(59, 'O campo minutos deve ser menor ou igual a 59.')
      .transform((value) => (value === parseInt('00') ? undefined : value))
      .when('estimated_time_hours', (val, schema) =>
        val === 0
          ? schema.required('Quando o tempo estimado em horas é menor que 1, o campo minutos é obrigatório.')
          : schema,
      ),
    package_consumption_hours: Yup.number()
      .when(['project', 'bonus', 'budget', 'package_consumption_minutes'], {
        is: (project: any, bonus: any, budget: any, package_consumption_minutes: any) =>
          !project && !bonus && !budget && !package_consumption_minutes,
        then: (schema) => schema.positive('O campo consumo de pacote deve ser um número positivo.'),
      })
      .nullable(),
    package_consumption_minutes: Yup.number()
      .min(parseInt('00'), 'O campo minutos deve ser maior ou igual a 00.')
      .max(59, 'O campo minutos deve ser menor ou igual a 59.')
      .transform((value) => (value === parseInt('00') ? undefined : value))
      .when(['project', 'bonus', 'budget', 'package_consumption_hours'], {
        is: (project: any, bonus: any, budget: any, package_consumption_hours: any) =>
          !project && !bonus && !budget && !package_consumption_hours,
        then: (schema) =>
          schema.required('Quando o tempo estimado em minutos é menor que 1, o campo horas é obrigatório.'),
      }),
    internal_term: Yup.date()
      .required('O campo prazo interno é obrigatório.')
      .min(new Date('1970-01-01'), 'A data deve ser maior que 1970.')
      .when(
        'customer_term',
        (val, schema) => val && schema.max(val, 'O prazo interno deve ser menor que o prazo do cliente.'),
      )
      .when('start', (val, schema) => val && schema.min(val, 'O prazo interno deve ser maior que a data de início.')),
    customer_term: Yup.date()
      .required('O campo prazo do cliente é obrigatório.')
      .min(new Date('1970-01-01'), 'A data deve ser maior que 1970.')
      .when(
        'internal_term',
        (val, schema) => val && schema.min(val, 'O prazo do cliente deve ser maior que o prazo interno.'),
      ),
    title: Yup.string().required('O campo título é obrigatório.'),
    briefing: Yup.string().required('O campo briefing é obrigatório.'),
    status: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('O campo status é obrigatório.'),
    bonus: Yup.number().nullable(),
    budget: Yup.boolean().required('O campo orçamento é obrigatório.'),
  },
  [
    ['estimated_time_hours', 'estimated_time_minutes'],
    ['package_consumption_hours', 'package_consumption_minutes'],
    ['internal_term', 'customer_term'],
    ['start', 'internal_term'],
    ['project', 'package'],
    ['package', 'package_year'],
    ['package', 'package_month'],
    ['package', 'request'],
  ],
);

/**
 * ? Validation schema for job update status field
 */
export const editJobStatusValidation = Yup.object().shape({
  status: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('O campo status é obrigatório.'),
});
