// * MUI
import { styled } from '@mui/system';

export const ScreenshotsListContainer = styled('div')({
  'position': 'relative',
  'width': 150,
  '&:hover': { '& >  div': { opacity: 1 } },
});

export const ScreenshotsListContainerDeleteButton = styled('div')({
  position: 'absolute',
  backgroundColor: 'red',
  width: 20,
  padding: 5,
  color: 'white',
  cursor: 'pointer',
  top: 0,
  right: 0,
  opacity: 0,
});
