// * MUI
import { IconButton, styled, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';

const Button = styled(IconButton)({
  svg: { width: 20, height: 20 },
  width: 28,
  height: 28,
  right: '0.6rem',
});

interface IProps {
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  values: any;
  item: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  className?: string;
  id: string;
}

function Text({ handleChange, values, item, setFieldValue, className, id }: IProps): JSX.Element {
  const { entity, label } = item;
  const value = values[entity];
  const onClick = () => setFieldValue(entity, '');

  return (
    <>
      <TextField
        onChange={handleChange}
        value={value}
        id={label}
        name={entity}
        variant="outlined"
        className={className}
        itemID={id}
      />

      {value && (
        <Button onClick={onClick}>
          <Close />
        </Button>
      )}
    </>
  );
}

export default Text;
