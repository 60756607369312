// * MUI
import { Close, Cancel, Delete as DeleteIcon } from '@mui/icons-material';
import { Modal, IconButton, Button } from '@mui/material';

// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { useMutation, useQueryClient } from 'react-query';

// * Models
import { MediaData } from '@/models';

// * Services
import { deleteMedia } from '@/services';

interface IProps {
  toggleDelete: MediaData | undefined;
  setToggleDelete: React.Dispatch<React.SetStateAction<MediaData | undefined>>;
  snackbar: ICustomSnackbar;
}

export const DeleteModal = ({ toggleDelete, setToggleDelete, snackbar }: IProps) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(deleteMedia, {
    onSuccess: () => {
      snackbar.showSuccess('Arquivo removido com sucesso.');
      setToggleDelete(undefined);
    },
    onError: () => snackbar.showError('Erro ao remover o arquivo.'),
    onSettled: (_data, _error, variables) =>
      queryClient.invalidateQueries(['medias', { jobId: String(variables.job?.id) }]),
  });

  const handleClose = () => setToggleDelete(undefined);

  return (
    <Modal open={!!toggleDelete} onClose={handleClose} className="delete-modal-wrapper">
      <div className="delete-modal-container">
        <div className="delete-modal-head">
          <h1>Excluir arquivo</h1>

          <div>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </div>

        <div className="delete-modal-content">
          <div className="delete-modal-content-text">Tem certeza que deseja excluir o arquivo?</div>
        </div>

        <div className="delete-modal-form-end">
          <Button variant="contained" onClick={() => toggleDelete && mutate(toggleDelete)} startIcon={<DeleteIcon />}>
            Deletar
          </Button>

          <Button variant="outlined" onClick={handleClose} startIcon={<Cancel />}>
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
  );
};
