// * MUI
import { styled } from '@mui/material';

export const DeleteModalContainer = styled('div')({
  'width': '80vw',
  'height': 'auto',
  'background': '#FEFEFE',
  'borderRadius': 4,
  'boxShadow': '0px 2px 6px rgba(0, 0, 0, 0.1)',
  'padding': '1.4rem',
  '.MuiButton-root': { height: 40 },
  '.MuiButton-outlined': {
    'backgroundColor': 'transparent',
    '&:hover': { color: '#FEFEFE', borderColor: '#2A10AF', backgroundColor: '#2A10AF' },
  },
  '.MuiAlert-root': { marginTop: '-0.6rem', marginBottom: '1.2rem' },
});
