// * MUI
import { styled, LinearProgress, linearProgressClasses } from '@mui/material';

const marginX = '0.75rem';

export const LinearProgressContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '0.5rem',
  marginLeft: marginX,
  marginRight: marginX,
});

export const LinearProgressContainerProgress = styled('div')({
  width: '100%',
  marginRight: '0.4rem',
  marginBottom: '0.3rem',
});

export const LinearProgressContainerProgressBorder = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: { backgroundColor: theme.palette.grey[200] },
  [`& .${linearProgressClasses.bar1Determinate}`]: { borderRadius: 5 },
}));
