import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Accounts = loadable(async () => import('pages/app/Account'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      'profile': components.AccountProfile,
      'change-password': components.AccountChangePassword,
      'create-password': components.AccountCreatePassword,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `accounts-${props.page}`,
});
