import { useState, useEffect, useRef } from 'react';

// * MUI
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

// * Components
import { Head } from '@/components/Data';
import { JobDetailsItem } from './components';

// * Hooks & Utils
import { ICustomSnackbar, usePermission } from '@/hooks';
import { showError } from '@/helpers';
import { Formik, Form, FormikConfig, FormikProps } from 'formik';
import { useMutation, useQuery } from 'react-query';

// * Models
import { AuthData, JobData, UserListData } from '@/models';

// * Services
import { fetchInternalUsers, updateJobFollowers } from '@/services';

// * Permissions
import { JOBS_PERMISSIONS } from '@/constants/permissions';

interface IProps {
  job: JobData;
  snackbar: ICustomSnackbar;
  user: AuthData;
}

interface IFormValues {
  followers: UserListData[];
}

export const JobsDetailsFollowers = ({ job, snackbar, user }: IProps) => {
  const { verifyPermission } = usePermission();
  const ref = useRef<FormikProps<IFormValues>>(null);
  const { mutate } = useMutation(updateJobFollowers);
  const [users, setUsers] = useState<UserListData[]>([]);

  const { id, finish, project: jobProject, package: jobPackage, responsible, requester, followers, user_created } = job;

  const isActive = !((jobPackage && !jobPackage.is_active) ?? (jobProject && !jobProject.is_active));

  const editPermission =
    (user.id === responsible.id ||
      user.id === requester.id ||
      user.id === user_created.id ||
      !followers.includes(user.id) ||
      (jobPackage && jobPackage.responsible?.id === user.id)) &&
    !finish &&
    isActive;

  const { refetch, isLoading } = useQuery('internal-users', fetchInternalUsers, {
    enabled: false,
    onSuccess: (data) =>
      setUsers(data.filter((internalUser) => ![responsible.id, requester.id].includes(internalUser.id))),
  });

  useEffect(() => {
    refetch();
  }, [job, refetch]);

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  useEffect(() => {
    if (ref.current && followers.length > 0) {
      ref.current.setFieldValue(
        'followers',
        users.filter((follower) => followers.includes(follower.id)),
      );
    }
  }, [id, followers, users]);

  const handleSubmit: FormikConfig<IFormValues>['onSubmit'] = (values, { setSubmitting }) => {
    setSubmitting(true);

    const formFollowers = values.followers.map((follower) => follower.id);

    mutate(
      { id, followers: formFollowers },
      {
        onSuccess: () => {
          setSubmitting(false);
          snackbar.showSuccess('Seguidores atualizados com sucesso.');
        },
        onError: (error: any) => {
          setSubmitting(false);
          showError({ error, snackbar, message: 'Erro ao atualizar seguidores' });
        },
      },
    );
  };

  const initialValues = { followers: [] as UserListData[] };

  return (
    <>
      <Head>
        <div style={{ fontSize: '1.4rem', fontWeight: 700 }}>Seguidores: </div>
      </Head>

      <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={ref}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <JobDetailsItem>
              {!(isLoading || isSubmitting) ? (
                <Autocomplete
                  multiple
                  id="followers"
                  disabled={!editPermission || !verifyPermission([JOBS_PERMISSIONS.CHANGE])}
                  options={users}
                  value={values.followers}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  )}
                  onChange={(_, value) => setFieldValue('followers', value)}
                  renderInput={(params) => <TextField {...params} variant="standard" size="small" label="Seguidores" />}
                />
              ) : (
                <JobDetailsItem>Carregando...</JobDetailsItem>
              )}
            </JobDetailsItem>

            {!(!editPermission || !verifyPermission([JOBS_PERMISSIONS.CHANGE]) || isSubmitting) && (
              <div style={{ display: 'flex', paddingTop: '0.5rem', justifyContent: 'flex-end' }}>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  loadingPosition="center"
                  variant="contained"
                  startIcon={<Save />}
                >
                  Salvar
                </LoadingButton>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
