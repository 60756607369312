// * MUI
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';

// * Components
import { Head } from '../Data';
import { Table } from './Table';

// * Models
import { ContractData } from '@/models';

// * Hooks & Utils
import { useContract } from '@/hooks/useContract';

export interface IContract extends Omit<ContractData, 'date' | 'validity'> {
  date: Date;
  validity: Date | null;
  isEditMode: boolean;
  isNew: boolean;
}

interface IContractsProps {
  contracts: IContract[];
  setContracts: React.Dispatch<React.SetStateAction<IContract[]>>;
}

export function Contracts({ contracts, setContracts }: IContractsProps): JSX.Element {
  const { addContract, onChange, deleteContract, onEdit } = useContract(contracts, setContracts);

  const isAnyContractInEditMode = contracts.some((contract) => contract.isEditMode);
  const isNewContractValidityLessOrEqualsDate = contracts.some(
    (contract) => contract.isNew && contract.validity && contract.validity.getTime() <= contract.date.getTime(),
  );
  const disabledToAdd = isAnyContractInEditMode || isNewContractValidityLessOrEqualsDate;

  return (
    <div style={{ marginBottom: 20 }}>
      <Head>
        <div style={{ fontSize: '1.4rem', fontWeight: 700 }}>Contratos</div>

        <Button variant="contained" endIcon={<Add />} onClick={addContract} disabled={disabledToAdd}>
          Adicionar
        </Button>
      </Head>

      <Table contracts={contracts} onChange={onChange} deleteContract={deleteContract} onEdit={onEdit} />
    </div>
  );
}
