// * MUI
import { Close, Save, Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Modal, IconButton, Button } from '@mui/material';

// * Components
import { Inputs, Spinner } from '@/components';

// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { showError } from '@/helpers';
import { Formik, Form, FormikConfig } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// * Constants
import { editJobStatusValidation } from '@/constants/validations/job';

// * Models
import { JobData, JobPatchStatusData } from '@/models';

// * Services
import { fetchAllStatuses, updateJobStatus } from '@/services';

interface IJobDetailsDescriptionEditStatusProps {
  job: JobData;
  toggleEditStatus: boolean;
  setToggleEditStatus: React.Dispatch<React.SetStateAction<boolean>>;
  snackbar: ICustomSnackbar;
}

export const JobDetailsDescriptionEditStatus = ({
  job,
  toggleEditStatus,
  setToggleEditStatus,
  snackbar,
}: IJobDetailsDescriptionEditStatusProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(updateJobStatus);

  const getStatuses = useQuery('status', fetchAllStatuses, { enabled: !!job.status });

  const handleSubmit: FormikConfig<JobPatchStatusData>['onSubmit'] = (values, { setSubmitting }) => {
    setSubmitting(true);

    mutate(values, {
      onSuccess: () => {
        setSubmitting(false);
        snackbar.showSuccess('Status atualizado com sucesso.');
      },
      onError: (error: any) => {
        setSubmitting(false);
        showError({ error, snackbar, message: 'Erro ao atualizar status' });
      },
      onSettled: (_data, _error, variables) => queryClient.invalidateQueries(['jobs', { jobId: String(variables.id) }]),
    });
  };

  const handleClose = () => setToggleEditStatus(!toggleEditStatus);
  const initialValues = { id: job.id, status: job.status.id };

  return (
    <Modal open={!!toggleEditStatus} onClose={handleClose} className="delete-modal-wrapper">
      <div className="delete-modal-container">
        <div className="delete-modal-head">
          <h1>Editar Status</h1>

          <div>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </div>

        <div className="delete-modal-content" style={{ display: 'block' }}>
          {!getStatuses.isLoading && job ? (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={editJobStatusValidation}>
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Inputs.Input
                    type="select"
                    name="status"
                    values={values}
                    options={getStatuses.data ?? []}
                    label="Status *:"
                    id="status"
                    handleChange={setFieldValue}
                    order="order"
                    excludeEmpty
                  />

                  <div style={{ display: 'flex', gap: 5, justifyContent: 'flex-end' }}>
                    <LoadingButton
                      type="submit"
                      loading={isSubmitting}
                      loadingPosition="center"
                      variant="contained"
                      startIcon={<Save />}
                    >
                      Salvar
                    </LoadingButton>

                    <Button variant="outlined" onClick={handleClose} startIcon={<Cancel />}>
                      Cancelar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </Modal>
  );
};
