import { RequestPaginated, FAQData, FAQPostData, FAQPutData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/faqs';

export const fetchFAQs = async (page = 0, pageSize = 10): Promise<RequestPaginated<FAQData>> => {
  const { data } = await api.get(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}`);
  return data;
};

export const fetchFAQById = async (id: number): Promise<FAQData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

export const createFAQ = async (data: FAQPostData): Promise<FAQData> => {
  const { data: response } = await api.post(`${ENDPOINT}`, data);
  return response;
};

export const updateFAQ = async (data: FAQPutData): Promise<FAQData> => {
  const { data: response } = await api.put(`${ENDPOINT}/${data.id}`, data);
  return response;
};
