// * MUI
import { Add } from '@mui/icons-material';
import { Button, ButtonTypeMap, ExtendButtonBase, Select } from '@mui/material';

// * Components
import { PageContainer, PageContainerContent, PageContainerHead } from './components';
import { Breadcrumb } from '@/components';

// * React-Router-Dom
import { Link, useNavigate } from 'react-router-dom';

// * Helmet
import Helmet from 'react-helmet';

interface IPageProps {
  title: string;
  pageTitle?: string;
  breadcrumb: string[][];
  children: React.ReactNode | React.ReactNode[];
  addButton?: boolean;
  otherButtons?: Array<React.ReactElement<ExtendButtonBase<ButtonTypeMap<object, 'button'>>>>;
  contentSize: 'max' | 'auto';
  containerStyle?: React.CSSProperties;
}

export const Page = ({
  title,
  pageTitle,
  breadcrumb,
  addButton,
  otherButtons,
  children,
  contentSize,
  containerStyle,
}: IPageProps) => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Helmet title={pageTitle ?? 'Jobber'} />

      <Breadcrumb>
        {breadcrumb.map((crumb, index) => (
          <Link key={crumb[1] + index} to={`${crumb[1]}`}>
            {crumb[0]}
          </Link>
        ))}
      </Breadcrumb>

      <PageContainerHead>
        <h1>{title}</h1>

        {(addButton ?? otherButtons) && (
          <div>
            {addButton && (
              <Button variant="contained" startIcon={<Add />} onClick={() => navigate('new')}>
                Adicionar
              </Button>
            )}

            {otherButtons && (
              <div>{otherButtons.map((button) => (button.type === Button || button.type === Select) && button)}</div>
            )}
          </div>
        )}
      </PageContainerHead>

      <PageContainerContent {...(contentSize === 'auto' ? { auto: true } : { max: true })} style={containerStyle}>
        {children}
      </PageContainerContent>
    </PageContainer>
  );
};
