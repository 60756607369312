// * Components
import { IContract } from '@/components';

// * Hooks & Utils
import { ICustomSnackbar, useCustomSnackbar } from '@/hooks';
import { useMutation } from 'react-query';

// * Services
import { editContract as editContractMutation, deleteContract as deleteContractMutation } from '@/services';

const getErrorMessage = (err: any) => {
  return Object.entries(err.response.data)
    .flatMap(([key, value]) =>
      Array.isArray(value)
        ? value.map((error) => `${key.replace('_', ' ')}: ${error}`)
        : `${key.replace('_', ' ')}: ${value}`,
    )
    .join('\n');
};

const checkDateValidity = (value: Date | null, contract: IContract, snackbar: ICustomSnackbar) => {
  if (value instanceof Date && contract.validity instanceof Date && value > contract.validity) {
    const errorMessage = 'A data de validade não pode ser anterior ou igual à data de início!';
    snackbar.showWarning(errorMessage);
    return false;
  }
  return true;
};

const checkContractDate = (value: Date | null, contract: IContract, snackbar: ICustomSnackbar) => {
  if (value instanceof Date && contract.date instanceof Date && value < contract.date) {
    const errorMessage = 'A data de início não pode ser igual ou posterior à data de validade!';
    snackbar.showWarning(errorMessage);
    return false;
  }
  return true;
};

const checkPreviousContractValidity = (
  name: string,
  value: Date | null,
  contract: IContract,
  previousContract: IContract | null,
  snackbar: ICustomSnackbar,
) => {
  if (
    name === 'date' &&
    value instanceof Date &&
    previousContract?.validity instanceof Date &&
    value < previousContract.validity
  ) {
    const errorMessage = 'A data de início do contrato não pode ser anterior à data de validade do contrato anterior!';
    snackbar.showWarning(errorMessage);
    return false;
  }
  return true;
};

const isValidDates = (
  name: string,
  value: Date | null,
  contract: IContract,
  previousContract: IContract | null,
  snackbar: ICustomSnackbar,
) => {
  if (name === 'date' && !checkDateValidity(value, contract, snackbar)) {
    return false;
  }

  if (name === 'validity' && !checkContractDate(value, contract, snackbar)) {
    return false;
  }

  if (!checkPreviousContractValidity(name, value, contract, previousContract, snackbar)) {
    return false;
  }

  return true;
};

export function useContract(contracts: IContract[], setContracts: React.Dispatch<React.SetStateAction<IContract[]>>) {
  const snackbar = useCustomSnackbar();
  const onError = (err: any) => snackbar.showError(getErrorMessage(err));
  const { mutate: mutateEditContract } = useMutation(editContractMutation, { onError });
  const { mutate: mutateDeleteContract } = useMutation(deleteContractMutation, { onError });

  const onChange = (name: keyof IContract, value: string | number | Date | boolean | null, id: number) => {
    setContracts((prevContracts) => {
      const contractIndex = prevContracts.findIndex((contract) => contract.id === id);
      const contract = prevContracts[contractIndex];
      const previousContract = contractIndex > 0 ? prevContracts[contractIndex - 1] : null;

      let updatedContract: IContract;

      if (name === 'is_indeterminate') {
        updatedContract = { ...contract, is_indeterminate: !!value, validity: null };
      } else if (name === 'date' || name === 'validity') {
        if (!isValidDates(name, value as Date | null, contract, previousContract, snackbar)) {
          return prevContracts;
        }

        updatedContract = { ...contract, [name]: value };
      } else {
        updatedContract = { ...contract, [name]: value };
      }

      return prevContracts.map((contractData) => (contractData.id === id ? updatedContract : contractData));
    });
  };

  const addContract = () => {
    const sortedContracts = contracts.sort((a, b) => a.date.getTime() - b.date.getTime());
    const lastActiveContract = sortedContracts[sortedContracts.length - 1];

    let nextDate: Date = new Date();

    if (lastActiveContract) {
      if (lastActiveContract.is_indeterminate) {
        snackbar.showWarning(`Não é possível adicionar um novo contrato pois o último contrato está indeterminado!`);
        return;
      }

      if (lastActiveContract.validity) {
        nextDate = new Date(lastActiveContract.validity);
        nextDate.setDate(nextDate.getDate() + 1);
      }
    }

    const newContract = {
      id: Math.floor((1 + Math.random()) * 0x100000),
      date: nextDate,
      validity: null,
      is_indeterminate: false,
      hours: 0,
      hour_price: 0,
      hour_price_extra: 0,
      isEditMode: true,
      isNew: true,
    };

    setContracts((prevContracts) => [...prevContracts, newContract]);
  };

  const updateContract = (id: number, changes: Partial<IContract>) => {
    setContracts((prevContracts) => {
      return prevContracts.map((contractData) =>
        contractData.id === id ? { ...contractData, ...changes } : contractData,
      );
    });
  };

  const removeContractFromState = (id: number) => setContracts(contracts.filter((contract) => contract.id !== id));

  const updateContractValues = (updates: Partial<IContract>, id: number) => {
    setContracts((prevContracts) => {
      const contractIndex = prevContracts.findIndex((contract) => contract.id === id);
      const updatedContract = { ...prevContracts[contractIndex], ...updates };

      return [...prevContracts.slice(0, contractIndex), updatedContract, ...prevContracts.slice(contractIndex + 1)];
    });
  };

  const editContract = ({
    id,
    date,
    validity,
    hours,
    hour_price,
    hour_price_extra,
    isNew,
    is_indeterminate,
  }: IContract) => {
    const data = {
      id,
      date: date.toLocaleDateString('en-CA'),
      validity: validity ? validity.toLocaleDateString('en-CA') : null,
      is_indeterminate,
      hours: hours * 60,
      hour_price,
      hour_price_extra,
    };

    if (isNew) {
      updateContractValues(
        {
          date,
          validity,
          is_indeterminate,
          hours,
          hour_price,
          hour_price_extra,
        },
        id,
      );
    } else {
      const onSuccess = () => {
        snackbar.showSuccess('Contrato editado com sucesso! Aguarde...');

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      };

      mutateEditContract(data, { onSuccess });
    }
  };

  const deleteContract = (id: number, isNew: boolean) => {
    if (isNew) {
      removeContractFromState(id);
    } else {
      const lastContractId = contracts[contracts.length - 1].id;

      if (id === lastContractId) {
        const data = { id };

        const onSuccess = () => {
          snackbar.showSuccess('Contrato deletado com sucesso! Aguarde...');

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        };

        mutateDeleteContract(data, { onSuccess });
      }
    }
  };

  const onEdit = (row: IContract) => {
    if (row.isEditMode) {
      editContract(row);
    }

    updateContract(row.id, { isEditMode: !row.isEditMode });
  };

  return {
    snackbar,
    addContract,
    deleteContract,
    onEdit,
    onChange,
  };
}
