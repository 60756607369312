import { RequestPaginated, PostData, PostPostData, PostPutData, PostPatchThumbnailData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/posts';

export const fetchPosts = async (page = 0, pageSize = 10) => {
  const { data } = await api.get<RequestPaginated<PostData>>(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}`);
  return data;
};

export const fetchPostById = async (id: number) => {
  const { data } = await api.get<PostData>(`${ENDPOINT}/${id}`);
  return data;
};

const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': '*/*',
  },
};

type PostPostKeys = keyof PostPostData;

export const createPost = async (data: PostPostData) => {
  const formData = new FormData();

  for (const key in data) {
    if (typeof data[key as PostPostKeys] === 'boolean') {
      formData.append(key, String(data[key as PostPostKeys]));
    }
    formData.append(key, data[key as PostPostKeys] as string | Blob);
  }

  const { data: response } = await api.post<PostData>(`${ENDPOINT}`, formData, config);
  return response;
};

type PostPutKeys = keyof PostPutData;

export const updatePost = async (data: PostPutData) => {
  const formData = new FormData();

  for (const key in data) {
    if (typeof data[key as PostPutKeys] === 'boolean') {
      formData.append(key, String(data[key as PostPutKeys]));
    }
    formData.append(key, data[key as PostPutKeys] as string);
  }

  const { data: response } = await api.put<PostData>(`${ENDPOINT}/${data.id}`, formData, config);
  return response;
};

export const updatePostThumbnail = async (data: PostPatchThumbnailData) => {
  const formData = new FormData();
  formData.append('thumbnail', data.thumbnail);

  const { data: response } = await api.patch<PostData>(`${ENDPOINT}/${data.id}`, formData, config);
  return response;
};
