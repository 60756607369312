// * Components
import { InputWrapper, InputWrapperLabelContainer, InputWrapperContainer } from '../components';

interface IWrapperProps {
  id?: string;
  label?: string;
  children: React.ReactElement;
}

export const Wrapper = ({ id, label, children }: IWrapperProps) => (
  <InputWrapper>
    {label && (
      <InputWrapperLabelContainer>
        <label htmlFor={id}>{label}</label>
      </InputWrapperLabelContainer>
    )}

    <InputWrapperContainer>{children}</InputWrapperContainer>
  </InputWrapper>
);
