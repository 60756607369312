import { PackageReportData, ReportGenerateData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/reports/package';

type ReportPackageFilters = {
  servicetype: string;
  year: string;
  month: string;
};

export const fetchReportPackage = async (filters: ReportPackageFilters): Promise<PackageReportData[]> => {
  const params = `?${String(new URLSearchParams(filters))}`;
  const { data } = await api.get(`${ENDPOINT}${params}`);
  return data;
};

export const fetchGenerateReportPackage = async (filters: ReportPackageFilters): Promise<ReportGenerateData> => {
  const params = `?${String(new URLSearchParams(filters))}&generate=true`;
  const { data } = await api.get(`${ENDPOINT}${params}`);
  return data;
};
