import { useMemo } from 'react';

// * Hooks & Utils
import MobileDetect from 'mobile-detect';

export type TOsType = 'AndroidOS' | 'iOS';

export const useMobileOs = () => {
  const md = useMemo(() => new MobileDetect(window.navigator.userAgent), []);
  const mdOs = md.os() as TOsType;

  return mdOs;
};
