interface Props {
  error: Error;
  resetErrorBoundary: (...args: any[]) => void;
}

export const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  return (
    <div style={{ margin: '55px auto 0' }}>
      <p>Algo deu errado:</p>

      <pre>{error.message}</pre>

      <button onClick={resetErrorBoundary}>Tente novamente</button>
    </div>
  );
};
