// * MUI
import { styled } from '@mui/material';

export const JobsDetailsWrapper = styled('div')({ display: 'flex', flexDirection: 'row', gap: '2em' });

interface IJobsDetailsColumnProps {
  one?: boolean;
  two?: boolean;
}

export const JobsDetailsColumn = styled('div', {
  shouldForwardProp: (prop) => prop !== 'one' && prop !== 'two',
})<IJobsDetailsColumnProps>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2em',
  flexBasis: props.one ? '70%' : props.two ? '30%' : '100%',
}));

export const JobsDetailsColumnItem = styled('div')({
  fontSize: '0.85rem',
  padding: '1.4rem',
  background: '#fefefe',
  borderRadius: 4,
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
});

export const JobDetailsDescriptionContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
  gridAutoRows: 'auto',
});

export const JobDetailsItem = styled('div')({ marginBottom: '1.4rem', flex: '20%' });

interface IJobDetailsDescriptionItemContainerProps {
  wide?: boolean;
}

export const JobDetailsDescriptionItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wide',
})<IJobDetailsDescriptionItemContainerProps>((props) => ({
  marginBottom: '1.4rem',
  flex: '20%',
  ...(props.wide ? { gridColumn: '1/-1' } : {}),
}));

export const JobDetailsDescriptionItemContainerLabel = styled('div')({ textTransform: 'capitalize', fontWeight: 600 });

interface IJobDetailsDescriptionItemContainerContentProps {
  clickable?: boolean;
}

export const JobDetailsDescriptionItemContainerContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'clickable',
})<IJobDetailsDescriptionItemContainerContentProps>((props) => ({
  fontWeight: 400,
  ...(props.clickable ? { cursor: 'pointer', textDecoration: 'underline !important' } : {}),
}));

export const JobDetailsDescriptionItemValueFlag = styled('div')({
  cursor: 'pointer',
  padding: '0.3rem',
  width: 'max-content',
  borderRadius: '0.4rem',
  p: { color: '#fefefe', textOverflow: 'ellipsis', margin: '0 0.5rem' },
});
