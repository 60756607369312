// * MUI
import { Cancel, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// * Components
import { Inputs } from '@/components';

// * Hooks & Utils
import { useCustomSnackbar } from '@/hooks';
import { showError } from '@/helpers';
import { Form, Formik, FormikConfig } from 'formik';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';

// * Models
import { JobData, JobPatchStatusData, RequestPaginated } from '@/models';

// * Services
import { fetchAllStatuses, updateJobStatus } from '@/services';

interface EditJobStatusProps {
  editJobStatus: { jobId: number; statusId: number } | null;
  setEditJobStatus: React.Dispatch<React.SetStateAction<{ jobId: number; statusId: number } | null>>;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<RequestPaginated<JobData>, unknown>>;
}

export const JobEditStatus = ({ editJobStatus, setEditJobStatus, refetch }: EditJobStatusProps) => {
  const snackbar = useCustomSnackbar();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(updateJobStatus);
  const getStatuses = useQuery('status', fetchAllStatuses, { enabled: !!editJobStatus });

  const handleSubmit: FormikConfig<JobPatchStatusData>['onSubmit'] = (values, { setSubmitting }) => {
    setSubmitting(true);

    mutate(values, {
      onSuccess: () => {
        refetch();
        setSubmitting(false);
        snackbar.showSuccess('Status atualizado com sucesso.');
        setEditJobStatus(null);
      },
      onError: (error: any) => {
        setSubmitting(false);
        showError({ error, snackbar, message: 'Erro ao atualizar status' });
      },
      onSettled: (_data, _error, variables) => {
        refetch();
        queryClient.invalidateQueries(['jobs', { jobId: String(variables.id) }]);
      },
    });
  };

  const initialValues = { id: editJobStatus?.jobId ?? 0, status: editJobStatus?.statusId ?? 0 };

  return editJobStatus ? (
    <Dialog open={!!editJobStatus} disableEscapeKeyDown fullWidth maxWidth="lg">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ setFieldValue, values, isSubmitting }) => (
          <Form>
            <DialogTitle id="dialog-title">Editar status do job #{editJobStatus?.jobId}</DialogTitle>

            <DialogContent>
              <Inputs.Input
                type="select"
                name="status"
                values={values}
                options={getStatuses.data ?? []}
                label="Status *:"
                id="status"
                handleChange={setFieldValue}
                order="order"
                excludeEmpty
              />
            </DialogContent>

            <DialogActions>
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                loadingPosition="center"
                variant="contained"
                startIcon={<Save />}
              >
                Salvar
              </LoadingButton>

              <Button
                variant="outlined"
                disabled={isSubmitting}
                onClick={() => setEditJobStatus(null)}
                startIcon={<Cancel />}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  ) : null;
};
