import { useState, useEffect } from 'react';

// * MUI
import { Cancel, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// * Components
import { Inputs, Spinner } from '@/components';

// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { HTMLToDraft } from '@/helpers';
import { Formik, Form, FormikConfig } from 'formik';
import { EditorState } from 'draft-js';
import { useMutation, useQueryClient } from 'react-query';

// * Constants
import { commentValidation } from '@/constants/validations/comment';

// * Models
import { CommentPutData } from '@/models';

// * Services
import { updateComment } from '@/services';

interface IProps {
  toggleEdit: CommentPutData | undefined;
  setToggleEdit: (value: CommentPutData | undefined) => void;
  snackbar: ICustomSnackbar;
}

export const EditModal = ({ toggleEdit, setToggleEdit, snackbar }: IProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(updateComment);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<CommentPutData>();
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  useEffect(() => {
    if (toggleEdit) setInitialValues(toggleEdit);
  }, [toggleEdit]);

  useEffect(() => {
    if (initialValues) {
      setEditorState(HTMLToDraft(initialValues.comment));
      setLoading(false);
    }
  }, [initialValues]);

  const handleSubmit: FormikConfig<CommentPutData>['onSubmit'] = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    mutate(values, {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(['comments', { jobId: String(variables.job) }], data);
        snackbar.showSuccess('Comentário editado com sucesso.');
        setToggleEdit(undefined);
        setEditorState(EditorState.createEmpty());
        resetForm();
      },
      onError: (error: any) => {
        setSubmitting(false);
        snackbar.showError(`Erro ao editar comentário: ${error.response.data.error}`);
      },
      onSettled: (_data, _error, variables) =>
        queryClient.invalidateQueries(['comments', { jobId: String(variables.job) }]),
    });
  };

  const handleClose = () => setToggleEdit(undefined);

  return (
    <Dialog open={!!toggleEdit} onClose={handleClose} maxWidth="lg">
      <Formik<CommentPutData>
        initialValues={initialValues ?? { id: 0, job: 0, comment: '' }}
        onSubmit={handleSubmit}
        validationSchema={commentValidation}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <DialogTitle id="dialog-title">Editar comentário</DialogTitle>

            <DialogContent>
              {!loading && (
                <Inputs.Input
                  type="editor"
                  name="comment"
                  label="Comentário *:"
                  placeholder="Ex: Foi realizado um teste de integração..."
                  id="comment"
                  editorState={editorState}
                  setEditorState={setEditorState}
                  customHandleChange={(content) => setFieldValue('comment', content)}
                />
              )}

              {loading && <Spinner />}
            </DialogContent>

            <DialogActions>
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || loading}
                loadingPosition="center"
                variant="contained"
                startIcon={<Save />}
              >
                Salvar
              </LoadingButton>

              <Button variant="outlined" onClick={handleClose} startIcon={<Cancel />}>
                Cancelar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
