import { useState } from 'react';

export const usePagination = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const { page, pageSize } = paginationModel;

  return { paginationModel, setPaginationModel, page, pageSize };
};
