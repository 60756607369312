// * MUI
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  TextField,
  CircularProgress,
} from '@mui/material';

// * Components
import { Wrapper, Value } from './components';

interface InputWrapperProps {
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined,
  ) => void;
  data: any[] | undefined;
  label: string;
  value: string | number | (string | number[]) | null;
  optionName?: string;
  isLoading?: boolean;
  disabled?: boolean;
  multiple?: boolean;
}

export const InputWrapper = ({
  onChange,
  data,
  label,
  value,
  optionName = 'name',
  isLoading = false,
  disabled = false,
  multiple = false,
}: InputWrapperProps) => {
  const selectedValue = multiple
    ? Array.isArray(value)
      ? data?.filter((item) => value.includes(item.id))
      : []
    : data?.find((item) => item.id === value) ?? null;

  return (
    <Wrapper>
      <Autocomplete
        disablePortal
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
        value={selectedValue}
        getOptionLabel={(option) => option[optionName]}
        clearOnBlur
        clearOnEscape
        options={data ?? []}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box {...props} component="li" key={option.id} style={{ display: data ? 'flex' : 'none' }}>
            {option[optionName]}
          </Box>
        )}
      />

      <Value>{Array.isArray(value) ? value.join(', ') : value ?? 'Não informado.'}</Value>
    </Wrapper>
  );
};
