// * Hooks & Utils
import { Route } from 'react-router-dom';

// * Pages
import { Login, Recover, Google } from './pages';

export const routes = (
  <Route path="/auth">
    <Route index element={<Login page="login" />} />

    <Route path="recover">
      <Route index element={<Recover page="home" />} />

      <Route path="token">
        <Route path=":token" element={<Recover page="token" />} />
      </Route>
    </Route>

    <Route path="google">
      <Route index element={<Google page="callback" />} />
    </Route>
  </Route>
);

export const authRoutes = routes;
