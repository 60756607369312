import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Login = loadable(async () => import('@/pages/auth'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      login: components.LoginHome,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `login-${props.page}`,
});
