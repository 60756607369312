import { RequestPaginated, WalletPackageData, ListWalletPackageData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/walletpackages';

export const fetchWalletPackages = async (page = 0, pageSize = 10): Promise<RequestPaginated<WalletPackageData>> => {
  const { data } = await api.get(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}`);
  return data;
};

type IFetchWalletPackageFilters = {
  responsible?: string;
  servicetype?: string;
  name?: string;
};

export const fetchAllWalletPackages = async (filters: IFetchWalletPackageFilters): Promise<ListWalletPackageData[]> => {
  const params = new URLSearchParams(filters);
  const { data } = await api.get(`${ENDPOINT}?paginate=false&${params.toString()}`);
  return data;
};

export const fetchWalletPackageById = async (id: number): Promise<WalletPackageData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

export const fetchWalletPackageByResponsibleId = async (responsibleId: number): Promise<WalletPackageData[]> => {
  const { data } = await api.get(`${ENDPOINT}?responsible=${responsibleId}&paginate=false`);
  return data;
};

export const updateWalletPackagePackages = async (data: {
  id: number;
  packages: number[];
}): Promise<WalletPackageData> => {
  const { data: response } = await api.patch(`${ENDPOINT}/${data.id}/update_wallet_package_packages`, data);
  return response;
};
