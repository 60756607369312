// * MUI
import { Cancel, Close, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, IconButton, Modal } from '@mui/material';

// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { useMutation, useQueryClient } from 'react-query';

// * Models
import { NoteData } from '@/models';

// * Services
import { deleteNote } from '@/services';

interface IProps {
  toggleDelete: NoteData | undefined;
  setToggleDelete: (value: NoteData | undefined) => void;
  snackbar: ICustomSnackbar;
}

export const DeleteModal = ({ toggleDelete, setToggleDelete, snackbar }: IProps) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(deleteNote, {
    onSuccess: () => {
      snackbar.showSuccess('Apontamento removido com sucesso.');
      setToggleDelete(undefined);
    },
    onError: () => snackbar.showError('Erro ao remover o apontamento.'),
    onSettled: (_data, _error, variables) =>
      queryClient.invalidateQueries(['notes', { jobId: String(variables.job.id) }]),
  });

  const handleClose = () => setToggleDelete(undefined);

  return (
    <Modal open={!!toggleDelete} onClose={handleClose} className="delete-modal-wrapper">
      <div className="delete-modal-container">
        <div className="delete-modal-head">
          <h1>Excluir apontamento</h1>

          <div>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </div>

        <div className="delete-modal-content">
          <div className="delete-modal-content-text">Tem certeza que deseja excluir o apontamento?</div>
        </div>

        <div className="delete-modal-form-end">
          <Button variant="contained" onClick={() => toggleDelete && mutate(toggleDelete)} startIcon={<DeleteIcon />}>
            Deletar
          </Button>

          <Button variant="outlined" onClick={handleClose} startIcon={<Cancel />}>
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
  );
};
