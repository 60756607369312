import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Users = loadable(async () => import('pages/app/Data'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      home: components.DataUsersHome,
      details: components.DataUsersDetails,
      add: components.DataUsersAdd,
      edit: components.DataUsersEdit,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `users-${props.page}`,
});
