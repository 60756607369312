import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Requests = loadable(async () => import('pages/app/Requests'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      all: components.RequestsAll,
      add: components.RequestsAdd,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `requests-${props.page}`,
});

export const RequestsPackage = loadable(async () => import('pages/app/Requests'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      home: components.RequestsPackageHome,
      details: components.RequestsPackageDetails,
      add: components.RequestsPackageAdd,
      edit: components.RequestsPackageEdit,
      requests: components.RequestsPackageRequests,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `requests-package-${props.page}`,
});

export const RequestsProject = loadable(async () => import('pages/app/Requests'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      home: components.RequestsProjectHome,
      details: components.RequestsProjectDetails,
      add: components.RequestsProjectAdd,
      edit: components.RequestsProjectEdit,
      requests: components.RequestsProjectRequests,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `requests-project-${props.page}`,
});
