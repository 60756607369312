// * MUI
import { LinearProgressProps, Typography } from '@mui/material';

// * Components
import {
  LinearProgressContainer,
  LinearProgressContainerProgress,
  LinearProgressContainerProgressBorder,
} from './components';

// * Helpers
import { decimalToTime } from '@/helpers';

interface ILinearProgressProps extends LinearProgressProps {
  value: number;
  hours: number;
  consumption: number;
}

export const LinearProgress = (props: ILinearProgressProps) => {
  const { value: receivedValue, hours, consumption } = props;
  const value = isNaN(receivedValue) ? 0 : receivedValue;
  const exceddedMax = value > 100;

  return (
    <LinearProgressContainer>
      <LinearProgressContainerProgress>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="caption">
            {consumption ? decimalToTime(consumption) : '00:00'} / {hours ? decimalToTime(hours) : '00'}
          </Typography>
        </div>

        <div>
          <LinearProgressContainerProgressBorder
            variant="determinate"
            value={exceddedMax ? 100 : value}
            color={exceddedMax ? 'error' : 'primary'}
          />
        </div>
      </LinearProgressContainerProgress>

      <div style={{ alignSelf: 'end' }}>
        <Typography variant="body2" color={exceddedMax ? 'error' : 'primary'}>
          <strong>{`${Math.round(exceddedMax ? 100 : value)}%`}</strong>
        </Typography>
      </div>
    </LinearProgressContainer>
  );
};
