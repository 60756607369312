import { useState } from 'react';

// * Components
import { ScreenshotsAdd } from './Add';
import { ScreenshotsReorder } from './Reorder';
import { ScreenshotsDelete } from './Delete';
import { ScreenshotsList } from './List';

// * Helpers & Utils
import { useCustomSnackbar } from '@/hooks';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// * Models
import { MediaData } from '@/models';

// * Services
import { fetchMediasByAppId, fetchMediasByServiceId, deleteMedia } from '@/services';

interface IScreenshotsProps {
  endpoint: 'apps' | 'services';
  id: number | undefined;
  reorder?: boolean;
}

export const Screenshots = ({ endpoint, id, reorder = false }: IScreenshotsProps) => {
  const snackbar = useCustomSnackbar();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(deleteMedia);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [toggleDelete, setToggleDelete] = useState<MediaData | null>(null);

  const returnFilter = (id: number | undefined) => {
    let key = '';

    if (endpoint === 'apps') key = 'appId';
    else if (endpoint === 'services') key = 'serviceId';

    return { [key]: String(id) };
  };

  const returnService = async (id: number): Promise<MediaData[]> => {
    let functionToCall: any;

    if (endpoint === 'apps') functionToCall = fetchMediasByAppId;
    else if (endpoint === 'services') functionToCall = fetchMediasByServiceId;

    return functionToCall(id);
  };

  const queryKey = ['medias', returnFilter(id)];

  const handleDelete = (data: MediaData) => {
    setLoadingDelete(true);

    mutate(data, {
      onSuccess: () => {
        if (id) queryClient.setQueryData(queryKey, data);
        snackbar.showSuccess('Screenshot deletado com sucesso!');
        setLoadingDelete(false);
        setToggleDelete(null);
      },
      onError: () => snackbar.showError('Erro ao deletar screenshot!'),
      onSettled: () => {
        if (id) queryClient.invalidateQueries(queryKey);
      },
    });
  };

  const { data } = useQuery(queryKey, async () => returnService(id as number), { enabled: !!id });

  return (
    <>
      <ScreenshotsAdd endpoint={endpoint} id={id} snackbar={snackbar} queryKey={queryKey} />

      {reorder && data && <ScreenshotsReorder screenshots={data} snackbar={snackbar} queryKey={queryKey} />}

      <ScreenshotsDelete
        toggleDelete={toggleDelete}
        setToggleDelete={setToggleDelete}
        handleDelete={handleDelete}
        loading={loadingDelete}
      />

      <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
        {Array.isArray(data) &&
          data
            .filter((media) => media.is_valid)
            .sort((a, b) => {
              if (a.order === null && b.order === null) return 0;

              return (a.order ?? Infinity) - (b.order ?? Infinity);
            })
            .map((media) => <ScreenshotsList key={media.id} media={media} setToggleDelete={setToggleDelete} />)}
      </div>
    </>
  );
};
