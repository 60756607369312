import { InteractionPostData, InteractionPutData, InteractionData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/interactions';

export const fetchInteractionsByRequestId = async (requestId: number) => {
  const { data } = await api.get<InteractionData[]>(`${ENDPOINT}?request=${requestId}`);
  return data;
};

export const createInteraction = async (data: InteractionPostData) => {
  const { data: response } = await api.post<InteractionData>(ENDPOINT, data);
  return response;
};

export const updateInteraction = async (data: InteractionPutData) => {
  const { data: response } = await api.put<InteractionData>(`${ENDPOINT}/${data.id}`, data);
  return response;
};

export const deleteInteraction = async (data: InteractionData) => {
  const { data: response } = await api.delete(`${ENDPOINT}/${data.id}`);
  return response;
};
