// * MUI
import {
  AccountBalanceWallet,
  AddBox,
  AddComment,
  AppsOutlined,
  Badge,
  BarChart,
  BusinessCenter,
  CommentOutlined,
  CommitOutlined,
  Favorite,
  FavoriteBorder,
  FeedOutlined,
  HandymanOutlined,
  Home,
  Info,
  InventoryOutlined,
  LocalShippingOutlined,
  MiscellaneousServicesOutlined,
  Person,
  Publish,
  PushPin,
  SearchOutlined,
  Smartphone,
  Star,
  Today,
  WatchLaterOutlined,
  AssessmentOutlined,
} from '@mui/icons-material';

// * Components
import { Drawer, MenuContainer } from './components';
import { MenuItem } from './Item';

// * Hooks
import { useAuth, usePermission } from '@/hooks';

// * Constants
import { JOBS_PERMISSIONS } from '@/constants/permissions';

interface IMenuProps {
  toggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

export const Menu = ({ toggleMenu, isOpen }: IMenuProps) => {
  const { isCustomer } = useAuth();
  const {
    verifyPermission,
    appsPermissions,
    faqsPermissions,
    postsPermissions,
    servicesPermissions,
    groupsPermissions,
    usersPermissions,
    eventsPermissions,
    customersPermissions,
    projectsPermissions,
    packagesPermissions,
    walletPackagesPermissions,
    bonusPermissions,
    statusPermissions,
    deadlinePermission,
    productionPermission,
    jobsPermissions,
    reportNotesPermissions,
    reportCustomersPermissions,
    reportJobsPermissions,
    devPermissions,
  } = usePermission();

  const importJobPermission = verifyPermission([JOBS_PERMISSIONS.ADD]);
  const mobileServicesPermissions = appsPermissions && faqsPermissions && postsPermissions && servicesPermissions;
  const schedulesPermission = productionPermission || deadlinePermission;

  const jobberReportUrl = process.env.REACT_APP_JOBBER_REPORT_URL || '/';
  const handleClose = () => toggleMenu(!isOpen);

  const requestItems = (showAll: boolean) => (
    <MenuItem label="Solicitações" icon={<CommentOutlined />}>
      <>{showAll && <MenuItem label="Todas" link="/requests/all" icon={<CommentOutlined />} />}</>
      <MenuItem label="Por pacotes" link="/requests/package" icon={<AddComment />} />
      <MenuItem label="Por projetos" link="/requests/project" icon={<AddBox />} />
    </MenuItem>
  );

  const items = isCustomer ? (
    <>
      {requestItems(false)}
      <MenuItem label="Insights" link="/reports/insights" icon={<BarChart />} />
    </>
  ) : (
    <>
      <MenuItem label="Dashboard" link={devPermissions ? '/dev' : '/schedules/production'} icon={<Home />} />
      {groupsPermissions && <MenuItem label="Grupos" link="/groups" icon={<Badge />} />}
      {usersPermissions && <MenuItem label="Usuários" link="/users" icon={<Person />} />}
      {eventsPermissions && <MenuItem label="Eventos" link="/events" icon={<PushPin />} />}
      {customersPermissions && <MenuItem label="Clientes" link="/customers" icon={<Favorite />} />}
      {projectsPermissions && <MenuItem label="Projetos" link="/projects" icon={<Star />} />}
      {packagesPermissions && <MenuItem label="Pacotes" link="/packages" icon={<BusinessCenter />} />}
      {walletPackagesPermissions && (
        <MenuItem label="Carteiras de Pacotes" link="/walletpackages" icon={<AccountBalanceWallet />} />
      )}
      {bonusPermissions && <MenuItem label="Bonus" link="/bonus" icon={<AddBox />} />}
      {statusPermissions && <MenuItem label="Status" link="/status" icon={<Info />} />}
      {requestItems(true)}
      {jobsPermissions && (
        <MenuItem label="Jobs" icon={<WatchLaterOutlined />}>
          <MenuItem label="Listar" link="/jobs" icon={<WatchLaterOutlined />} />

          <>{importJobPermission && <MenuItem label="Importar" link="/jobs/import" icon={<Publish />} />}</>
        </MenuItem>
      )}
      {schedulesPermission && (
        <MenuItem label="Pauta" icon={<Today />}>
          <>
            {productionPermission && (
              <MenuItem label="Pauta de produção" link="/schedules/production" icon={<HandymanOutlined />} />
            )}
          </>
          <>
            {deadlinePermission && (
              <MenuItem label="Pauta de entrega" link="/schedules/deadline" icon={<LocalShippingOutlined />} />
            )}
          </>
        </MenuItem>
      )}
      <MenuItem label="Relatórios" icon={<BarChart />}>
        <MenuItem label="Customizados" externalLink link={jobberReportUrl} icon={<AssessmentOutlined />} />
        <>
          {reportNotesPermissions && <MenuItem label="Apontamentos" link="/reports/note" icon={<CommitOutlined />} />}
        </>
        <>
          {reportCustomersPermissions && (
            <MenuItem label="Clientes" link="/reports/customer" icon={<FavoriteBorder />} />
          )}
        </>
        <>{reportJobsPermissions && <MenuItem label="Jobs" link="/reports/job" icon={<WatchLaterOutlined />} />}</>
        <MenuItem label="Pacotes" link="/reports/package" icon={<InventoryOutlined />} />
      </MenuItem>

      {mobileServicesPermissions && (
        <MenuItem label="App" icon={<Smartphone />}>
          <MenuItem label="Apps" link="/apps" icon={<AppsOutlined />} />
          <MenuItem label="FAQs" link="/faqs" icon={<SearchOutlined />} />
          <MenuItem label="Postagens" link="/posts" icon={<FeedOutlined />} />
          <MenuItem label="Serviços" link="/services" icon={<MiscellaneousServicesOutlined />} />
        </MenuItem>
      )}
    </>
  );

  return (
    <>
      <Drawer variant="temporary" open={isOpen} onClose={handleClose} ModalProps={{ keepMounted: true }}>
        {items}
      </Drawer>

      <MenuContainer active={isOpen}>{items}</MenuContainer>
    </>
  );
};
