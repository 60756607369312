// * MUI
import { TableCell as MuiTableCell, styled } from '@mui/material';

export const Container = styled('div')({
  'flex': 1,
  'height': 'inherit',
  'border': '1px solid rgba(224, 224, 224, 1)',
  'overflow': 'auto',
  '@media (min-width: 900px)': { overflow: 'inherit' },
});

interface ITableCellProps {
  noClick?: boolean;
}

export const TableCell = styled(MuiTableCell, {
  shouldForwardProp: (prop) => prop !== 'noClick',
})<ITableCellProps>((props) => ({
  justifyContent: 'center',
  padding: 0,
  border: 0,
  ...(props.variant === 'body' && { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }),
  ...(props.noClick && { cursor: 'initial' }),
  height: '100%',
  width: '100%',
}));

export const NoRows = styled('div')({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

export const classes = {
  flexContainer: 'ReactVirtualized-flexContainer',
  tableRow: 'ReactVirtualized-tableRow',
  tableRowHover: 'ReactVirtualized-tableRowHover',
};
