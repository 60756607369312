// * MUI
import { keyframes, styled } from '@mui/material';

export const MenuItemStart = styled('div')({
  'display': 'flex',
  'flexDirection': 'row',
  'width': '100%',
  // '&:hover': { color: '#005CFF' },
  '&:hover': { color: '#3F19FF' },
});

const center = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

export const MenuItemIconContainer = styled('div')({ ...center, width: 55, height: 55, transition: '0.2s ease-in' });

export const MenuItemLabelContainer = styled('div')({ ...center, height: 55 });

export const MenuItemLabel = styled('div')({ textTransform: 'uppercase', fontSize: 16, lineHeight: '1em' });

export const MenuItemSubIcon = styled('div')({ width: 24, height: 24, marginRight: '1rem' });

export const MenuItemLink = styled('div')({
  'display': 'flex',
  'flexDirection': 'row',
  'alignItems': 'center',
  'justifyContent': 'space-between',
  'width': 260,
  'textDecoration': 'none',
  'color': '#000000',
  'borderBottom': '1px solid #E1E1E1',
  'transition': '0.2s ease-in',
  'position': 'relative',
  '*': { cursor: 'pointer' },
  // '&.active': { color: '#005CFF', boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)', transition: '0.2s ease-in' },
  // '&.selected': { color: '#005CFF', transition: '0.2s ease-in' },
  '&.active': { color: '#3F19FF', boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)', transition: '0.2s ease-in' },
  '&.selected': { color: '#3F19FF', transition: '0.2s ease-in' },
  '&:hover': { backgroundColor: '#E1E1E170', transition: '0.2s ease-in' },
});

const showSubmenu = keyframes`
0% { opacity: 0; max-height: 0;}
60% { opacity: 1; }
100% { max-height: 100%; }`;

const hideSubmenu = keyframes`
0% { max-height: 100%; overflow: hidden; }
60% { opacity: 1; }
100% { opacity: 0; max-height: 0; }`;

export const SubMenuItemContainer = styled('div')({
  'maxHeight': 0,
  'animation': `${showSubmenu} 0.5s ease-out forwards`,
  'position': 'relative',
  '&.closing': { animation: `${hideSubmenu} 0.5s forwards` },
});
