// * Hooks & Utils
import { UseQueryResult } from 'react-query';

// * Models
import { ContractData, PackageData } from '@/models';

// * Services
import { ListPackageData } from '@/services';

export type QueryReturnType = UseQueryResult<ListPackageData[] | PackageData>;

export const usePackageLastContract = (query: QueryReturnType, packageId?: string | number): ContractData | null => {
  const getPackageLastContract = () => {
    if (query.status !== 'success' || !query.data) return null;

    if (Array.isArray(query.data)) {
      if (!packageId) return null;
      const packageObject = query.data.find((p) => String(p.id) === String(packageId));

      if (
        packageObject &&
        packageObject.last_contract.id &&
        packageObject.last_contract.date &&
        packageObject.last_contract.validity
      ) {
        return packageObject.last_contract as ContractData;
      }
    } else if ('contracts' in query.data) {
      if (query.data.contracts.length > 0) {
        return query.data.contracts[query.data.contracts.length - 1] as ContractData;
      }
    }

    return null;
  };

  return getPackageLastContract();
};
