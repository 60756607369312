import { useState } from 'react';

// * MUI
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

type Option = { id: number; name: string };

const not = (a: Option[], b: Option[]) => a.filter((value) => !b.includes(value));
const intersection = (a: Option[], b: Option[]) => a.filter((value) => b.includes(value));

interface ISelectorChooseProps {
  labelLeft: string;
  left: Option[];
  setLeft: (left: any) => void;
  labelRight: string;
  right: Option[];
  setRight: (right: any) => void;
  disableForward?: boolean;
}

export const SelectorChoose = ({
  labelLeft,
  left,
  setLeft,
  labelRight,
  right,
  setRight,
  disableForward = false,
}: ISelectorChooseProps) => {
  const [checked, setChecked] = useState<Option[]>([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (option: Option) => () => {
    const currentItemIndex = checked.findIndex((item) => item.id === option.id);
    const currentOption = checked[currentItemIndex];
    const newChecked = [...checked];

    if (currentOption) newChecked.splice(currentItemIndex, 1);
    else newChecked.push(option);

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const CustomCardHeader = ({ ...props }) => <CardHeader {...props} sx={{ px: 2, py: 1 }} />;
  const CustomList = ({ ...props }) => (
    <List
      {...props}
      sx={{ width: 400, height: 400, bgcolor: 'background.paper', overflow: 'auto' }}
      dense
      component="div"
      role="list"
    />
  );
  const CustomButton = ({ ...props }) => <Button {...props} sx={{ my: 0.5 }} variant="outlined" size="small" />;

  const subheaderLeft = `${leftChecked?.length ?? 0}/${left.length} selecionado (s)`;
  const subheaderRight = `${rightChecked?.length ?? 0}/${right.length} selecionado (s)`;

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" display="flex">
      <Grid item>
        <Card>
          <CustomCardHeader title={labelLeft} subheader={subheaderLeft} />

          <Divider />

          <CustomList>
            {left.map((option) => {
              const leftChecked = checked.some((item) => item.id === option.id);
              const labelId = `select-transfer-list-all-item-${option.id}-label`;
              const inputProps = { 'aria-labelledby': labelId };

              return (
                <ListItem key={option.id} role="listitem" onClick={handleToggle(option)}>
                  <ListItemIcon>
                    <Checkbox checked={leftChecked} tabIndex={-1} disableRipple inputProps={inputProps} />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${option.name}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </CustomList>
        </Card>
      </Grid>

      <Grid item>
        <Grid container direction="column" alignItems="center">
          <CustomButton onClick={handleCheckedRight} disabled={leftChecked.length === 0}>
            &gt;
          </CustomButton>
          <CustomButton onClick={handleAllRight} disabled={left.length === 0}>
            ≫
          </CustomButton>

          {!disableForward && (
            <>
              <CustomButton onClick={handleAllLeft} disabled={right.length === 0}>
                ≪
              </CustomButton>
              <CustomButton onClick={handleCheckedLeft} disabled={rightChecked.length === 0}>
                &lt;
              </CustomButton>
            </>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Card>
          <CustomCardHeader title={labelRight} subheader={subheaderRight} />

          <Divider />

          <CustomList>
            {right.map((option) => {
              const rightChecked = checked.some((item) => item.id === option.id);
              const labelId = `selected-transfer-list-all-item-${option.id}-label`;
              const inputProps = { 'aria-labelledby': labelId };

              return (
                <ListItem key={option.id} role="listitem" onClick={handleToggle(option)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={rightChecked}
                      tabIndex={-1}
                      disabled={disableForward}
                      disableRipple
                      inputProps={inputProps}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${option.name}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </CustomList>
        </Card>
      </Grid>
    </Grid>
  );
};
