import { ServiceTypeData, ServiceTypeFilterData } from '@/models';
import { sortBetweenName } from '@/helpers';
import api from '@/services/api';

const ENDPOINT = '/servicetypes';

export const fetchAllServiceTypes = async (): Promise<ServiceTypeData[]> => {
  const { data } = await api.get(ENDPOINT);
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const fetchServiceTypesToFilter = async (): Promise<ServiceTypeFilterData[]> => {
  const { data } = await api.get('/filters/servicetypes');
  const sortedData = sortBetweenName(data);
  return sortedData;
};
