import { useEffect, useState } from 'react';

interface IWindowSizeProps {
  width: number | undefined;
  height: number | undefined;
}

export const useWindowSize = (): IWindowSizeProps => {
  const [windowSize, setWindowSize] = useState<IWindowSizeProps>({ width: undefined, height: undefined });

  useEffect(() => {
    const handleResize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
