import { useState } from 'react';

// * MUI
import { Cancel, Edit, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// * Components
import { Inputs } from '@/components';

// * Hooks & Utils
import { useCustomSnackbar } from '@/hooks';
import { randomKey } from '@/helpers';
import { Form, Formik, FormikConfig } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

// * Services
import { updateAppThumbnail, updatePostThumbnail, updateServiceThumbnail } from '@/services';

interface IEditThumbnailProps {
  endpoint: 'apps' | 'posts' | 'services';
  id: number | undefined;
}

interface IFormValues {
  thumbnail: File;
}

export const EditThumbnail = ({ endpoint, id }: IEditThumbnailProps) => {
  const snackbar = useCustomSnackbar();
  const queryClient = useQueryClient();
  const [fileInputKey, setFileInputKey] = useState(randomKey());
  const [open, setOpen] = useState(false);

  const returnService = () => {
    let functionToCall: any = updateAppThumbnail;

    if (endpoint === 'apps') functionToCall = updateAppThumbnail;
    else if (endpoint === 'posts') functionToCall = updatePostThumbnail;
    else if (endpoint === 'services') functionToCall = updateServiceThumbnail;

    return functionToCall;
  };

  const returnKey = (id: number | undefined) => {
    let key = 'appId';

    if (endpoint === 'apps') key = 'appId';
    else if (endpoint === 'posts') key = 'postId';
    else if (endpoint === 'services') key = 'serviceId';

    return { [key]: String(id) };
  };

  const { mutate }: any = useMutation(returnService());

  const handleSubmit: FormikConfig<IFormValues>['onSubmit'] = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    mutate(
      { id, thumbnail: values.thumbnail },
      {
        onSuccess: (data: any) => {
          if (id) queryClient.setQueryData([endpoint, returnKey(id)], data);
          snackbar.showSuccess('Thumbnail editada com sucesso.');
          setOpen(!open);
          setFileInputKey(randomKey());
          resetForm();
        },
        onError: (error: any) => {
          setSubmitting(false);
          snackbar.showError(`Erro ao editar thumbnail: ${error.response.data.error}`);
        },
        onSettled: () => {
          if (id) queryClient.invalidateQueries([endpoint, returnKey(id)]);
        },
      },
    );
  };

  const handleToggleOpen = () => setOpen(!open);
  const initialValues = { thumbnail: new File([], '') };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(!open)} fullWidth maxWidth="lg">
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
          {({ setFieldValue, values, isSubmitting }) => (
            <Form>
              <DialogTitle>Editar thumbnail</DialogTitle>

              <DialogContent>
                <Inputs.Input
                  key={fileInputKey ?? ''}
                  type="file"
                  name="thumbnail"
                  label="Thumbnail *:"
                  accept="image/*"
                  placeholder="Selecione um arquivo..."
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('thumbnail', e?.target.files?.[0])
                  }
                  values={values}
                />
              </DialogContent>

              <DialogActions>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  loadingPosition="center"
                  variant="contained"
                  startIcon={<Save />}
                >
                  Salvar
                </LoadingButton>

                <Button variant="outlined" onClick={handleToggleOpen} startIcon={<Cancel />}>
                  Cancelar
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <Button sx={{ ml: 2 }} variant="contained" startIcon={<Edit />} onClick={handleToggleOpen}>
        Alterar
      </Button>
    </>
  );
};
