import { useState } from 'react';

// * MUI
import { Visibility, VisibilityOff } from '@mui/icons-material';

// * Formik
import { Field } from 'formik';

interface IProps {
  name: string;
  placeholder?: string;
}

function Password({ name, placeholder }: IProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const inputType = isVisible ? 'text' : 'password';
  const toggleType = isVisible ? <VisibilityOff /> : <Visibility />;

  const props = { name, placeholder };

  return (
    <>
      <Field type={inputType} {...props} className="form-input" />

      <div onClick={() => setIsVisible(!isVisible)} className="input-toggle-password">
        {toggleType}
      </div>
    </>
  );
}

export default Password;
