// * Components
import { HeaderAccountDropdownItemClickable } from './components';

// * React-Router-Dom
import { Link } from 'react-router-dom';

interface IHeaderAccountDropdownItemProps {
  label: string;
  link?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  icon: React.ReactElement;
}

export const HeaderAccountDropdownItem = ({ label, link, onClick, icon }: IHeaderAccountDropdownItemProps) => {
  const item = (
    <HeaderAccountDropdownItemClickable title={label}>
      <div style={{ display: 'flex' }}>{icon}</div>

      <div>
        <div>{label}</div>
      </div>
    </HeaderAccountDropdownItemClickable>
  );

  if (link) return <Link to={link}>{item}</Link>;
  if (onClick) return <div onClick={onClick}>{item}</div>;

  return <div>{item}</div>;
};
