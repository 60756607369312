import { Fragment } from 'react';

// * MUI
import { ChevronRight } from '@mui/icons-material';

// * Components
import { BreadcrumbContainer } from './components';

interface IBreadcrumbProps {
  children: React.ReactNode | React.ReactNode[];
}

export const Breadcrumb = ({ children }: IBreadcrumbProps) => {
  const isArray = Array.isArray(children);

  return (
    <BreadcrumbContainer>
      {isArray ? (
        <>
          {children.map((child, index) => (
            <Fragment key={`breadcrumb-item-${index}`}>
              {child}

              {index !== children.length - 1 && <ChevronRight />}
            </Fragment>
          ))}
        </>
      ) : (
        <Fragment key="breadcrumb-item">{children}</Fragment>
      )}
    </BreadcrumbContainer>
  );
};
