import { Children, cloneElement } from 'react';

// * Components
import { Spinner } from '@/components';
import { Mobile } from './Mobile';

// * Hooks & Utils
import { TOsType, useAuth, useMobileOs } from '@/hooks';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useLocation,
} from 'react-router-dom';

// * Routes
import { authRoutes } from './Auth';
import { appRoutes } from './App';

// * Layouts
import { AppLayout, AuthLayout } from 'routes/layouts';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user, isFetchingUser } = useAuth();
  const location = useLocation();

  if (isFetchingUser) return <Spinner />;
  if (!user) return <Navigate to="/auth" state={{ from: location }} replace />;

  return children;
};

const routes = (
  <Route element={<AuthLayout />}>
    {authRoutes}

    <Route
      element={
        <ProtectedRoute>
          <AppLayout />
        </ProtectedRoute>
      }
    >
      {Children.map(appRoutes, (route, i) => cloneElement(route, { key: i }))}
    </Route>

    <Route path="*" element={<Navigate to="/" />} />
  </Route>
);

export const router = createBrowserRouter(createRoutesFromElements(routes));

export const Router = () => {
  const mobileOs = useMobileOs();
  const osMap: Record<TOsType, string> = {
    AndroidOS: `https://play.google.com/store/apps/details?id=${process.env.REACT_APP_ANDROID_APP_ID}`,
    iOS: `https://apps.apple.com/us/app/${process.env.REACT_APP_IOS_APP_ID}`,
  };

  const isMobile = mobileOs === 'AndroidOS' || mobileOs === 'iOS';

  if (isMobile) return <Mobile os={mobileOs} link={osMap[mobileOs]} />;

  return <RouterProvider router={router} />;
};
