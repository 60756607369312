import { useState } from 'react';

// * MUI
import { IconButton, Tooltip } from '@mui/material';
import { ContentCopy, Delete, Edit, Visibility } from '@mui/icons-material';

// * Components
import { DeleteModal } from '@/components/DeleteModal';

// * Hooks & Utils
import { useCustomSnackbar, usePermission } from '@/hooks';
import { Link, useNavigate } from 'react-router-dom';

// * Services
import api from '@/services/api';

interface IDataGridActionsProps {
  params: { row: { id: number | string } };
  endpoint: string;
  fieldName?: string;
  duplicate?: boolean;
  cannotEdit?: boolean;
  cannotDelete?: boolean;
  permissions?: TFilterPermissions;
}

export const DataGridActions = ({
  params,
  endpoint,
  fieldName = 'name',
  duplicate,
  cannotEdit,
  cannotDelete,
  permissions,
}: IDataGridActionsProps) => {
  const { verifyPermission } = usePermission();
  const snackbar = useCustomSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [toggleDelete, setToggleDelete] = useState<any>(null);

  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setToggleDelete(params.row);
  };

  const handleDelete = () => {
    setLoading(true);

    api
      .delete(`/${endpoint}/${params.row.id}`)
      .then(() => {
        snackbar.showSuccess('Registro excluído com sucesso.');

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err: any) => {
        snackbar.showError(`Não foi possível excluir: ${err.response.data.detail}`);
        setLoading(false);
      });
  };

  const viewPermission =
    permissions &&
    permissions.view &&
    verifyPermission(Object.values(permissions.view).map((permission) => permission));

  const changePermission =
    permissions &&
    permissions.change &&
    verifyPermission(Object.values(permissions.change).map((permission) => permission));

  const deletePermission =
    permissions &&
    permissions.delete &&
    verifyPermission(Object.values(permissions.delete).map((permission) => permission));

  const handleClose = () => setToggleDelete(null);

  return (
    <>
      <DeleteModal
        toggleDelete={toggleDelete}
        handleClose={handleClose}
        fieldName={fieldName}
        handleDelete={handleDelete}
        loading={loading}
      />

      <Tooltip title={viewPermission === false ? 'Não é possível visualizar esse item.' : 'Visualizar'}>
        <Link
          to={`details/${params.row.id}`}
          className={viewPermission === false ? 'disabled-link' : ''}
          target="_blank"
        >
          <IconButton sx={{ padding: '4px' }}>
            <Visibility sx={{ ...(viewPermission === false && { color: '#C1C1C3' }) }} />
          </IconButton>
        </Link>
      </Tooltip>

      <Tooltip title={cannotEdit ?? changePermission === false ? 'Não é possível editar esse item.' : 'Editar'}>
        <div>
          <IconButton
            disabled={cannotEdit ?? changePermission === false}
            onClick={() => navigate(`edit/${params.row.id}`)}
            sx={{ padding: '4px' }}
          >
            <Edit sx={{ ...((cannotEdit ?? changePermission === false) && { color: '#C1C1C3' }) }} />
          </IconButton>
        </div>
      </Tooltip>

      <Tooltip title={cannotDelete ?? deletePermission === false ? 'Não é possível excluir esse item.' : 'Excluir'}>
        <div>
          <IconButton disabled={cannotDelete ?? deletePermission === false} onClick={onDelete} sx={{ padding: '4px' }}>
            <Delete sx={{ ...((cannotDelete ?? deletePermission === false) && { color: '#C1C1C3' }) }} />
          </IconButton>
        </div>
      </Tooltip>

      {duplicate && (
        <Tooltip title="Duplicar">
          <div>
            <IconButton onClick={() => navigate(`duplicate/${params.row.id}`)} sx={{ padding: '4px' }} component="div">
              <ContentCopy />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </>
  );
};
