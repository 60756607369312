// * MUI
import { Tooltip } from '@mui/material';

// * Components
import { ScreenshotsListContainer, ScreenshotsListContainerDeleteButton } from './components';

// * Models
import { MediaData } from '@/models';

interface IScreenshotsListProps {
  media: MediaData;
  setToggleDelete: (value: React.SetStateAction<MediaData | null>) => void;
}

export const ScreenshotsList = ({ media, setToggleDelete }: IScreenshotsListProps) => {
  const onClickDelete = () => setToggleDelete(media);

  return (
    <ScreenshotsListContainer key={media.id}>
      <img src={media.file} title={media.name} alt={media.name} width={150} height={150} />

      <Tooltip title="Remover screenshot" arrow>
        <ScreenshotsListContainerDeleteButton onClick={onClickDelete}>X</ScreenshotsListContainerDeleteButton>
      </Tooltip>
    </ScreenshotsListContainer>
  );
};
