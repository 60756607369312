// * Components
import { Spinner } from '../Spinner';
import { Item, Label, Value, Wrapper } from '../Data';

// * Helpers & Utils
import { toHoursAndMinutes } from '@/helpers';
import { useQuery } from 'react-query';

// * Services
import { fetchTodaysHours } from '@/services';

export const TodaysHours = () => {
  const { data, isLoading } = useQuery('notes-today', fetchTodaysHours);

  return (
    <Wrapper>
      <Item>
        {!isLoading && data ? (
          <>
            <Label>Horas apontadas hoje:</Label>
            <Value>{' ' + toHoursAndMinutes(data.minutes)}</Value>
          </>
        ) : (
          <Spinner />
        )}
      </Item>
    </Wrapper>
  );
};
