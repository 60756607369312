import { useState, useEffect, useRef } from 'react';

// * MUI
import { Close, Cancel, Add as AddIcon, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Modal, IconButton, Button } from '@mui/material';

// * Components
import { Inputs } from '@/components';
import { DataFormActions } from '@/components/Form/Actions';

// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { randomKey, uploadFiles } from '@/helpers';
import { Formik, Form, FormikConfig } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

// * Constants
import { mediaValidation } from '@/constants/validations/media';

// * Services
import { uploadDo, uploadFinish, uploadStart } from '@/services';

interface IScreenshotsAddProps {
  endpoint: 'apps' | 'services';
  id: number | undefined;
  snackbar: ICustomSnackbar;
  queryKey: Array<string | { [x: string]: string | undefined }>;
}

interface FormValues {
  name: string;
  files: File[];
  app?: number;
  post?: number;
  service?: number;
}

export const ScreenshotsAdd = ({ endpoint, id, snackbar, queryKey }: IScreenshotsAddProps) => {
  const formikRef = useRef<any>(null);
  const startMutate = useMutation(uploadStart);
  const doMutate = useMutation(uploadDo);
  const finishMutate = useMutation(uploadFinish);
  const queryClient = useQueryClient();
  const [fileInputKey, setFileInputKey] = useState<string>(randomKey());
  const [toggleAdd, setToggleAdd] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);

  const endpointKey = (): 'app' | 'service' => {
    if (endpoint === 'apps') return 'app';
    if (endpoint === 'services') return 'service';

    return 'app';
  };

  const handleSubmit: FormikConfig<FormValues>['onSubmit'] = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const onError = (error: unknown) => {
      console.error(error);
      setSubmitting(false);
      snackbar.showError('Erro ao adicionar screenshot(s).');
    };

    const onFinish = () => {
      setUpload(true);
      setSubmitting(false);
      resetForm();
    };

    await uploadFiles({
      endpointName: endpointKey(),
      endpointId: values[endpointKey()],
      startMutation: startMutate,
      doMutation: doMutate,
      finishMutation: finishMutate,
      snackbar,
      onError,
      onFinish,
      files: values.files,
      name: values.name,
    });
  };

  useEffect(() => {
    if (upload) {
      queryClient.invalidateQueries(queryKey);
      setUpload(false);
      setFileInputKey(randomKey());
      snackbar.showSuccess('Screenshot(s) adicionado(s) com sucesso.');
    }
  }, [upload, snackbar, queryClient, queryKey]);

  const handleClose = () => setToggleAdd(false);
  const handleOpen = () => setToggleAdd(true);

  const initialValues: FormValues = { name: '', files: [], [endpointKey()]: id };

  return (
    <>
      <Modal
        open={!!toggleAdd}
        onClose={(_, reason) => reason !== 'backdropClick' && handleClose()}
        className="delete-modal-wrapper"
      >
        <div className="delete-modal-container">
          <div className="delete-modal-head">
            <h1>Adicionar screenshots</h1>

            <div>
              <IconButton
                className="delete-modal-close-icon"
                onClick={handleClose}
                disabled={formikRef.current?.isSubmitting}
              >
                <Close />
              </IconButton>
            </div>
          </div>

          <div className="delete-modal-content" style={{ display: 'block' }}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              innerRef={formikRef}
              validationSchema={mediaValidation}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Inputs.Input
                    type="text"
                    name="name"
                    label="Nome *:"
                    id="name"
                    values={values}
                    handleChange={setFieldValue}
                  />

                  <Inputs.Input
                    key={fileInputKey ?? ''}
                    type="file"
                    name="files"
                    label="Arquivo (s) *:"
                    id="files"
                    multiple
                    placeholder="Selecione um ou mais arquivos..."
                    handleChange={(e: any) => setFieldValue('files', e.currentTarget.files)}
                    values={values}
                  />

                  <DataFormActions>
                    <LoadingButton
                      type="submit"
                      loading={isSubmitting}
                      loadingPosition="center"
                      variant="contained"
                      startIcon={<Save />}
                    >
                      Salvar
                    </LoadingButton>

                    <Button variant="outlined" onClick={handleClose} disabled={isSubmitting} startIcon={<Cancel />}>
                      Cancelar
                    </Button>
                  </DataFormActions>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>

      <Button sx={{ ml: 2 }} variant="contained" startIcon={<AddIcon />} onClick={handleOpen}>
        Adicionar
      </Button>
    </>
  );
};
