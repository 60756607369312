// * Components
import {
  HeaderContainer,
  HeaderContainerEnd,
  HeaderContainerStart,
  HeaderContainerStartLogo,
  HeaderContainerStartMenuIconContainer,
  HeaderContainerStartMenuIconContainerContent,
  HeaderContainerStartMenuIconContainerContentBar,
} from './components';
import { HeaderAccount } from './Account';

// * Images
import { ReactComponent as LogoJobberWhite } from '@/assets/images/jobber-white.svg';

interface IHeaderProps {
  toggleMenu: (state: boolean) => void;
  isOpen: boolean;
}

export const Header = ({ toggleMenu, isOpen }: IHeaderProps) => {
  const handleMenu = () => toggleMenu(!isOpen);

  return (
    <HeaderContainer>
      <HeaderContainerStart>
        <HeaderContainerStartMenuIconContainer active={isOpen} onClick={handleMenu}>
          <HeaderContainerStartMenuIconContainerContent>
            <HeaderContainerStartMenuIconContainerContentBar active={isOpen} />
            <HeaderContainerStartMenuIconContainerContentBar active={isOpen} />
            <HeaderContainerStartMenuIconContainerContentBar active={isOpen} />
          </HeaderContainerStartMenuIconContainerContent>
        </HeaderContainerStartMenuIconContainer>

        <HeaderContainerStartLogo>
          <LogoJobberWhite />
        </HeaderContainerStartLogo>
      </HeaderContainerStart>

      <HeaderContainerEnd>
        <HeaderAccount />
      </HeaderContainerEnd>
    </HeaderContainer>
  );
};
