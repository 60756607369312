import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Google = loadable(async () => import('pages/auth/Login/Google/Callback'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      callback: components.SocialLoginGoogleCallback,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `google-${props.page}`,
});
