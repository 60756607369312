import { forwardRef } from 'react';

// * MUI
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowClassNameParams,
  ptBR,
} from '@mui/x-data-grid';

// * Components
import { DataGridWrapperContainer } from './components';

// * Hooks
import { useDataRows } from '@/hooks';

interface IDataGridWrapperProps {
  loading: boolean;
  tableHeight: number;
  data: any[];
  count?: number;
  getRowClassName?: ((params: GridRowClassNameParams<any>) => string) | undefined;
  colDef: GridColDef[];
  hideFooterPagination?: boolean;
  paginationMode?: 'client' | 'server';
  columnVisibilityModel?: GridColumnVisibilityModel | undefined;
  paginationModel?: GridPaginationModel;
  onPaginationModelChange?: (model: GridPaginationModel, details: GridCallbackDetails<any>) => void;
}

const Component = (
  {
    loading,
    tableHeight,
    data = [],
    count,
    getRowClassName,
    colDef,
    hideFooterPagination,
    paginationMode = 'server',
    columnVisibilityModel,
    paginationModel,
    onPaginationModelChange,
  }: IDataGridWrapperProps,
  ref: any,
) => {
  const { rows: dataRows } = useDataRows(colDef, data);

  const localeText = ptBR.components.MuiDataGrid.defaultProps.localeText;
  const rows = !loading ? dataRows : [];
  const rowCount = count ?? 0;
  const rowsPerPageOptions = [10, 25, 50, 100];

  return (
    <DataGridWrapperContainer height={tableHeight}>
      <DataGrid
        ref={ref}
        localeText={localeText}
        loading={loading}
        getRowClassName={getRowClassName}
        columns={colDef}
        rows={rows}
        rowCount={rowCount}
        pageSizeOptions={rowsPerPageOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        hideFooterPagination={hideFooterPagination}
        paginationMode={paginationMode}
        disableRowSelectionOnClick
        initialState={{ columns: { columnVisibilityModel } }}
      />
    </DataGridWrapperContainer>
  );
};

export const DataGridWrapper = forwardRef(Component);
