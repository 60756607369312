import { useState, useEffect, useMemo } from 'react';

// * MUI
import { LoadingButton } from '@mui/lab';
import { CleaningServices, FilterAlt } from '@mui/icons-material';
import { IconButton } from '@mui/material';

// * Components
import {
  DataGridFilterContainer,
  DataGridFilterContainerTitle,
  DataGridFilterContainerContent,
  DataGridFilterContainerContentForm,
  DataGridFilterContainerContentFormActions,
  DataGridFilterContainerContentFormInput,
  DataGridFilterContainerContentFormInputWrapper,
  DataGridFilterContainerContentFormInputWrapperContainer,
} from './components';
import { ErrorMessage } from '@/components';
import { Autocomplete, Datepicker } from 'components/Inputs';
import Text from './Text';

// * Hooks & Utils
import { usePermission } from '@/hooks';
import { formatDateString, handleApiData, sortBetweenName } from '@/helpers';
import { useQueries } from 'react-query';
import { Formik, FormikState } from 'formik';

// * Services
import {
  fetchUsersToFilter,
  fetchCustomersToFilter,
  fetchPackagesToFilter,
  fetchProjectsToFilter,
  fetchStatusesToFilter,
  fetchServiceTypesToFilter,
  fetchRequestStatusesToFilter,
  fetchPrioritiesToFilter,
  fetchBonusesToFilter,
} from '@/services';

interface IDataGridFilterProps {
  schema: IDataFilterSchema[];
  filters: Record<string, string | number | boolean | null | undefined>;
  setFilters: (value: Record<string, string | number | boolean | null | undefined>) => void;
  filterRef: React.RefObject<HTMLDivElement>;
  toggleFilter: boolean;
  clearFilters?: () => void;
  setToggle: (value: boolean) => void;
  setTableHeight: (value: number) => void;
}

export const DataGridFilter = ({
  schema,
  filters,
  setFilters,
  filterRef,
  toggleFilter,
  clearFilters,
  setToggle,
  setTableHeight,
}: IDataGridFilterProps) => {
  const { verifyPermission } = usePermission();

  const [data, setData] = useState<any>();
  const [initialValues, setInitialValues] = useState({});
  const [reset, setReset] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const queries = useQueries([
    { queryKey: 'filter-users', queryFn: fetchUsersToFilter },
    { queryKey: 'filter-customers', queryFn: fetchCustomersToFilter },
    { queryKey: 'filter-packages', queryFn: fetchPackagesToFilter },
    { queryKey: 'filter-projects', queryFn: fetchProjectsToFilter },
    { queryKey: 'filter-status', queryFn: fetchStatusesToFilter },
    { queryKey: 'filter-servicetypes', queryFn: fetchServiceTypesToFilter },
    { queryKey: 'filter-requeststatus', queryFn: fetchRequestStatusesToFilter },
    { queryKey: 'filter-priorities', queryFn: fetchPrioritiesToFilter },
    { queryKey: 'filter-bonuses', queryFn: fetchBonusesToFilter },
  ]);

  useEffect(() => {
    const fetchData: any[] = [];

    if (queries.every((query) => query.isSuccess && query.data)) {
      schema.forEach(({ type, options, entity, allowCustomers, withPackages, name }) => {
        const user = handleApiData(
          allowCustomers ? queries[0].data : queries[0].data?.filter((filteredUser) => !filteredUser.is_customer),
          name,
        );
        const customer = withPackages ? queries[1].data : handleApiData(queries[1].data, name);
        const packageData = handleApiData(queries[2].data, name);
        const project = handleApiData(queries[3].data, name);
        const status = handleApiData(queries[4].data, name);
        const servicetype = handleApiData(queries[5].data, name);
        const requeststatus = handleApiData(queries[6].data, name);
        const priorities = handleApiData(queries[7].data, name);
        const bonus = handleApiData(queries[8].data, name);

        const dataMap = {
          user,
          customer,
          package: packageData,
          project,
          status,
          servicetype,
          requeststatus,
          priorities,
          bonus,
        };

        if (type === 'select') {
          const selectData = options ?? dataMap[entity as keyof typeof dataMap];
          fetchData.push({ entity, data: selectData });
        }
      });

      !data && fetchData && setData(fetchData);
    }
  }, [queries, schema, data]);

  const dateKeys = useMemo(() => ['start', 'finish', 'date'], []);

  useEffect(() => {
    const initial: { [key: string]: string } = {};

    schema.forEach(({ defaultDateValue, entity, defaultValue }) => {
      let value = '';

      if (defaultDateValue != null) {
        value = formatDateString(defaultDateValue);
      } else if (defaultValue) {
        value = defaultValue;
      }

      initial[entity] = value;
    });

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.searchParams);

    if (!reset) {
      const setDateField = (key: string, value: string) => {
        const date = new Date(value);
        date.setDate(date.getDate() + 2);
        initial[key] = formatDateString(date);
      };

      const setField = (key: string, value: string) => {
        initial[key] = value;
        if (dateKeys.includes(key)) setDateField(key, value);
      };

      if (String(searchParams).length) {
        searchParams.forEach((value, key) => setField(key, value));
      } else if (Object.keys(filters).length) {
        for (const [key, value] of Object.entries(filters)) {
          setField(key, value as string);
        }
      }
    }

    initial && setInitialValues(initial);
    setLoading(false);
  }, [schema, data, dateKeys, filters, reset]);

  useEffect(() => {
    setTimeout(() => {
      filterRef.current?.offsetHeight && setTableHeight(filterRef.current?.offsetHeight);
    }, 200);
  }, [toggleFilter, filterRef, setTableHeight]);

  const handleSubmit = async (values: any) => {
    let data = { ...values };

    dateKeys.forEach((key) => {
      if (data[key]) data[key] = formatDateString(data[key]);
    });

    data = Object.fromEntries(Object.entries(data).filter(([, value]) => value !== ''));
    setFilters(data);
  };
  const handleToggleFilter = () => setToggle(!toggleFilter);
  const handleClear = (resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void) => {
    setReset(true);
    resetForm();

    if (clearFilters) clearFilters();
    else setFilters({});
  };

  const filterDisabled = queries.some((query) => query.isLoading);

  return (
    <DataGridFilterContainer ref={filterRef}>
      <DataGridFilterContainerTitle onClick={handleToggleFilter}>
        <label>Filtrar</label>

        <IconButton disabled={filterDisabled}>
          <FilterAlt />
        </IconButton>
      </DataGridFilterContainerTitle>

      <DataGridFilterContainerContent active={toggleFilter}>
        {!loading && data && (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
            {({ isSubmitting, handleChange, values, setFieldValue, resetForm }) => (
              <DataGridFilterContainerContentForm>
                {schema.length > 0 &&
                  schema.map((item, index) => {
                    const { permissions, type, entity, label, field } = item;
                    const name = field ?? entity;
                    const buttonId = name + '-button';

                    if (permissions != null && permissions.view && !verifyPermission(permissions.view)) {
                      return null;
                    }

                    const schemaData = data.find((e: any) => e.entity === entity)?.data;

                    const typeMap = {
                      select: (
                        <Autocomplete
                          item={item}
                          data={schemaData && sortBetweenName(schemaData)}
                          setDisabled={setDisabled}
                          setFieldValue={setFieldValue}
                          values={values}
                          id={buttonId}
                        />
                      ),
                      datepicker: (
                        <Datepicker
                          setValue={(value) => setFieldValue(entity, value)}
                          value={values[entity as keyof typeof values]}
                        />
                      ),
                      search: (
                        <Text
                          handleChange={handleChange}
                          values={values}
                          item={item}
                          setFieldValue={setFieldValue}
                          id={buttonId}
                        />
                      ),
                    };

                    const renderInput = (filterType: TFilterType) => typeMap[filterType];

                    return (
                      <DataGridFilterContainerContentFormInput key={`${label}-${index}`}>
                        <DataGridFilterContainerContentFormInputWrapper>
                          {label && (
                            <div>
                              <label htmlFor={buttonId}>{label}</label>
                            </div>
                          )}

                          <DataGridFilterContainerContentFormInputWrapperContainer>
                            {renderInput(type)}
                          </DataGridFilterContainerContentFormInputWrapperContainer>

                          <ErrorMessage name={name} />
                        </DataGridFilterContainerContentFormInputWrapper>
                      </DataGridFilterContainerContentFormInput>
                    );
                  })}

                <DataGridFilterContainerContentFormActions>
                  <LoadingButton
                    type="button"
                    onClick={() => handleClear(resetForm)}
                    loadingPosition="center"
                    variant="contained"
                    startIcon={<CleaningServices />}
                  >
                    Limpar
                  </LoadingButton>

                  <LoadingButton
                    type="submit"
                    disabled={disabled || isSubmitting}
                    loading={isSubmitting}
                    loadingPosition="center"
                    variant="contained"
                    startIcon={<FilterAlt />}
                  >
                    Filtrar
                  </LoadingButton>
                </DataGridFilterContainerContentFormActions>
              </DataGridFilterContainerContentForm>
            )}
          </Formik>
        )}
      </DataGridFilterContainerContent>
    </DataGridFilterContainer>
  );
};
