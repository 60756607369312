/**
 * Function that returns the search params on current page
 * @returns [k: string]: string
 */

export const getParams = (): {
  [k: string]: string;
} => {
  const url = new URL(window.location.href);

  return Object.fromEntries(new URLSearchParams(url.searchParams));
};
