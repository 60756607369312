// * MUI
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// * Components
import { ScheduleFooterContainer, ScheduleFooterContainerMinutes } from './components';

// * Hooks & Utils
import { usePermission } from '@/hooks';
import { toHoursAndMinutes } from '@/helpers';

// * Permissions
import { JOBS_PERMISSIONS } from '@/constants/permissions';

interface IToggleAddProps {
  exceddedEightHours: boolean;
  minutes: number;
  responsible: { id: number; name: string };
  internal_start: Date;
}

interface IScheduleFooterProps {
  date: Date;
  user: { id: number; name: string };
  minutes: number;
  setToggleAdd: React.Dispatch<React.SetStateAction<IToggleAddProps | null>>;
}

export const ScheduleFooter = ({ date, user, minutes, setToggleAdd }: IScheduleFooterProps) => {
  const { verifyPermission } = usePermission();
  const exceddedEightHours = minutes >= 8 * 60;
  const title = `Criar novo job para ${user.name}, iniciando no dia ${date.toLocaleDateString()}`;
  const onClick = () => setToggleAdd({ exceddedEightHours, minutes, responsible: user, internal_start: date });

  return (
    <ScheduleFooterContainer error={exceddedEightHours}>
      <ScheduleFooterContainerMinutes>{toHoursAndMinutes(minutes)}</ScheduleFooterContainerMinutes>

      {verifyPermission([JOBS_PERMISSIONS.ADD]) && (
        <IconButton title={title} sx={{ padding: 0, marginLeft: 1 }} onClick={onClick}>
          <AddIcon />
        </IconButton>
      )}
    </ScheduleFooterContainer>
  );
};
