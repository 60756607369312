// * MUI
import { keyframes, styled } from '@mui/system';

const showArrow = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const hideArrow = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;

interface ContainerProps {
  active?: boolean;
  closing?: boolean;
}

export const HeaderAccountContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'closing',
})<ContainerProps>((props) => ({
  position: 'relative',
  ...(props.active && {
    '::after': {
      content: '""',
      position: 'absolute',
      top: 39,
      right: 7.5,
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #F9F9F9',
      zIndex: 8,
      animation: `${showArrow} 0.5s ease-out forwards`,
    },
    ...(props.closing && { '::after': { animation: `${hideArrow} 0.5s ease-in forwards 0.3s` } }),
  }),
}));
