// * MUI
import { LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// * Hooks & Utils
import brLocale from 'date-fns/locale/pt-BR';

type Views = ['year', 'month', 'day'] | ['year', 'month'];

interface IProps {
  value: Date | null;
  setValue: (value: Date | null) => void;
  label?: string;
  disabled?: boolean;
  views?: Views;
}

function Datepicker({ value, setValue, label, disabled, views = ['year', 'month', 'day'] }: IProps): JSX.Element {
  const openTo = views[views.length - 1];
  const inputFormat = views[views.length - 1] === 'day' ? 'dd/MM/yyyy' : 'MM/yyyy';
  const minDate = new Date('1970-01-01');

  const formattedValue = value ? new Date(value) : null;

  const props = {
    value: formattedValue,
    onChange: setValue,
    label,
    disabled,
    views,
    openTo,
    format: inputFormat,
    minDate,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
      {window.innerWidth < 768 ? <MobileDatePicker {...props} /> : <DesktopDatePicker {...props} />}
    </LocalizationProvider>
  );
}

export default Datepicker;
