// * Hooks & Utils
import { Editor as _Editor, EditorProps } from 'react-draft-wysiwyg';

// * Styles
import './styles.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function Editor({ editorState, readOnly, onEditorStateChange }: EditorProps): JSX.Element {
  const editorToolbar = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'],
    inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] },
    blockType: { inDropdown: false },
    link: {
      inDropdown: false,
      popupClassName: 'editor-link-popup',
      defaultTargetOption: '_blank',
      options: ['link', 'unlink'],
    },
  };

  return (
    <_Editor
      editorState={editorState}
      readOnly={readOnly}
      onEditorStateChange={onEditorStateChange}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      toolbar={editorToolbar}
      localization={{ locale: 'pt' }}
    />
  );
}

export default Editor;
