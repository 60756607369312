// * MUI
import { styled } from '@mui/material';

interface IDataGridWrapperContainerProps {
  height: number;
}

export const DataGridWrapperContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'height',
})<IDataGridWrapperContainerProps>((props) => ({
  height: '100%',
  maxHeight: `calc(100% - ${props.height}px)`,
  transition: '.1s ease-out',
}));
