import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Packages = loadable(async () => import('pages/app/Data'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      home: components.DataPackagesHome,
      details: components.DataPackagesDetails,
      add: components.DataPackagesAdd,
      edit: components.DataPackagesEdit,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `packages-${props.page}`,
});
