import { useState, useEffect } from 'react';

// * MUI
import { Comment as CommentIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton } from '@mui/material';

// * Components
import { Head } from '@/components/Data';
import { SpanBolder, SpanMedium } from '@/components/Request/Details/Jobs/components';
import { JobDetailsItem } from '../components';
import { AddModal } from './Add';
import { EditModal } from './Edit';
import { DeleteModal } from './Delete';

// * Hooks & Utils
import { ICustomSnackbar, useAuth } from '@/hooks';
import { useQuery } from 'react-query';

// * Models
import { JobData, CommentData, CommentPutData } from '@/models';

// * Services
import { fetchCommentsByJobId } from '@/services';

interface IProps {
  job: JobData;
  snackbar: ICustomSnackbar;
}

export const JobsDetailsComments = ({ job, snackbar }: IProps) => {
  const { user } = useAuth();
  const [toggleEdit, setToggleEdit] = useState<CommentPutData | undefined>();
  const [toggleDelete, setToggleDelete] = useState<CommentData | undefined>();

  const { package: jobPackage, project: jobProject } = job;

  const { isLoading, isFetching, refetch, data } = useQuery(['comments', { jobId: String(job.id) }], async () =>
    fetchCommentsByJobId(job.id),
  );

  const isActive = !((jobPackage && !jobPackage.is_active) ?? (jobProject && !jobProject.is_active));

  useEffect(() => {
    refetch();
  }, [job, refetch]);

  return (
    <>
      <Head>
        <div style={{ fontSize: '1.4rem', fontWeight: 700 }}>Comentários: </div>

        {isActive && (
          <>
            <AddModal job={job} snackbar={snackbar} />

            {!job.finish && (
              <>
                <EditModal toggleEdit={toggleEdit} setToggleEdit={setToggleEdit} snackbar={snackbar} />

                <DeleteModal toggleDelete={toggleDelete} setToggleDelete={setToggleDelete} snackbar={snackbar} />
              </>
            )}
          </>
        )}
      </Head>

      <JobDetailsItem>
        {!(isLoading || isFetching) ? (
          data && data.length > 0 ? (
            <List dense disablePadding>
              {data.map((commentItem) => {
                const { id, user: commentUser, comment, date_updated, job: commentJob } = commentItem;

                return (
                  <ListItem key={id} sx={{ py: 0 }} disablePadding component="div">
                    <ListItemAvatar>
                      <Avatar>
                        <CommentIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <>
                          <SpanBolder>{commentUser.name}</SpanBolder> -{' '}
                          <SpanMedium>{new Date(date_updated).toLocaleString()}</SpanMedium>
                        </>
                      }
                      secondary={<div dangerouslySetInnerHTML={{ __html: comment }} />}
                      secondaryTypographyProps={{ component: 'span' }}
                    />

                    {commentUser.id === user?.id && !job.finish && (
                      <ListItem sx={{ width: 'auto' }} component="div">
                        <IconButton edge="start" onClick={() => setToggleEdit({ id, job: commentJob.id, comment })}>
                          <EditIcon />
                        </IconButton>

                        <IconButton edge="end" onClick={() => setToggleDelete(commentItem)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    )}
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <>Nenhum comentário registrado.</>
          )
        ) : (
          <>Carregando...</>
        )}
      </JobDetailsItem>
    </>
  );
};
