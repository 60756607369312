import { CustomerReportListData, ReportGenerateData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/reports/customer';

type ReportCustomerFilters = {
  year: string;
  month: string;
  customer: string;
  package: string;
};

export const fetchReportCustomer = async (filters: ReportCustomerFilters): Promise<CustomerReportListData> => {
  const params = `?${String(new URLSearchParams(filters))}`;
  const { data } = await api.get(`${ENDPOINT}${params}`);
  return data;
};

export const fetchGenerateReportCustomer = async (filters: ReportCustomerFilters): Promise<ReportGenerateData> => {
  const params = `?${String(new URLSearchParams(filters))}&generate=true`;
  const { data } = await api.get(`${ENDPOINT}${params}`);
  return data;
};
