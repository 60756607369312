// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';

interface IShowErrorProps {
  error: { response: { data: Record<string, unknown> } };
  snackbar: ICustomSnackbar;
  message: string;
}

/**
 * Map errors and show them in snackbar
 * @param error
 * @param snackbar
 * @param message
 * @returns void
 */
export const showError = ({ error, snackbar, message }: IShowErrorProps) => {
  const errors = error.response.data as Record<string, unknown>;
  const keys = Object.keys(errors);
  snackbar.showError(`${message}: ${keys.map((key) => Object.values(errors[key] as Array<unknown>).map((e) => e))}`);
};

export const showSpreadsheetGenerationError = ({ error, snackbar, message }: IShowErrorProps) => {
  const errors = error.response.data as Record<string, unknown>;
  const requiredFields: string[] = [];
  let nonFieldMessages: string[] = [];

  Object.entries(errors).forEach(([key, messages]) => {
    const messageArray = messages as string[];

    if (key !== 'non_field_errors') {
      if (
        messageArray.some(
          (msg) =>
            msg.includes('não pode ser nulo') || msg.includes('não pode estar vazia') || msg.includes('obrigatório'),
        )
      ) {
        requiredFields.push(key);
      }
    } else {
      nonFieldMessages = messageArray;
    }
  });

  const requiredFieldsMessage = requiredFields.length
    ? requiredFields.length === 1
      ? `Selecione o campo a seguir: ${requiredFields[0]}.`
      : `Selecione os campos a seguir: ${requiredFields.slice(0, -1).join(', ')} e ${
          requiredFields[requiredFields.length - 1]
        }.`
    : '';

  const formattedMessage = [requiredFieldsMessage, ...nonFieldMessages].filter(Boolean).join(' ');
  snackbar.showError(`${message} ${formattedMessage}`);
};
