// * MUI
import { IconButton, TableCell } from '@mui/material';
import { Done, Edit } from '@mui/icons-material';

// * Components
import DeleteButton from './DeleteButton';

// * Interfaces
import { IContract } from '.';

interface IEditCellProps {
  contractsLength: number;
  row: IContract;
  onEdit: () => void;
  onDelete?: () => void;
  disabled?: boolean;
  isLastContract?: boolean;
}

function EditCell({ contractsLength, row, onEdit, onDelete, disabled, isLastContract }: IEditCellProps): JSX.Element {
  const cannotDelete = !row.isNew && contractsLength === 1;

  return (
    <TableCell>
      {isLastContract && (
        <IconButton onClick={onEdit} disabled={disabled}>
          {row.isEditMode ? <Done /> : <Edit />}
        </IconButton>
      )}

      {isLastContract && !row.isEditMode && onDelete && (
        <DeleteButton id={row.id} onDelete={onDelete} disabled={disabled} cannotDelete={cannotDelete} />
      )}
    </TableCell>
  );
}

export default EditCell;
