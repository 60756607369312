import { useState, useEffect } from 'react';

// * MUI
import { Receipt, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton } from '@mui/material';

// * Components
import { Head } from '@/components/Data';
import { SpanBolder } from '@/components/Request/Details/Jobs/components';
import { JobDetailsItem } from '../components';
import { AddModal } from './Add';
import { EditModal } from './Edit';
import { DeleteModal } from './Delete';

// * Hooks & Utils
import { ICustomSnackbar, useAuth } from '@/hooks';
import { stringDiffBetweenTwoDateTimes, toHoursAndMinutes } from '@/helpers';
import { useQuery } from 'react-query';

// * Models
import { JobData, NoteData, NotePutData } from '@/models';

// * Services
import { fetchNotesByJobId } from '@/services';

interface IProps {
  job: JobData;
  snackbar: ICustomSnackbar;
}

export const JobsDetailsNotes = ({ job, snackbar }: IProps) => {
  const { user } = useAuth();
  const [toggleEdit, setToggleEdit] = useState<NotePutData | undefined>();
  const [toggleDelete, setToggleDelete] = useState<NoteData | undefined>();

  const { package: jobPackage, project: jobProject } = job;

  const { isLoading, isFetching, refetch, data } = useQuery(['notes', { jobId: String(job.id) }], async () =>
    fetchNotesByJobId(job.id),
  );

  const isActive = !((jobPackage && !jobPackage.is_active) ?? (jobProject && !jobProject.is_active));

  useEffect(() => {
    refetch();
  }, [job, refetch]);

  return (
    <>
      <Head>
        <div style={{ fontSize: '1.4rem', fontWeight: 700 }}>Apontamentos: </div>

        {isActive && (
          <>
            <AddModal jobId={job.id} snackbar={snackbar} />

            <EditModal toggleEdit={toggleEdit} setToggleEdit={setToggleEdit} snackbar={snackbar} />

            <DeleteModal toggleDelete={toggleDelete} setToggleDelete={setToggleDelete} snackbar={snackbar} />
          </>
        )}
      </Head>

      <JobDetailsItem>
        {!(isLoading || isFetching) ? (
          data && data.length > 0 ? (
            <List dense disablePadding>
              {data.length > 0 ? (
                <>
                  {data.map((note) => {
                    const { id, description, user: noteUser, job: noteJob, start, finish, minutes } = note;

                    return (
                      <ListItem key={id} disablePadding component="div">
                        <ListItemAvatar>
                          <Avatar>
                            <Receipt />
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary={
                            <>
                              {description ? `${description} - ` : null}
                              <SpanBolder>{noteUser.name}</SpanBolder>
                            </>
                          }
                          secondary={
                            <>
                              {stringDiffBetweenTwoDateTimes(start, finish)} -{' '}
                              <strong>{toHoursAndMinutes(minutes)}</strong>
                            </>
                          }
                          secondaryTypographyProps={{ component: 'span' }}
                        />

                        {noteUser.id === user?.id && isActive && (
                          <ListItem sx={{ width: 'auto' }} component="div">
                            <IconButton
                              edge="start"
                              onClick={() => setToggleEdit({ id, job: noteJob.id, description, start, finish })}
                            >
                              <EditIcon />
                            </IconButton>

                            <IconButton edge="end" onClick={() => setToggleDelete(note)}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItem>
                        )}
                      </ListItem>
                    );
                  })}

                  <ListItem sx={{ px: 0, pb: 0 }}>
                    <ListItemText
                      primary={
                        <div style={{ fontSize: 14, fontWeight: 800, color: '#707980' }}>
                          Total de horas:{' '}
                          {toHoursAndMinutes(data.reduce((accumulator, object) => accumulator + object.minutes, 0))}
                        </div>
                      }
                    />
                  </ListItem>
                </>
              ) : (
                <div>Nenhum apontamento anexado.</div>
              )}
            </List>
          ) : (
            <>Nenhum apontamento anexado.</>
          )
        ) : (
          <>Carregando...</>
        )}
      </JobDetailsItem>
    </>
  );
};
