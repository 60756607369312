// * MUI
import { styled, Button as MUIButton } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '30px 0 0',
  textAlign: 'center',
  maxWidth: '100%',
  padding: 30,
  [theme.breakpoints.down('md')]: { display: 'flex', flexDirection: 'column', padding: 30 },
}));

export const Logo = styled('div')({ '& svg': { height: 300, width: '100%' } });

export const Content = styled('div')({ 'padding': '0 30px', '& h1': { fontSize: 50 }, '& p': { fontSize: 24 } });

export const Button = styled(MUIButton)({
  margin: '30px auto',
  // border: '2px solid #000000',
  // backgroundColor: '#000000',
  border: '2px solid #17162e',
  backgroundColor: '#17162e',
  borderRadius: 40,
  fontSize: 16,
});
