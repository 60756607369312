import { useState, useEffect } from 'react';

// * MUI
import { AttachFile, Delete, Download } from '@mui/icons-material';
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

// * Components
import { Head } from '@/components/Data';
import { SpanBolder } from '@/components/Request/Details/Jobs/components';
import { JobDetailsItem } from '../components';
import { AddModal } from './Add';
import { DeleteModal } from './Delete';

// * Hooks & Utils
import { ICustomSnackbar, useAuth } from '@/hooks';
import { useQuery } from 'react-query';

// * Models
import { JobData, MediaData } from '@/models';

// * Services
import { fetchMediasByJobId } from '@/services';

interface IProps {
  job: JobData;
  snackbar: ICustomSnackbar;
}

export const JobsDetailsFiles = ({ job, snackbar }: IProps) => {
  const { user } = useAuth();
  const [toggleDelete, setToggleDelete] = useState<MediaData | undefined>(undefined);

  const { package: jobPackage, project: jobProject } = job;

  const { isLoading, isFetching, refetch, data } = useQuery(['medias', { jobId: String(job.id) }], async () =>
    fetchMediasByJobId(job.id),
  );

  const isActive = !((jobPackage && !jobPackage.is_active) ?? (jobProject && !jobProject.is_active));

  useEffect(() => {
    refetch();
  }, [job, refetch]);

  return (
    <>
      <Head>
        <div style={{ fontSize: '1.4rem', fontWeight: 700 }}>Arquivos: </div>

        {isActive && (
          <>
            <AddModal job={job} snackbar={snackbar} />

            {!job.finish && (
              <DeleteModal toggleDelete={toggleDelete} setToggleDelete={setToggleDelete} snackbar={snackbar} />
            )}
          </>
        )}
      </Head>

      <JobDetailsItem>
        {!(isLoading || isFetching) ? (
          data && data.length > 0 ? (
            <List dense disablePadding>
              {data.map((media) => {
                const { id, name, date_created, user: mediaUser, file } = media;

                const handleDownloadMedia = () => {
                  const anchorToDownloadMedia = document.createElement('a');
                  anchorToDownloadMedia.href = file;
                  anchorToDownloadMedia.target = '_blank';
                  anchorToDownloadMedia.click();
                  anchorToDownloadMedia.remove();
                };

                return (
                  <ListItem key={id} disablePadding component="div">
                    <ListItemAvatar>
                      <Avatar>
                        <AttachFile />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={name}
                      secondary={
                        <>
                          {new Date(date_created).toLocaleString()} - <SpanBolder>{mediaUser.name}</SpanBolder>
                        </>
                      }
                      secondaryTypographyProps={{ component: 'span' }}
                    />

                    <ListItem sx={{ width: 'auto' }} component="div">
                      <IconButton edge="start" onClick={handleDownloadMedia}>
                        <Download />
                      </IconButton>

                      {mediaUser.id === user?.id && !job.finish && isActive && (
                        <IconButton edge="end" onClick={() => setToggleDelete(media)}>
                          <Delete />
                        </IconButton>
                      )}
                    </ListItem>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <>Nenhum arquivo anexado.</>
          )
        ) : (
          <>Carregando...</>
        )}
      </JobDetailsItem>
    </>
  );
};
