import { useEffect, useState } from 'react';

// * Components
import { HeaderAccountContainer } from './components';
import { HeaderAccountDropdown } from './Dropdown';
import { HeaderAccountUserIcon } from './UserIcon';

// * Hooks
import { useAuth } from '@/hooks';

// * Models
import { AuthData } from '@/models';

export const HeaderAccount = () => {
  const { user } = useAuth();
  const [toggle, setToggle] = useState(false);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (closing) {
      const timeout = setTimeout(() => {
        setToggle(false);
        setClosing(false);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [closing]);

  const handleToggle = () => (toggle ? setClosing(true) : setToggle(true));

  return (
    <>
      <HeaderAccountContainer active={toggle} closing={closing} onClick={handleToggle}>
        <HeaderAccountUserIcon user={user as AuthData} />
      </HeaderAccountContainer>

      {toggle && <HeaderAccountDropdown toggle={handleToggle} closing={closing} />}
    </>
  );
};
