import { useState, useEffect } from 'react';

// * MUI
import { KeyboardArrowDown } from '@mui/icons-material';

// * Components
import {
  MenuItemStart,
  MenuItemIconContainer,
  MenuItemLabelContainer,
  MenuItemLabel,
  MenuItemSubIcon,
  MenuItemLink,
  SubMenuItemContainer,
} from './components';

// * Utils
import { useNavigate, useLocation } from 'react-router-dom';

interface IMenuItemProps {
  children?: React.ReactElement | React.ReactElement[];
  label: string;
  link?: string;
  icon: React.ReactElement;
  externalLink?: boolean;
}

export const MenuItem = ({ children, label, link, icon, externalLink }: IMenuItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [toggleSubmenu, setToggleSubmenu] = useState(false);
  const [closing, setClosing] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(location.pathname === link);
  }, [location, link]);

  const handleSubmenu = () => {
    if (!toggleSubmenu) return setToggleSubmenu(true);

    setClosing(true);
    setTimeout(() => {
      setToggleSubmenu(false);
      setClosing(false);
    }, 500);
  };

  const Item = () => (
    <>
      <MenuItemStart title={label}>
        <MenuItemIconContainer>{icon}</MenuItemIconContainer>

        <MenuItemLabelContainer>
          <MenuItemLabel>{label}</MenuItemLabel>
        </MenuItemLabelContainer>
      </MenuItemStart>

      {children && (
        <div>
          <MenuItemSubIcon>
            <KeyboardArrowDown />
          </MenuItemSubIcon>
        </div>
      )}
    </>
  );

  return (
    <>
      {link ? (
        <MenuItemLink
          className={selected ? 'selected' : ''}
          onClick={() => (externalLink ? window.open(link, '_blank') : navigate(link))}
        >
          <Item />
        </MenuItemLink>
      ) : (
        <MenuItemLink className={toggleSubmenu || selected ? 'active' : ''} onClick={handleSubmenu}>
          <Item />
        </MenuItemLink>
      )}

      {toggleSubmenu && <SubMenuItemContainer className={closing ? 'closing' : ''}>{children}</SubMenuItemContainer>}
    </>
  );
};
