import { Ref, forwardRef, useState, FC } from 'react';

// * MUI
import { Divider, Tooltip } from '@mui/material';

// * Components
import { classes, Container, NoRows, TableCell } from './components';

// * Hooks & Utils
import { useWindowSize } from '@/hooks';
import clsx from 'clsx';
import {
  AutoSizer as _AutoSizer,
  AutoSizerProps,
  Column as _Column,
  ColumnProps,
  SortDirection,
  SortDirectionType,
  SortIndicator,
  Table as _Table,
  TableCellRenderer,
  TableHeaderProps,
  TableProps,
} from 'react-virtualized';

// * Styles
import './styles.scss';

export interface ColumnData {
  dataKey: string;
  label: string;
  numeric?: boolean;
  width: number;
  formatValue?: (value: number | string | any) => JSX.Element;
  tooltip?: boolean;
  sortComparator?: (v1: any, v2: any) => 0 | 1 | -1;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps {
  tableData: any[];
  columns: readonly ColumnData[];
  headerHeight?: number;
  onRowClick?: () => void;
  rowHeight?: number;
  defaultSortBy?: string;
}

type SortProps = {
  sortBy: string;
  sortDirection: SortDirectionType;
};

const MuiVirtualizedTable = (
  { tableData, columns, headerHeight = 48, onRowClick, rowHeight = 48, defaultSortBy = 'id' }: MuiVirtualizedTableProps,
  ref: Ref<_Table>,
) => {
  const { width: windowWidth } = useWindowSize();

  const getRowClassName = ({ index }: Row) => {
    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  const headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number; sortDirection: string }) => {
    return (
      <>
        <TableCell component="div" variant="head" width={columns[columnIndex].width} align="center">
          <>
            {label}

            {sortDirection && <SortIndicator sortDirection={sortDirection} />}
          </>
        </TableCell>

        <Divider orientation="vertical" variant="middle" flexItem />
      </>
    );
  };

  const cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
    let value = cellData === '' || cellData === undefined || cellData === null ? 'Não informado.' : cellData;

    if (columnIndex != null && columns[columnIndex].formatValue) {
      value = columns[columnIndex].formatValue?.(cellData);
    }

    const cell = (
      <TableCell
        component="div"
        noClick={onRowClick == null}
        variant="body"
        width={columns[columnIndex].width}
        align="center"
      >
        {value}
      </TableCell>
    );

    return columnIndex != null && columns[columnIndex].tooltip ? <Tooltip title={value}>{cell}</Tooltip> : cell;
  };

  const noRowsRenderer = () => <NoRows>Nenhum registro.</NoRows>;

  const sortData = ({ sortBy: sortKey, sortDirection: sDirection }: SortProps) => {
    const compare =
      (key: string) =>
      (a: any, b: any): 0 | 1 | -1 => {
        const A = a[key];
        const B = b[key];

        const column = columns.find((c) => c.dataKey === key);

        if (column?.sortComparator) return column.sortComparator(A, B);

        return A < B ? -1 : A > B ? 1 : 0;
      };

    const sortedData = tableData.sort(compare(sortKey));

    setSortBy(sortKey);
    setSortDirection(sDirection);

    return sDirection === SortDirection.ASC ? sortedData.reverse() : sortedData;
  };

  const [sortBy, setSortBy] = useState<string>(defaultSortBy);
  const [sortDirection, setSortDirection] = useState<SortDirectionType>(SortDirection.DESC);

  const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
  const Table = _Table as unknown as FC<TableProps>;
  const Column = _Column as unknown as FC<ColumnProps>;

  return (
    <Container>
      {windowWidth && (
        <AutoSizer>
          {({ height, width }) => (
            <Table
              ref={ref}
              height={height}
              width={windowWidth > 900 ? width : columns.length * 150}
              rowHeight={rowHeight}
              headerHeight={headerHeight}
              rowCount={tableData.length}
              rowGetter={({ index }) => tableData[index]}
              rowClassName={getRowClassName}
              noRowsRenderer={noRowsRenderer}
              sort={sortData}
              sortBy={sortBy}
              sortDirection={sortDirection}
            >
              {columns.map(({ dataKey, ...rest }, index) => {
                return (
                  <Column
                    {...rest}
                    key={dataKey}
                    headerRenderer={(headerProps) =>
                      headerRenderer({ ...headerProps, columnIndex: index, sortDirection })
                    }
                    flexGrow={1}
                    cellRenderer={cellRenderer}
                    dataKey={dataKey}
                  />
                );
              })}
            </Table>
          )}
        </AutoSizer>
      )}
    </Container>
  );
};

export const VirtualizedTable = forwardRef(MuiVirtualizedTable);
