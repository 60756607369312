import { RequestPaginated, BonusData, BonusPostData, BonusPutData, BonusFilterData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/bonus';

export const fetchBonuses = async (page = 0, pageSize = 10): Promise<RequestPaginated<BonusData>> => {
  const { data } = await api.get(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}`);
  return data;
};

export const fetchBonusById = async (id: number): Promise<BonusData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

export const fetchAllBonuses = async (): Promise<BonusData[]> => {
  const { data } = await api.get(`${ENDPOINT}?paginate=false`);
  return data;
};

export const fetchBonusesToFilter = async (): Promise<BonusFilterData[]> => {
  const { data } = await api.get('/filters/bonus');
  return data;
};

export const createBonus = async (data: BonusPostData): Promise<BonusData> => {
  const { data: response } = await api.post(ENDPOINT, data);
  return response;
};

export const updateBonus = async (data: BonusPutData): Promise<BonusData> => {
  const { data: response } = await api.put(`${ENDPOINT}/${data.id}`, data);
  return response;
};
