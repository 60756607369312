import { useState, useEffect, useRef } from 'react';

// * MUI
import { Close, Cancel, Add as AddIcon, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Modal, IconButton, Button, Alert } from '@mui/material';

// * Components
import { Inputs } from '@/components';

// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { randomKey, uploadFiles } from '@/helpers';
import { Formik, Form, FormikConfig, FormikProps } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

// * Constants
import { mediaValidation } from '@/constants/validations/media';

// * Models
import { JobData } from '@/models';

// * Services
import { uploadDo, uploadFinish, uploadStart } from '@/services';

interface IProps {
  job: JobData;
  snackbar: ICustomSnackbar;
}

interface FormValues {
  job: number;
  name: string;
  files: File[];
}

export const AddModal = ({ job, snackbar }: IProps) => {
  const startMutate = useMutation(uploadStart);
  const doMutate = useMutation(uploadDo);
  const finishMutate = useMutation(uploadFinish);
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const queryClient = useQueryClient();
  const [fileInputKey, setFileInputKey] = useState<string>(randomKey());
  const [toggleAdd, setToggleAdd] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);

  const handleSubmit: FormikConfig<FormValues>['onSubmit'] = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const onError = (error: unknown) => {
      console.error(error);
      setSubmitting(false);
      snackbar.showError('Erro ao adicionar arquivo(s).');
    };

    const onFinish = () => {
      setUpload(true);
      setSubmitting(false);
      resetForm();
    };

    await uploadFiles({
      endpointName: 'job',
      endpointId: values.job,
      startMutation: startMutate,
      doMutation: doMutate,
      finishMutation: finishMutate,
      snackbar,
      onError,
      onFinish,
      files: values.files,
      name: values.name,
    });
  };

  useEffect(() => {
    if (upload) {
      queryClient.invalidateQueries(['medias', { jobId: String(job.id) }]);
      setUpload(false);
      setFileInputKey(randomKey());
      snackbar.showSuccess('Arquivo(s) adicionado(s) com sucesso.');
    }
  }, [upload, snackbar, job.id, queryClient]);

  const initialValues: FormValues = { job: job.id, name: '', files: [] };

  const handleClose = () => setToggleAdd(false);
  const handleOpen = () => setToggleAdd(true);

  return (
    <>
      <Modal
        open={!!toggleAdd}
        onClose={(_, reason) => reason !== 'backdropClick' && handleClose()}
        className="delete-modal-wrapper"
      >
        <div className="delete-modal-container">
          <div className="delete-modal-head">
            <h1>Enviar arquivo</h1>

            <div>
              <IconButton
                className="delete-modal-close-icon"
                onClick={handleClose}
                disabled={formikRef.current?.isSubmitting}
              >
                <Close />
              </IconButton>
            </div>
          </div>

          <div className="delete-modal-content" style={{ display: 'block' }}>
            {job.finish && (
              <Alert severity="warning">
                Esse Job já foi finalizado, ao inserir um novo comentário, ele não poderá ser alterado ou removido!
              </Alert>
            )}

            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              innerRef={formikRef}
              validationSchema={mediaValidation}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Inputs.Input
                    type="text"
                    name="name"
                    label="Nome *:"
                    id="name"
                    values={values}
                    handleChange={setFieldValue}
                  />

                  <Inputs.Input
                    key={fileInputKey ?? ''}
                    type="file"
                    name="files"
                    label="Arquivo (s) *:"
                    id="files"
                    multiple
                    placeholder="Selecione um ou mais arquivos..."
                    handleChange={(e: any) => setFieldValue('files', e.currentTarget.files)}
                    values={values}
                  />

                  <div style={{ display: 'flex', gap: 5, justifyContent: 'flex-end' }}>
                    <LoadingButton
                      type="submit"
                      loading={isSubmitting}
                      loadingPosition="center"
                      variant="contained"
                      startIcon={<Save />}
                    >
                      Salvar
                    </LoadingButton>

                    <Button variant="outlined" onClick={handleClose} disabled={isSubmitting} startIcon={<Cancel />}>
                      Cancelar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>

      <IconButton className="data-grid-add-icon" onClick={handleOpen}>
        <AddIcon />
      </IconButton>
    </>
  );
};
