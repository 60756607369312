import { useState, useEffect, useCallback } from 'react';

// * Hooks & Helpers
import { useCustomSnackbar } from '@/hooks';
import { clearTokensAndHeader, getLocalAccessToken, setTokensAndHeader } from '@/helpers';
import { useNavigate, useLocation } from 'react-router-dom';

// * Models
import { AuthData, LoginData } from '@/models';

// * Contexts
import { AuthContext } from './AuthContext';

// * Services
import api from '@/services/api';

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const snackbar = useCustomSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = getLocalAccessToken();
  const from = location.state?.from?.pathname || '/';

  const getUserByToken = async () => {
    const { data: userData } = await api.get<AuthData>('/users/me');

    return userData;
  };

  const [user, setUser] = useState<AuthData | null>(null);

  const logout = useCallback(() => {
    clearTokensAndHeader();
    setUser(null);
    navigate('/auth', { replace: true });
  }, [navigate]);

  const login = useCallback(
    async (data: LoginData) => {
      setTokensAndHeader(data.access, data.refresh);

      const userData = await getUserByToken();
      const { is_superuser, is_customer, is_internal } = userData;

      if (!is_superuser && !is_customer && !is_internal) {
        snackbar.showError(
          'O usuário está sem permissão para acessar o sistema, por favor, entre em contato com algum colaborador.',
        );

        return logout();
      }

      setUser(userData);
      navigate(from, { replace: true });
    },
    [snackbar, logout, from, navigate],
  );

  useEffect(() => {
    if (accessToken && !user) {
      getUserByToken()
        .then((userData) => setUser(userData))
        .catch(() => logout());
    }
  }, [accessToken, user, logout]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isCustomer: Boolean(user?.is_customer),
        isInternal: Boolean(user?.is_internal),
        isStaff: Boolean(user?.is_staff),
        isFetchingUser: Boolean(accessToken) && !user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
