import { useState, useRef, useEffect } from 'react';

// * MUI
import { Dialog, AppBar, Toolbar, Typography, IconButton, Autocomplete, Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close, FilterAlt } from '@mui/icons-material';
import { GridPaginationModel } from '@mui/x-data-grid';

// * Components
import {
  Form,
  Container,
  FormActions,
  DataGridFilterContainerContent,
  Input,
  InputWrapper,
  InputWrapperContainer,
  Title,
} from './components';
import { DataGrid } from '@/components';

// * Hooks & Utils
import { Formik } from 'formik';
import { useQueries, UseQueryResult } from 'react-query';

// * Constants
import { requestsColDef } from './colDef';

// * Models
import { RequestPaginated, RequestData } from '@/models';

// * Services
import { fetchAllRequestStatuses, fetchRequestsUsersByPackageIdOrProjectId } from '@/services';

interface IJobRequestDialogProps {
  openDialogRequest: boolean;
  setOpenDialogRequest: React.Dispatch<React.SetStateAction<boolean>>;
  packageId: string | undefined;
  projectId: string | undefined;
  setRequestId: React.Dispatch<React.SetStateAction<string | undefined>>;
  requestQuery: UseQueryResult<RequestPaginated<RequestData>, unknown>;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  paginationModel: GridPaginationModel;
  onPaginationModelChange: (model: GridPaginationModel) => void;
}

export const JobRequestDialog = ({
  openDialogRequest,
  setOpenDialogRequest,
  packageId,
  projectId,
  setRequestId,
  requestQuery,
  setFilters,
  setFieldValue,
  paginationModel,
  onPaginationModelChange,
}: IJobRequestDialogProps) => {
  const [toggleFilter, setToggleFilter] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);

  const queries = useQueries([
    {
      queryKey: ['requests-users', { packageId, projectId }],
      queryFn: async () =>
        fetchRequestsUsersByPackageIdOrProjectId(
          packageId ? parseInt(packageId) : null,
          projectId ? parseInt(projectId) : null,
        ),
      enabled: !!(packageId ?? projectId),
    },
    {
      queryKey: 'requeststatus',
      queryFn: fetchAllRequestStatuses,
    },
  ]);

  // * Refs
  const filterWrapper = useRef<HTMLDivElement | null>(null);

  // * ~kindof~ error handler
  useEffect(() => {
    !requestQuery.isLoading &&
      setTimeout(() => {
        filterWrapper.current?.offsetHeight && setTableHeight(filterWrapper.current?.offsetHeight);
      }, 200);
  }, [requestQuery.isLoading]);

  const handleSubmitFilter = (values: any, { setSubmitting }: any) => {
    setSubmitting(true);
    setFilters(values);
    setSubmitting(false);
  };
  const handleToggle = () => setOpenDialogRequest(!openDialogRequest);

  const renderSelect = (rowId: string) => {
    setFieldValue('request', rowId);
    setRequestId(rowId);
    handleToggle();
    onPaginationModelChange({ ...paginationModel, page: 0 });
    setFilters({});
  };

  const colDef = requestsColDef(renderSelect);
  const initialValues = { title: '', user: '', status: '' };

  return (
    <Dialog fullScreen open={openDialogRequest} onClose={handleToggle}>
      <AppBar
        position="static"
        sx={{
          // backgroundColor: '#000000',
          backgroundColor: '#17162e',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Pesquisar solicitação
          </Typography>

          <div style={{ display: 'flex' }} onClick={handleToggle}>
            <Typography variant="h6" component="div" sx={{ mr: 2, cursor: 'pointer', alignSelf: 'center' }}>
              Fechar
            </Typography>

            <IconButton edge="end">
              <Close sx={{ color: 'white' }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <div style={{ width: '100%', height: '100%', paddingLeft: '1.4rem', paddingRight: '1.4rem' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>Solicitações</h1>
        </div>

        <div style={{ width: '100%', height: '80%' }}>
          <Container ref={filterWrapper}>
            <Title onClick={() => setToggleFilter(!toggleFilter)}>
              <label>Filtrar</label>

              <IconButton>
                <FilterAlt />
              </IconButton>
            </Title>

            <DataGridFilterContainerContent active={toggleFilter}>
              <Formik initialValues={initialValues} onSubmit={handleSubmitFilter}>
                {({ isSubmitting, values, setFieldValue }) => (
                  <Form>
                    <Input>
                      <InputWrapper>
                        <div>
                          <label htmlFor="title">Título</label>
                        </div>

                        <InputWrapperContainer>
                          <TextField
                            onChange={(e) => setFieldValue('title', e.target.value)}
                            value={values.title}
                            id="title"
                            name="title"
                            variant="outlined"
                            itemID="title"
                          />
                        </InputWrapperContainer>
                      </InputWrapper>
                    </Input>

                    <Input>
                      <InputWrapper>
                        <div>
                          <label htmlFor="user">Solicitante</label>
                        </div>

                        <InputWrapperContainer>
                          <Autocomplete
                            disablePortal
                            onChange={(_, value) => setFieldValue('user', value ?? '')}
                            isOptionEqualToValue={(option: string, value: string) => option === value}
                            getOptionLabel={(option) =>
                              option
                                ? queries[0].data?.filter((dataInput) => String(dataInput.id) === String(option))[0]
                                    ?.name ?? 'Todos'
                                : 'Todos'
                            }
                            clearOnBlur
                            clearOnEscape
                            id="user"
                            options={queries[0].data ? ['', ...queries[0].data.map((user) => String(user.id))] : ['']}
                            value={values.user}
                            key="user"
                            sx={{ width: 210 }}
                            renderInput={(params) => <TextField {...params} name="user" />}
                            renderOption={(props, option) => (
                              <Box
                                {...props}
                                component="li"
                                key={option}
                                id="user"
                                style={{ display: queries[0].data ? 'flex' : 'none' }}
                              >
                                {queries[0].data?.filter((user) => String(user.id) === option)[0]?.name ?? 'Todos'}
                              </Box>
                            )}
                          />
                        </InputWrapperContainer>
                      </InputWrapper>
                    </Input>

                    <Input>
                      <InputWrapper>
                        <div>
                          <label htmlFor="status">Status</label>
                        </div>

                        <InputWrapperContainer>
                          <Autocomplete
                            disablePortal
                            onChange={(e, value) => setFieldValue('status', value ?? '')}
                            isOptionEqualToValue={(option: string, value: string) => option === value}
                            getOptionLabel={(option) =>
                              option
                                ? queries[1].data?.filter((dataInput) => String(dataInput.id) === String(option))[0]
                                    ?.name ?? 'Todos'
                                : 'Todos'
                            }
                            clearOnBlur
                            clearOnEscape
                            id="status"
                            options={
                              queries[1].data ? ['', ...queries[1].data.map((status) => String(status.id))] : ['']
                            }
                            value={values.status}
                            key="status"
                            sx={{ width: 210 }}
                            renderInput={(params) => <TextField {...params} name="status" />}
                            renderOption={(props, option) => (
                              <Box
                                {...props}
                                component="li"
                                key={option}
                                id="status"
                                style={{ display: queries[1].data ? 'flex' : 'none' }}
                              >
                                {queries[1].data?.filter((status) => String(status.id) === option)[0]?.name ?? 'Todos'}
                              </Box>
                            )}
                          />
                        </InputWrapperContainer>
                      </InputWrapper>
                    </Input>

                    <FormActions>
                      <LoadingButton
                        type="submit"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        loadingPosition="center"
                        variant="contained"
                        startIcon={<FilterAlt />}
                      >
                        Filtrar
                      </LoadingButton>
                    </FormActions>
                  </Form>
                )}
              </Formik>
            </DataGridFilterContainerContent>
          </Container>

          <DataGrid.Wrapper
            loading={requestQuery.isLoading || requestQuery.isRefetching}
            tableHeight={tableHeight}
            data={requestQuery.data?.results ?? []}
            count={requestQuery.data?.count ?? 0}
            getRowClassName={(params) => `requests-priority-${params.row.priority.order}`}
            colDef={colDef}
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
          />
        </div>
      </div>
    </Dialog>
  );
};
