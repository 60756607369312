import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Apps = loadable(async () => import('pages/app/Data'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      home: components.DataAppsHome,
      details: components.DataAppsDetails,
      add: components.DataAppsAdd,
      edit: components.DataAppsEdit,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `apps-${props.page}`,
});
