/**
 * Handler to convert decimal to time
 * @param decimal
 * @returns "HH:MM"
 */

export const decimalToTime = (decimal: number) => {
  const sign = decimal < 0 ? '-' : '';
  const min = Math.floor(Math.abs(decimal));
  const sec = Math.floor((Math.abs(decimal) * 60) % 60);

  return sign + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec;
};
