// * MUI
import { styled } from '@mui/material';

export const PageContainer = styled('div')((props) => ({
  marginLeft: 0,
  marginTop: 55,
  width: 'calc(100vw - 55px)',
  height: 'calc(100vh - 55px)',
  background: '#F9F9F9',
  padding: '1.4rem',
  // color: '#000000',
  color: '#17162E',
  [props.theme.breakpoints.up('md')]: { marginLeft: 55 },
}));

export const PageContainerHead = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1.4rem',
  flexWrap: 'wrap',
  h1: { margin: 0 },
  // button: { height: 40 },
  button: { 'height': 40, 'backgroundColor': '#3F19FF', '&:hover': { backgroundColor: '#2A10AF' } },
});

interface IPageContainerContentProps {
  auto?: boolean;
  max?: boolean;
}

export const PageContainerContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'auto' && prop !== 'max',
})<IPageContainerContentProps>((props) => ({
  background: '#FEFEFE',
  borderRadius: 4,
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  ...(props.auto && { height: 'auto', maxHeight: '90%', overflowY: 'auto' }),
  ...(props.max && { height: '90%', display: 'flex', flexDirection: 'column' }),
}));
