/**
 * Format date string to locale date string
 * @param date - Date string, number or Date object
 * @returns string
 */

export const formatDateString = (date: string | Date | number) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
