import {
  DataGridFilterContainer,
  DataGridFilterContainerTitle,
  // DataGridFilterContainerContent,
  DataGridFilterContainerContentForm,
  DataGridFilterContainerContentFormInput,
  DataGridFilterContainerContentFormInputWrapper,
  DataGridFilterContainerContentFormInputWrapperContainer,
  DataGridFilterContainerContentFormActions,
} from 'components/DataGrid/Filter/components';

const Container = DataGridFilterContainer;
const Title = DataGridFilterContainerTitle;
// const Content = DataGridFilterContainerContent
const Form = DataGridFilterContainerContentForm;
const Input = DataGridFilterContainerContentFormInput;
const InputWrapper = DataGridFilterContainerContentFormInputWrapper;
const InputWrapperContainer = DataGridFilterContainerContentFormInputWrapperContainer;
const FormActions = DataGridFilterContainerContentFormActions;

export {
  Container,
  Title,
  // Content,
  Form,
  Input,
  InputWrapper,
  InputWrapperContainer,
  FormActions,
};
export { DataGridFilterContainerContent } from 'components/DataGrid/Filter/components';
