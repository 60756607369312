import { MediaData, MediaPatchOrderData } from '@/models';
import axios, { AxiosProgressEvent } from 'axios';
import api from '@/services/api';

const ENDPOINT = '/medias';

export const fetchMediasByJobId = async (jobId: number): Promise<MediaData[]> => {
  const { data } = await api.get(`${ENDPOINT}?job=${jobId}`);
  return data;
};

export const fetchMediasByRequestId = async (requestId: number): Promise<MediaData[]> => {
  const { data } = await api.get(`${ENDPOINT}?request=${requestId}`);
  return data;
};

export const fetchMediasByAppId = async (appId: number): Promise<MediaData[]> => {
  const { data } = await api.get(`${ENDPOINT}?app=${appId}`);
  return data;
};

export const fetchMediasByServiceId = async (serviceId: number): Promise<MediaData[]> => {
  const { data } = await api.get(`${ENDPOINT}?service=${serviceId}`);
  return data;
};

export const updateMediaOrder = async (data: MediaPatchOrderData): Promise<MediaData> => {
  const { data: response } = await api.patch(`${ENDPOINT}/${data.id}`, data);
  return response;
};

export const deleteMedia = async (data: MediaData) => {
  const { data: response } = await api.delete(`${ENDPOINT}/${data.id}`);
  return response;
};

const UPLOAD_ENDPOINT = '/upload/direct';

// * START
export type StartProps = {
  job?: number;
  request?: number;
  app?: number;
  service?: number;
  name: string;
  file_name: string;
};

export type StartResponse = {
  id: number;
  url: string;
  fields?: Record<string, string>;
};

export const uploadStart = async (data: StartProps) => {
  const { data: response } = await api.post<StartResponse>(`${UPLOAD_ENDPOINT}/start`, data);
  return response;
};
// * START

// * DO
export type DoProps = {
  data: StartResponse;
  file: File;
  progressCallback?: (progressEvent: AxiosProgressEvent) => void;
};
export type DoResponse = { media_id: number };

export const uploadDo = async ({ data, file, progressCallback }: DoProps) => {
  const formData = new FormData();
  for (const key in data?.fields) formData.append(key, data.fields[key]);
  formData.append('file', file);

  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      progressCallback && progressCallback(progressEvent);

      return progressEvent;
    },
  };

  // if the backend is configured for upload:
  // locally - fields must not exist, using authenticated axios instance (default)
  // s3 - fields must exist, using new instance of axios, no authentication
  const axiosInstance = data.fields ? axios.create() : api;

  await axiosInstance.post<DoResponse>(data.url, formData, config);

  return { media_id: data.id };
};
// * DO

// * FINISH
export type FinishProps = { media_id: number };
export type FinishResponse = { id: number };

export const uploadFinish = async (data: FinishProps) => {
  const { data: response } = await api.post<FinishResponse>(`${UPLOAD_ENDPOINT}/finish/${data.media_id}`);
  return response;
};
// * FINISH
