// * Components
import {
  JobDetailsDescriptionItemContainer,
  JobDetailsDescriptionItemContainerContent,
  JobDetailsDescriptionItemContainerLabel,
  JobDetailsDescriptionItemValueFlag,
} from '../components';

interface IJobDetailsDescriptionItemProps {
  label: string;
  children: React.ReactNode;
  wide?: boolean;
  flag?: boolean;
  onClick?: () => void;
  clickable?: boolean;
  title?: string;
  condition?: boolean;
  background?: string;
}

export const JobDetailsDescriptionItem = ({
  label,
  children,
  wide,
  flag,
  onClick,
  clickable,
  title,
  condition,
  background,
}: IJobDetailsDescriptionItemProps) => {
  const flagDiv = flag && (
    <JobDetailsDescriptionItemValueFlag style={{ background }}>
      <p>{children}</p>
    </JobDetailsDescriptionItemValueFlag>
  );

  const conditional = condition === undefined || condition;
  const flagConditional = conditional ? flagDiv : <>Nenhum</>;

  return (
    <JobDetailsDescriptionItemContainer wide={wide}>
      <JobDetailsDescriptionItemContainerLabel>{label}</JobDetailsDescriptionItemContainerLabel>

      <JobDetailsDescriptionItemContainerContent
        clickable={clickable && (flag ?? conditional)}
        title={title}
        onClick={flag ?? conditional ? onClick : undefined}
      >
        {flag ? flagConditional : conditional ? children : <>Nenhum</>}
      </JobDetailsDescriptionItemContainerContent>
    </JobDetailsDescriptionItemContainer>
  );
};
