import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Reports = loadable(async () => import('@/pages/app/Reports'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      'customer': components.ReportsCustomer,
      'insights-home': components.HomeInsights,
      'insights-report': components.ReportInsights,
      'jobs': components.ReportsJobs,
      'notes': components.ReportsNotes,
      'packages': components.ReportsPackages,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `reports-${props.page}`,
});
