import {
  RequestPaginated,
  PackageData,
  PackageConsumptionData,
  PackagePostData,
  PackageFilterData,
  PackagePutData,
  ContractData,
} from '@/models';
import { sortBetweenName } from '@/helpers';
import api from '@/services/api';

const ENDPOINT = '/packages';

export const fetchPackages = async (page = 0, pageSize = 10, filters?: any) => {
  const params = filters ? `&${new URLSearchParams(filters).toString()}` : '';
  const { data } = await api.get<RequestPaginated<PackageData>>(
    `${ENDPOINT}?p=${page + 1}&page_size=${pageSize}${params}`,
  );
  return data;
};

export const fetchPackagesToFilter = async (): Promise<PackageFilterData[]> => {
  const { data } = await api.get('/filters/packages');
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const fetchPackageById = async (id: number) => {
  const { data } = await api.get<PackageData>(`${ENDPOINT}/${id}`);
  return data;
};

export type ListPackageData = PackageData & {
  last_contract: Omit<ContractData, 'hours' | 'hours_price' | 'hours_price_extra'> | Record<string, never>;
};

export const fetchPackagesById = async (id: number[]) => {
  const { data } = await api.get<ListPackageData[]>(`${ENDPOINT}?ids=${id.join(',')}&paginate=false`);
  return data;
};

export const fetchPackagesByCustomerId = async (customerId: number): Promise<ListPackageData[]> => {
  const { data } = await api.get(`${ENDPOINT}?customer=${customerId}&paginate=false`);
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const fetchActivePackagesByCustomerId = async (customerId: number): Promise<ListPackageData[]> => {
  const { data } = await api.get(`${ENDPOINT}?customer=${customerId}&is_active=true&paginate=false`);
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const fetchPackageConsumptionByPeriod = async (packageId: number, period: string) => {
  const { data } = await api.get<PackageConsumptionData>(
    `${ENDPOINT}/${packageId}/package_consumption?period=${period}`,
  );
  return data;
};

export const createPackage = async (data: PackagePostData) => {
  const { data: response } = await api.post<PackageData>(ENDPOINT, data);
  return response;
};

export const updatePackage = async (data: PackagePutData) => {
  const { data: response } = await api.put<PackageData>(`${ENDPOINT}/${data.id}`, data);
  return response;
};
