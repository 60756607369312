// * MUI
import { styled } from '@mui/material';

export const DataFormActions = styled('div')(({ theme }) => ({
  'margin': '1rem',
  'display': 'flex',
  'gap': '1rem',
  'justifyContent': 'flex-end',
  'flexGrow': 1,
  [theme.breakpoints.down('md')]: { marginTop: '1rem', marginBottom: '1rem', justifyContent: 'center' },
  '.MuiLoadingButton-root': { height: 40 },
}));
