import { useState } from 'react';

// * Hooks & Utils
import { useAuth } from './useAuth';
import { usePermission } from './usePermission';
import { EditorState } from 'draft-js';
import { useNavigate } from 'react-router-dom';
import { useCustomSnackbar } from './useCustomSnackbar';
import { useMutation } from 'react-query';

// * Services
import { createJob, updateJob } from '@/services';

const updateFieldValueAndReset = (
  name: string,
  value: unknown,
  resetFields: string[],
  setFieldValue: (field: string, value: unknown) => void,
) => {
  setFieldValue(name, value);
  resetFields.forEach((field) => setFieldValue(field, null));
};

export const transformTimeData = (estimated_time: number, package_consumption: number) => {
  const estimated_time_hours = Math.floor(estimated_time / 60);
  const estimated_time_minutes = estimated_time % 60;

  let package_consumption_hours = 0;
  let package_consumption_minutes = 0;

  if (package_consumption) {
    package_consumption_hours = Math.floor(package_consumption / 60);
    package_consumption_minutes = package_consumption % 60;
  }

  return {
    estimated_time_hours,
    estimated_time_minutes,
    package_consumption_hours,
    package_consumption_minutes,
  };
};

export const splitPackageDate = (package_date: string | null) => {
  let package_year = '';
  let package_month = '';

  if (package_date) {
    [package_year, package_month] = package_date.split('-');
  }

  return { package_year, package_month };
};

interface JobFormCommonValues {
  customer: number;
  project: number | null;
  package: number | null;
  package_year: number | null;
  package_month: string | null;
  request: number;
  requester: number;
  start: string;
  estimated_time_hours: number;
  estimated_time_minutes: number;
  internal_term: string;
  customer_term: string;
  title: string;
  briefing: string;
  status: number | null;
  package_consumption_hours: number;
  package_consumption_minutes: number;
  bonus: number | null;
  budget: boolean;
  publication_date: string | null;
}

export interface JobFormValuesSingleResponsible extends JobFormCommonValues {
  responsible: number;
}

export interface JobFormValuesMultipleResponsible extends JobFormCommonValues {
  responsible: number[];
}

export type JobFormValues = JobFormValuesSingleResponsible | JobFormValuesMultipleResponsible;

const createJobData = (values: JobFormValues, responsible: number) => {
  const convertToMinutes = (hours: number, minutes: number) => hours * 60 + minutes;

  const {
    package_year,
    package_month,
    estimated_time_hours,
    estimated_time_minutes,
    package_consumption_hours,
    package_consumption_minutes,
    ...data
  } = values;

  const jobData = Object.assign({
    ...data,
    followers: [],
    estimated_time: convertToMinutes(estimated_time_hours, estimated_time_minutes),
    package_consumption: convertToMinutes(package_consumption_hours, package_consumption_minutes),
    ...(values.package ? { package_date: `${package_year}-${package_month}-01` } : {}),
  });

  return { ...jobData, responsible };
};

export const useJobData = () => {
  const [customerId, setCustomerId] = useState<string | undefined>();
  const [packageId, setPackageId] = useState<string | undefined>();
  const [projectId, setProjectId] = useState<string | undefined>();
  const [requestId, setRequestId] = useState<string | undefined>();
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [success, setSuccess] = useState<number[]>([]);

  const [openDialogRequest, setOpenDialogRequest] = useState(false);
  const [isPackageExpired, setPackageExpired] = useState(false);

  const { user, isCustomer } = useAuth();
  const { verifyPermission, jobsPermissions } = usePermission();
  const navigate = useNavigate();
  const snackbar = useCustomSnackbar();
  const {
    mutate: createJobMutation,
    mutateAsync: createJobAsyncMutation,
    isLoading: createJobIsLoading,
  } = useMutation(createJob);
  const { mutate: updateJobMutation } = useMutation(updateJob);

  return {
    user,
    isCustomer,
    verifyPermission,
    jobsPermissions,
    navigate,
    snackbar,
    createJobMutation,
    createJobAsyncMutation,
    createJobIsLoading,
    updateJobMutation,
    customerId,
    setCustomerId,
    packageId,
    setPackageId,
    projectId,
    setProjectId,
    requestId,
    setRequestId,
    editorState,
    setEditorState,
    success,
    setSuccess,
    openDialogRequest,
    setOpenDialogRequest,
    isPackageExpired,
    setPackageExpired,
    transformTimeData,
    updateFieldValueAndReset,
    createJobData,
    splitPackageDate,
  };
};
