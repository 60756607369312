// * MUI
import { keyframes, styled } from '@mui/material';

const showModal = keyframes`
0% {
  opacity: 0;
  max-height: 0px;
}
60% {
  opacity: 1;
}
99% {
  overflow: hidden;
}
100% {
  opacity: 1;
  max-height: 200px;
  overflow: visible;
}
`;
const hideModal = keyframes`
0% {
  opacity: 1;
  max-height: 200px;
  overflow: hidden;
}
60% {
  opacity: 1;
}
100% {
  opacity: 0;
  max-height: 0px;
}
`;

interface IHeaderAccountDropdownContainerProps {
  closing?: boolean;
}

export const HeaderAccountDropdownContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'closing',
})<IHeaderAccountDropdownContainerProps>((props) => ({
  position: 'absolute',
  top: 60,
  right: 10,
  backgroundColor: '#F9F9F9',
  boxShadow: '1px 1px 8px rgba(0, 0, 0, 0.2)',
  opacity: 0,
  maxHeight: 0,
  overflow: 'hidden',
  hr: { margin: 0, border: 'none', borderBottom: '1px solid #E1E1E1' },
  animation: `${showModal} 0.5s ease-out forwards 0.15s`,
  ...(props.closing && { animation: `${hideModal} 0.5s ease-in forwards` }),
}));

export const HeaderAccountDropdownContainerLogout = styled('div')({
  'textAlign': 'end',
  'padding': '0.8rem 1rem',
  'cursor': 'pointer',
  'transition': '0.2s ease-in',
  '&:hover': { backgroundColor: '#F959592D', color: '#F95959', transition: '0.2s ease-in' },
});
