import { RequestPaginated, AppData, AppPostData, AppPutData, AppPatchThumbnailData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/apps';

export const fetchApps = async (page = 0, pageSize = 10): Promise<RequestPaginated<AppData>> => {
  const { data } = await api.get(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}`);
  return data;
};

export const fetchAppById = async (id: number): Promise<AppData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': '*/*',
  },
};

type AppPostKeys = keyof AppPostData;

export const createApp = async (data: AppPostData): Promise<AppData> => {
  const formData = new FormData();

  for (const key in data) {
    if (typeof data[key as AppPostKeys] === 'boolean') {
      formData.append(key, String(data[key as AppPostKeys]));
    }
    formData.append(key, data[key as AppPostKeys] as string | Blob);
  }

  const { data: response } = await api.post(`${ENDPOINT}`, formData, config);
  return response;
};

type AppPutKeys = keyof AppPutData;

export const updateApp = async (data: AppPutData): Promise<AppData> => {
  const formData = new FormData();

  for (const key in data) {
    if (typeof data[key as AppPutKeys] === 'boolean') {
      formData.append(key, String(data[key as AppPutKeys]));
    }
    formData.append(key, data[key as AppPutKeys] as string);
  }

  const { data: response } = await api.put(`${ENDPOINT}/${data.id}`, formData, config);
  return response;
};

export const updateAppThumbnail = async (data: AppPatchThumbnailData): Promise<AppData> => {
  const formData = new FormData();
  formData.append('thumbnail', data.thumbnail);

  const { data: response } = await api.patch(`${ENDPOINT}/${data.id}`, formData, config);
  return response;
};
