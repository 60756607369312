// * MUI
import { styled } from '@mui/material';

interface IScheduleFooterProps {
  error: boolean;
}

export const ScheduleFooterContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error',
})<IScheduleFooterProps>((props) => ({
  padding: '4px',
  marginTop: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  ...(props.error && { color: '#f95959' }),
}));

export const ScheduleFooterContainerMinutes = styled('span')({ fontSize: '0.7rem', alignSelf: 'center' });
