import {
  RequestPaginated,
  NotificationData,
  UserFilterData,
  UserListData,
  UserRetrieveData,
  UserPostData,
  UserPutData,
  NotificationPatchData,
} from '@/models';
import { sortBetweenName } from '@/helpers';
import api from '@/services/api';

const ENDPOINT = '/users';

export const fetchUsers = async (page = 0, pageSize = 10, filters?: any) => {
  const params = filters ? `&${new URLSearchParams(filters).toString()}` : '';
  const { data } = await api.get<RequestPaginated<UserListData>>(
    `${ENDPOINT}?p=${page + 1}&page_size=${pageSize}${params}`,
  );
  return data;
};

export const fetchUserById = async (id: number) => {
  const { data } = await api.get<UserRetrieveData>(`${ENDPOINT}/${id}`);
  return data;
};

export const fetchUsersById = async (id: number[]) => {
  const { data } = await api.get<UserListData[]>(`${ENDPOINT}?ids=${id.join(',')}&paginate=false`);
  return data;
};

export const fetchUsersByCustomerId = async (customerId: number) => {
  const { data } = await api.get<UserListData[]>(`${ENDPOINT}?customer=${customerId}&paginate=false`);
  return data;
};

export const fetchUserNotificationByUserId = async (userId: number) => {
  const { data } = await api.get<NotificationData>(`${ENDPOINT}/${userId}/notifications`);
  return data;
};

export const fetchUsersToFilter = async () => {
  const { data } = await api.get<UserFilterData[]>('/filters/users');
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const fetchInternalUsers = async (): Promise<UserListData[]> => {
  const { data } = await api.get(`${ENDPOINT}?is_internal=true&is_active=true`);
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const fetchCustomerUsers = async (): Promise<UserListData[]> => {
  const { data } = await api.get(`${ENDPOINT}?is_customer=true&is_active=true`);
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const createUser = async (data: UserPostData) => {
  const { data: response } = await api.post<UserRetrieveData>(ENDPOINT, data);
  return response;
};

export const createUserPassword = async (data: { password: string }) => {
  const { data: response } = await api.post(`${ENDPOINT}/set_password`, data);
  return response;
};

export const updateUser = async (data: UserPutData) => {
  const { data: response } = await api.put<UserRetrieveData>(`${ENDPOINT}/${data.id}`, data);
  return response;
};

export const updateUserPassword = async (data: { old_password: string; new_password: string }) => {
  const { data: response } = await api.patch<{ code: number; status: string }>(`${ENDPOINT}/change_password`, data);
  return response;
};

export const updateUserNotifications = async (data: NotificationPatchData) => {
  const { data: response } = await api.patch<NotificationData>(`${ENDPOINT}/${data.user}/notifications`, data);
  return response;
};

export const fetchHasUsablePassword = async () => {
  const { data } = await api.get<{ has_usable_password: boolean }>(`${ENDPOINT}/has_usable_password`);
  return data;
};
