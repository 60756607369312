import { useState, useEffect } from 'react';

// * MUI
import { Cancel, Close, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, IconButton, Modal } from '@mui/material';

// * Components
import { Inputs, Spinner } from '@/components';

// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { Formik, Form, FormikConfig } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

// * Constants
import { noteValidation } from '@/constants/validations/note';

// * Models
import { NotePutData } from '@/models';

// * Services
import { updateNote } from '@/services';

type CustomNotePutData = NotePutData & { date: Date };

interface IProps {
  toggleEdit: NotePutData | undefined;
  setToggleEdit: (value: NotePutData | undefined) => void;
  snackbar: ICustomSnackbar;
}

export const EditModal = ({ toggleEdit, setToggleEdit, snackbar }: IProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(updateNote);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<CustomNotePutData>();

  useEffect(() => {
    if (toggleEdit) {
      const noteStartDate = new Date(toggleEdit.start);
      const dateConstructor = new Date(noteStartDate.getFullYear(), noteStartDate.getMonth(), noteStartDate.getDate());

      const noteStartDateTime = new Date(toggleEdit.start);
      const noteFinishDateTime = new Date(toggleEdit.finish);

      const startDateTime = new Date(dateConstructor);
      startDateTime.setHours(noteStartDateTime.getHours());
      startDateTime.setMinutes(noteStartDateTime.getMinutes());

      const finishDateTime = new Date(dateConstructor);
      finishDateTime.setHours(noteFinishDateTime.getHours());
      finishDateTime.setMinutes(noteFinishDateTime.getMinutes());

      setInitialValues({
        id: toggleEdit.id,
        job: toggleEdit.job,
        description: toggleEdit.description,
        date: dateConstructor,
        start: startDateTime,
        finish: finishDateTime,
      });
      setLoading(false);
    }
  }, [toggleEdit]);

  const handleSubmit: FormikConfig<CustomNotePutData>['onSubmit'] = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const valueDate = new Date(values.date);
    const dateConstructor = new Date(valueDate.getFullYear(), valueDate.getMonth(), valueDate.getDate());

    const startDateTime = new Date(dateConstructor);
    startDateTime.setHours(new Date(values.start).getHours());
    startDateTime.setMinutes(new Date(values.start).getMinutes());

    const finishDateTime = new Date(dateConstructor);
    finishDateTime.setHours(new Date(values.finish).getHours());
    finishDateTime.setMinutes(new Date(values.finish).getMinutes());

    const data: NotePutData = {
      id: values.id,
      job: values.job,
      description: values.description,
      start: startDateTime,
      finish: finishDateTime,
    };

    mutate(data, {
      onSuccess: (newData, variables) => {
        queryClient.setQueryData(['notes', { jobId: String(variables.job) }], newData);
        snackbar.showSuccess('Apontamento editado com sucesso.');
        setToggleEdit(undefined);
        resetForm();
      },
      onError: () => {
        setSubmitting(false);
        snackbar.showError('Erro ao editar apontamento.');
      },
      onSettled: (_data, _error, variables) =>
        queryClient.invalidateQueries(['notes', { jobId: String(variables.job) }]),
    });
  };

  const handleClose = () => setToggleEdit(undefined);

  return (
    <Modal open={!!toggleEdit} onClose={handleClose} className="delete-modal-wrapper">
      <div className="delete-modal-container">
        <div className="delete-modal-head">
          <h1>Editar Apontamento</h1>

          <div>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </div>

        <div className="delete-modal-content" style={{ display: 'block' }}>
          <Formik<CustomNotePutData>
            initialValues={
              initialValues ?? {
                id: 0,
                job: 0,
                description: '',
                date: new Date(),
                start: '',
                finish: '',
              }
            }
            onSubmit={handleSubmit}
            validationSchema={noteValidation}
            enableReinitialize
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                {!loading && (
                  <>
                    <Inputs.Input
                      type="datepicker"
                      name="date"
                      label="Data:"
                      values={values}
                      handleChange={(newValue) => setFieldValue('date', newValue)}
                    />

                    <Inputs.Input
                      type="timepicker"
                      name="start"
                      label="Início:"
                      values={values}
                      handleChange={(newValue) => setFieldValue('start', newValue)}
                    />

                    <Inputs.Input
                      type="timepicker"
                      name="finish"
                      label="Fim:"
                      values={values}
                      handleChange={(newValue) => setFieldValue('finish', newValue)}
                    />

                    <Inputs.Input
                      type="text"
                      name="description"
                      label="Descrição:"
                      placeholder="Ex: Foi realizado um teste de integração..."
                      id="description"
                      values={values}
                      handleChange={(newValue: string) => setFieldValue('description', newValue)}
                    />
                  </>
                )}

                {loading && <Spinner />}

                <div style={{ display: 'flex', gap: 5, justifyContent: 'flex-end' }}>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || loading}
                    loadingPosition="center"
                    variant="contained"
                    startIcon={<Save />}
                  >
                    Salvar
                  </LoadingButton>

                  <Button variant="outlined" onClick={handleClose} startIcon={<Cancel />}>
                    Cancelar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};
