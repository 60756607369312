import { RequestPaginated, ServiceData, ServicePatchThumbnailData, ServicePostData, ServicePutData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/services';

export const fetchServices = async (page = 0, pageSize = 10): Promise<RequestPaginated<ServiceData>> => {
  const { data } = await api.get(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}`);
  return data;
};

export const fetchServiceById = async (id: number): Promise<ServiceData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': '*/*',
  },
};

type ServicePostKeys = keyof ServicePostData;

export const createService = async (data: ServicePostData): Promise<ServiceData> => {
  const formData = new FormData();

  for (const key in data) {
    if (typeof data[key as ServicePostKeys] === 'boolean') {
      formData.append(key, String(data[key as ServicePostKeys]));
    }
    formData.append(key, data[key as ServicePostKeys] as string | Blob);
  }

  const { data: response } = await api.post(`${ENDPOINT}`, formData, config);
  return response;
};

type ServicePutKeys = keyof ServicePutData;

export const updateService = async (data: ServicePutData): Promise<ServiceData> => {
  const formData = new FormData();

  for (const key in data) {
    if (typeof data[key as ServicePutKeys] === 'boolean') {
      formData.append(key, String(data[key as ServicePutKeys]));
    }
    formData.append(key, data[key as ServicePutKeys] as string);
  }

  const { data: response } = await api.put(`${ENDPOINT}/${data.id}`, formData, config);
  return response;
};

export const updateServiceThumbnail = async (data: ServicePatchThumbnailData): Promise<ServiceData> => {
  const formData = new FormData();
  formData.append('thumbnail', data.thumbnail);

  const { data: response } = await api.patch(`${ENDPOINT}/${data.id}`, formData, config);
  return response;
};
