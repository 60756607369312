import { useState } from 'react';

// * MUI
import { Edit, ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';

// * Components
import { Head } from '@/components/Data';
import { JobDetailsDescriptionContainer } from '../components';
import { JobDetailsDescriptionEditStatus } from './EditStatus';
import { JobDetailsDescriptionItem } from './Item';

// * Hooks & Utils
import { ICustomSnackbar, usePermission } from '@/hooks';
import { brazilianizeBooleans, toHoursAndMinutes } from '@/helpers';
import { useNavigate } from 'react-router-dom';

// * Models
import { JobData, AuthData } from '@/models';

// * Permissions
import { JOBS_PERMISSIONS } from '@/constants/permissions';

interface IJobsDetailsDescriptionProps {
  job: JobData;
  snackbar: ICustomSnackbar;
  user: AuthData;
}

export const JobsDetailsDescription = ({ job, snackbar, user }: IJobsDetailsDescriptionProps) => {
  const { verifyPermission } = usePermission();
  const navigate = useNavigate();
  const [toggleEditStatus, setToggleEditStatus] = useState(false);

  const {
    id,
    is_finished,
    customer,
    project: jobProject,
    package: jobPackage,
    package_date,
    requester,
    responsible,
    user_created,
    followers,
    start,
    internal_term,
    customer_term,
    estimated_time,
    title,
    date_created,
    date_updated,
    package_consumption,
    status,
    bonus,
    budget,
    request,
    briefing,
  } = job;

  const isActive = !((jobPackage && !jobPackage.is_active) ?? (jobProject && !jobProject.is_active));

  const editPermission =
    (user.id === responsible.id ||
      user.id === requester.id ||
      user.id === user_created.id ||
      followers.some((follower) => follower === user.id) ||
      (jobPackage && jobPackage.responsible?.id === user.id)) &&
    !is_finished &&
    isActive;

  return (
    <>
      <Head>
        <div style={{ fontSize: '1.4rem', fontWeight: 700 }}>Descrição: </div>

        <div>
          {!is_finished && verifyPermission([JOBS_PERMISSIONS.CHANGE]) && isActive && (
            <IconButton className="data-grid-edit-icon" onClick={() => navigate(`/jobs/edit/${id}`)} title="Editar job">
              <Edit />
            </IconButton>
          )}

          {verifyPermission([JOBS_PERMISSIONS.ADD]) && isActive && (
            <IconButton onClick={() => navigate(`/jobs/duplicate/${id}`)} title="Duplicar job">
              <ContentCopy />
            </IconButton>
          )}
        </div>
      </Head>

      <JobDetailsDescriptionEditStatus
        job={job}
        toggleEditStatus={toggleEditStatus}
        setToggleEditStatus={setToggleEditStatus}
        snackbar={snackbar}
      />

      <JobDetailsDescriptionContainer>
        {job && (
          <>
            <JobDetailsDescriptionItem label="Id">{id}</JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem
              label="Cliente"
              clickable
              onClick={() => window.open(`/jobs/?customer=${customer.id}`, '_blank')}
              title="Listar jobs ativos deste cliente"
            >
              {customer.name}
            </JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem
              label="Projeto"
              clickable
              condition={!!jobProject}
              onClick={() => window.open(`/jobs/?project=${jobProject?.id}`, '_blank')}
              title="Listar jobs ativos deste projeto"
            >
              {jobProject && jobProject.name}
            </JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem
              label="Pacote"
              clickable
              condition={!!jobPackage}
              onClick={() => window.open(`/jobs/?package=${jobPackage?.id}`, '_blank')}
              title="Listar jobs ativos deste pacote"
            >
              {jobPackage && jobPackage.name}
            </JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem
              label="Mês/Ano"
              clickable
              condition={!!jobPackage}
              onClick={() => window.open(`/jobs/?package=${jobPackage?.id}&package_date=${package_date}`, '_blank')}
              title={`Listar jobs ativos deste pacote feitos em ${package_date?.split('-')[1]}/${
                package_date?.split('-')[0]
              }`}
            >
              {`${package_date?.split('-')[1]}/${package_date?.split('-')[0]}`}
            </JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem
              label="Solicitante"
              clickable
              onClick={() => window.open(`/jobs/?requester=${requester.id}`, '_blank')}
              title="Listar jobs ativos deste solicitante"
            >
              {requester.name}
            </JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem
              label="Responsável"
              clickable
              onClick={() => window.open(`/jobs/?responsible=${responsible.id}`, '_blank')}
              title="Listar jobs ativos deste responsável"
            >
              {responsible.name}
            </JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem label="Previsão de Início">
              {new Date(start).toLocaleDateString()}
            </JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem label="Prazo Interno">
              {new Date(internal_term).toLocaleDateString()}
            </JobDetailsDescriptionItem>

            {verifyPermission([JOBS_PERMISSIONS.VIEW_JOB_CUSTOMER_TERM]) ? (
              <JobDetailsDescriptionItem label="Prazo Cliente">
                {new Date(customer_term).toLocaleDateString()}
              </JobDetailsDescriptionItem>
            ) : (
              <JobDetailsDescriptionItem label="Tempo Estimado">
                {toHoursAndMinutes(estimated_time)}
              </JobDetailsDescriptionItem>
            )}

            <JobDetailsDescriptionItem label="Título">{title}</JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem label="Data de Criação">
              {new Date(date_created).toLocaleString()}
            </JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem label="Última Atualização">
              {new Date(date_updated).toLocaleString()}
            </JobDetailsDescriptionItem>

            {verifyPermission([JOBS_PERMISSIONS.VIEW_JOB_CUSTOMER_TERM]) && (
              <JobDetailsDescriptionItem label="Tempo Estimado">
                {toHoursAndMinutes(estimated_time)}
              </JobDetailsDescriptionItem>
            )}

            {verifyPermission([JOBS_PERMISSIONS.VIEW_JOB_PACKAGE_CONSUMPTION]) && (
              <JobDetailsDescriptionItem label="Consumo do pacote">
                {toHoursAndMinutes(package_consumption)}
              </JobDetailsDescriptionItem>
            )}

            <JobDetailsDescriptionItem
              label="Status"
              flag
              background={status.color}
              {...(editPermission &&
                verifyPermission([JOBS_PERMISSIONS.CHANGE_JOB_STATUS]) && {
                  onClick: () => setToggleEditStatus(!toggleEditStatus),
                })}
              title={editPermission ? 'Editar status deste job' : 'Não é possível editar o status deste job.'}
            >
              {status.name}
            </JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem label="Bônus">{(bonus && bonus.reason) ?? 'Não'}</JobDetailsDescriptionItem>

            <JobDetailsDescriptionItem label="Orçamento">{brazilianizeBooleans(budget)}</JobDetailsDescriptionItem>

            {request && (
              <JobDetailsDescriptionItem
                label="Solicitação"
                clickable
                onClick={() => {
                  if (jobPackage) {
                    window.open(`/requests/package/${jobPackage.id}/details/${request.id}`, '_blank');
                  } else if (jobProject) {
                    window.open(`/requests/project/${jobProject.id}/details/${request.id}`, '_blank');
                  }
                }}
                title="Ver detalhes desta solicitação"
              >
                {request.title}
              </JobDetailsDescriptionItem>
            )}

            <div style={{ gridColumn: '1/-1' }} />

            <JobDetailsDescriptionItem label="Briefing" wide>
              <div className="briefing" dangerouslySetInnerHTML={{ __html: briefing }} />
            </JobDetailsDescriptionItem>
          </>
        )}
      </JobDetailsDescriptionContainer>
    </>
  );
};
