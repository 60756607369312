import { RequestPaginated, GroupData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/groups';

export const fetchGroups = async (page = 0, pageSize = 10): Promise<RequestPaginated<GroupData>> => {
  const { data } = await api.get(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}`);
  return data;
};

export const fetchAllGroups = async (): Promise<GroupData[]> => {
  const { data } = await api.get(`${ENDPOINT}?paginate=false`);
  return data;
};

export const fetchGroupById = async (id: number): Promise<GroupData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

export const fetchGroupsById = async (id: number[]): Promise<GroupData[]> => {
  const { data } = await api.get(`${ENDPOINT}?ids=${id.join(',')}&paginate=false`);
  return data;
};
