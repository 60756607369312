// * Theme
import { ThemeProvider } from '@mui/material';
import { theme } from 'themes';

// * Snackbar
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import { CustomSnackbar } from '@/components';

// * Routes
import { Router } from './routes';

// * React Query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// * Google OAuth
import { GoogleOAuthProvider } from '@react-oauth/google';

// * Styles
import './scss/index.scss';
import 'react-virtualized/styles.css';

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';

const Layout = () => (
  <SnackbarProvider
    SnackbarProps={{
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    }}
    SnackbarComponent={CustomSnackbar}
  >
    <GoogleOAuthProvider clientId={clientId}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Router />

          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </SnackbarProvider>
);

export default Layout;
