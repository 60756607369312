import { RequestPaginated, StatusData, StatusFilterData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/status';

export const fetchStatuses = async (page = 0, pageSize = 10): Promise<RequestPaginated<StatusData>> => {
  const { data } = await api.get(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}`);
  return data;
};

export const fetchStatusesToFilter = async (): Promise<StatusFilterData[]> => {
  const { data } = await api.get('/filters/status');
  return data;
};

export const fetchStatusById = async (id: number): Promise<StatusData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

export const fetchAllStatuses = async (): Promise<StatusData[]> => {
  const { data } = await api.get(`${ENDPOINT}?paginate=false`);
  return data;
};
