import * as Yup from 'yup';

/**
 * ? Validation schema for comment
 */

export const commentValidation = Yup.object().shape({
  job: Yup.number().required(),
  comment: Yup.string().required('O campo comentário é obrigatório.'),
});
