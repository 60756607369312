import * as Yup from 'yup';

/**
 * ? Validation schema for media
 */

export const mediaValidation = Yup.object().shape({
  request: Yup.number().nullable(),
  name: Yup.string().required('O campo nome é obrigatório.'),
  files: Yup.mixed().test('file-required', 'O campo arquivo é obrigatório.', (value) => value && value.length > 0),
});
