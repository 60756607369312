// * Hooks & Utils
import { UseMutationResult } from 'react-query';

// * Services
import { StartResponse, StartProps, DoResponse, DoProps, FinishResponse, FinishProps } from '@/services';
import { AxiosProgressEvent } from 'axios';

type StartMutation = UseMutationResult<StartResponse, unknown, StartProps, unknown>;
type DoMutation = UseMutationResult<DoResponse, unknown, DoProps, unknown>;
type FinishMutation = UseMutationResult<FinishResponse, unknown, FinishProps, unknown>;

interface IUploadFileProps {
  endpointName: 'app' | 'job' | 'request' | 'service';
  endpointId: number | undefined;
  startMutation: StartMutation;
  doMutation: DoMutation;
  finishMutation: FinishMutation;
  doProgressCallback?: ((progressEvent: AxiosProgressEvent) => void) | undefined;
  onError: (error: unknown) => void;
  file: File;
  name: string;
}

export const uploadFile = async ({
  endpointName,
  endpointId,
  startMutation,
  doMutation,
  finishMutation,
  doProgressCallback,
  onError,
  file,
  name,
}: IUploadFileProps) => {
  try {
    const startData = await startMutation.mutateAsync({ [endpointName]: endpointId, name, file_name: file.name });
    const { media_id } = await doMutation.mutateAsync({ data: startData, file, progressCallback: doProgressCallback });
    await finishMutation.mutateAsync({ media_id });
  } catch (error) {
    onError(error);
  }
};
