/**
 * Function that receive a quantity number of dates and an initial date and returns an array of dates
 * @param qtd number
 * @param initial Date
 * @returns Date[]
 */

export const getDays = (qtd: number, initial: Date): Date[] => {
  const date = new Date(initial);

  const days = [new Date(date.setDate(date.getDate()))];

  for (let i = 0; i < qtd; i++) {
    const day = new Date(date.setDate(date.getDate() + 1));
    days.push(day);
  }

  return days;
};
