// * MUI
import { CircularProgress, SxProps } from '@mui/material';

// * Components
import { SpinnerContainer } from './components';

interface ISpinnerProps {
  sx?: SxProps;
}

export const Spinner = ({ sx }: ISpinnerProps) => (
  <SpinnerContainer sx={sx}>
    <CircularProgress />
  </SpinnerContainer>
);
