// * MUI
import { styled } from '@mui/material';

export const BreadcrumbContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  margin: '-0.4rem 0 0.4rem 0',
  gap: '0.2rem',
  svg: { fontSize: '1.2rem', opacity: '0.8' },
  a: {
    // 'color': '#000000',
    'color': '#17162E',
    'opacity': '0.8',
    'textDecoration': 'none',
    '&:last-child': { opacity: 1 },
  },
});
