// * MUI
import { Avatar } from '@mui/material';

// * Hooks
import { useColorFromText } from '@/hooks';

// * Models
import { AuthData } from '@/models';

interface IHeaderAccountUserIconProps {
  user: AuthData;
}

export const HeaderAccountUserIcon = ({ user }: IHeaderAccountUserIconProps) => {
  const { bgColor, textColor } = useColorFromText(user.first_name);
  const sx = { width: 34, height: 34, bgcolor: bgColor, color: textColor };

  return <Avatar sx={sx}>{user.first_name[0].toLocaleUpperCase()}</Avatar>;
};
