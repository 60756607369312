// * MUI
import { AccountCircle } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';

// * Components
import { HeaderAccountDropdownItem } from './Item';
import { HeaderAccountDropdownContainer, HeaderAccountDropdownContainerLogout } from './components';

// * Hooks
import { useAuth } from '@/hooks';

interface IHeaderAccountDropdownProps {
  toggle: () => void;
  closing: boolean;
}

export const HeaderAccountDropdown = ({ toggle, closing }: IHeaderAccountDropdownProps) => {
  const { logout } = useAuth();

  return (
    <ClickAwayListener onClickAway={toggle}>
      <HeaderAccountDropdownContainer closing={closing}>
        <HeaderAccountDropdownItem label="Minha conta" link="/profile" icon={<AccountCircle />} />

        <hr />

        <HeaderAccountDropdownContainerLogout onClick={logout}>Sair do Jobber</HeaderAccountDropdownContainerLogout>
      </HeaderAccountDropdownContainer>
    </ClickAwayListener>
  );
};
