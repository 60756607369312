// * Contexts
import { AuthProvider } from 'contexts/AuthProvider';

// * React-Router-Dom
import { Outlet } from 'react-router-dom';

export const AuthLayout = () => (
  <AuthProvider>
    <Outlet />
  </AuthProvider>
);
