// * Mui
import { Select as _Select, SelectProps } from '@mui/material';

function Select({
  name,
  required,
  disabled,
  placeholder,
  multiple,
  value,
  onChange,
  id,
  children,
}: SelectProps): JSX.Element {
  return (
    <_Select
      name={name}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      multiple={multiple}
      className="form-input"
      value={value}
      onChange={onChange}
      id={id}
    >
      {children}
    </_Select>
  );
}

export default Select;
