// * MUI
import { Delete, Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@mui/material';

// * Models
import { MediaData } from '@/models';

interface IScreenshotsDeleteProps {
  toggleDelete: MediaData | null;
  setToggleDelete: (value: React.SetStateAction<MediaData | null>) => void;
  handleDelete: (data: MediaData) => void;
  loading: boolean;
}

export const ScreenshotsDelete = ({
  toggleDelete,
  setToggleDelete,
  handleDelete,
  loading,
}: IScreenshotsDeleteProps) => {
  const handleClose = () => setToggleDelete(null);

  return (
    <Dialog open={!!toggleDelete} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle id="dialog-title">Deletar {toggleDelete?.name}?</DialogTitle>

      <DialogContent dividers>
        <Typography gutterBottom>Tem certeza que deseja deletar a imagem {toggleDelete?.name}?</Typography>

        <Typography gutterBottom>
          <strong>Essa ação não pode ser desfeita.</strong>
        </Typography>

        <img src={toggleDelete?.file} title={toggleDelete?.name} alt={toggleDelete?.name} width={100} height={100} />
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={() => handleDelete(toggleDelete as MediaData)}
          loading={loading}
          loadingPosition="center"
          variant="contained"
          startIcon={<Delete />}
        >
          Deletar
        </LoadingButton>

        <Button variant="outlined" onClick={handleClose} startIcon={<Cancel />}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
