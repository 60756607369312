import * as Yup from 'yup';

/**
 * ? Validation schema for note
 */

export const noteValidation = Yup.object().shape(
  {
    description: Yup.string(),
    date: Yup.date().required().min(new Date('1970-01-01'), 'A data deve ser maior que 1970.'),
    start: Yup.date()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('O campo início é obrigatório.')
      .min(new Date('1970-01-01'), 'A data deve ser maior que 1970.')
      .when('finish', (val, schema) => val && schema.max(val, 'O campo início não pode ser maior que o campo fim.')),
    finish: Yup.date()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('O campo fim é obrigatório.')
      .min(new Date('1970-01-01'), 'A data deve ser maior que 1970.')
      .when('start', (val, schema) => val && schema.min(val, 'O fim deve ser maior que o início.')),
  },
  [['start', 'finish']],
);
