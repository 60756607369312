import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Customers = loadable(async () => import('pages/app/Data'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      home: components.DataCustomersHome,
      details: components.DataCustomersDetails,
      add: components.DataCustomersAdd,
      edit: components.DataCustomersEdit,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `customers-${props.page}`,
});
