import { JobReportData, ReportGenerateData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/reports/job';

type ReportJobFilters = {
  customer?: string;
  project?: string;
  package?: string;
  requester?: string;
  responsible?: string;
  status?: string;
  bonus?: string;
  request?: string;
  budget?: string;
  title?: string;
  start: string;
  finish: string;
};

export const fetchReportJob = async (filters: ReportJobFilters): Promise<JobReportData[]> => {
  const params = `?${String(new URLSearchParams(filters))}`;
  const { data } = await api.get(`${ENDPOINT}${params}`);
  return data;
};

export const fetchGenerateReportJob = async (filters: ReportJobFilters): Promise<ReportGenerateData> => {
  const params = `?${String(new URLSearchParams(filters))}&generate=true`;
  const { data } = await api.get(`${ENDPOINT}${params}`);
  return data;
};
