import { Fragment, useState } from 'react';

// * MUI
import { Cancel, Edit, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

// * Components
import { Inputs } from '@/components';

// * Helpers & Utils
import { ICustomSnackbar } from '@/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { Form, Formik } from 'formik';

// * Models
import { NotificationData } from '@/models';

// * Services
import { updateUserNotifications } from '@/services';

interface IUserNotificationProps {
  userId: string;
  notification: NotificationData;
  snackbar: ICustomSnackbar;
}

export const UserNotification = ({ userId, notification, snackbar }: IUserNotificationProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(updateUserNotifications);

  const [open, setOpen] = useState(false);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    setSubmitting(true);

    const notifications = Object.keys(values).reduce((acc, cur) => {
      const extractIds = (key: string) => {
        const [_, notificationId, __, serviceId] = key.split('-');

        return { notificationId, serviceId };
      };

      const { notificationId, serviceId } = extractIds(cur);

      const notification = acc.find((notification: any) => notification.id === notificationId);

      if (notification) {
        const service = notification.services.find((service: any) => service.id === serviceId);

        if (service) {
          service.checked = values[cur];
        } else {
          notification.services.push({ id: serviceId, checked: values[cur] });
        }
      } else {
        acc.push({
          id: notificationId,
          services: [{ id: serviceId, checked: values[cur] }],
        });
      }

      return acc;
    }, [] as any);

    const data = { user: userId, notifications };

    mutate(data, {
      onSuccess: (data) => {
        queryClient.setQueryData(['notification', { userId }], data);
        snackbar.showSuccess('Notificações editadas com sucesso.');
        setOpen(!open);
      },
      onError: (error: any) => {
        setSubmitting(false);
        snackbar.showError(`Erro ao editar notificações: ${error.response.data.error}`);
      },
      onSettled: () => queryClient.invalidateQueries(['notification', { userId }]),
    });
  };

  const handleToggle = () => setOpen(!open);
  const initialValues = notification.notifications.reduce(
    (acc, cur) =>
      Object.assign(
        acc,
        cur.services.reduce(
          (acc2, cur2) => Object.assign(acc2, { [`notification-${cur.id}-service-${cur2.id}`]: cur2.checked }),
          {},
        ),
      ),
    {},
  );

  return (
    <>
      <Dialog open={open} fullWidth disableEscapeKeyDown maxWidth="lg">
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <DialogTitle sx={{ fontSize: '1.5rem' }}>Editar notificações</DialogTitle>

              <DialogContent>
                <DialogContentText component="div">
                  {notification.notifications.map((notification) => (
                    <Fragment key={notification.id}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>{notification.name}</span>

                        <span style={{ fontSize: '0.9rem' }}>{notification.description}</span>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {notification.services.map((service) => (
                          <Inputs.Input
                            key={service.id}
                            type="checkbox"
                            name={`notification-${notification.id}-service-${service.id}`}
                            label={service.name}
                            id={`notification-${notification.id}-service-${service.id}`}
                            values={values}
                            customHandleChange={(e: React.ChangeEvent<any>) =>
                              setFieldValue(`notification-${notification.id}-service-${service.id}`, e.target.checked)
                            }
                          />
                        ))}
                      </div>
                    </Fragment>
                  ))}
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  loadingPosition="center"
                  variant="contained"
                  startIcon={<Save />}
                >
                  Salvar
                </LoadingButton>

                <Button variant="outlined" onClick={handleToggle} startIcon={<Cancel />}>
                  Cancelar
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <IconButton className="data-grid-edit-icon" onClick={handleToggle}>
        <Edit />
      </IconButton>
    </>
  );
};
