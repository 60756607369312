import { useState } from 'react';

// * MUI
import { Cancel, Add as AddIcon, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, Button, Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// * Components
import { Inputs } from '@/components';

// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { Formik, Form, FormikConfig } from 'formik';
import { EditorState } from 'draft-js';
import { useMutation, useQueryClient } from 'react-query';

// * Constants
import { commentValidation } from '@/constants/validations/comment';

// * Models
import { JobData, CommentPostData } from '@/models';

// * Services
import { createComment } from '@/services';

interface IProps {
  job: JobData;
  snackbar: ICustomSnackbar;
}

export const AddModal = ({ job, snackbar }: IProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(createComment);
  const [isOpen, setIsOpen] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const handleSubmit: FormikConfig<CommentPostData>['onSubmit'] = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    mutate(values, {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(['comments', { jobId: String(variables.job) }], data);
        snackbar.showSuccess('Comentário adicionado com sucesso.');
        setIsOpen(false);
        setEditorState(EditorState.createEmpty());
        resetForm();
      },
      onError: () => {
        setSubmitting(false);
        snackbar.showError('Erro ao adicionar comentário.');
      },
      onSettled: (_data, _error, variables) =>
        queryClient.invalidateQueries(['comments', { jobId: String(variables.job) }]),
    });
  };

  const handleToggle = () => setIsOpen(!isOpen);

  const initialValues = { job: job.id, comment: '' };

  return (
    <>
      <Dialog open={!!isOpen} onClose={handleToggle} maxWidth="lg">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={commentValidation}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <DialogTitle id="dialog-title">Criar comentário</DialogTitle>

              <DialogContent>
                {job.finish && (
                  <Alert severity="warning">
                    Esse Job já foi finalizado, ao inserir um novo comentário, ele não poderá ser alterado ou removido!
                  </Alert>
                )}

                <Inputs.Input
                  type="editor"
                  name="comment"
                  label="Comentário *:"
                  placeholder="Ex: Foi realizado um teste de integração..."
                  id="comment"
                  editorState={editorState}
                  setEditorState={setEditorState}
                  customHandleChange={(content) => setFieldValue('comment', content)}
                />
              </DialogContent>

              <DialogActions>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  loadingPosition="center"
                  variant="contained"
                  startIcon={<Save />}
                >
                  Salvar
                </LoadingButton>

                <Button variant="outlined" onClick={handleToggle} startIcon={<Cancel />}>
                  Cancelar
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <IconButton className="data-grid-add-icon" onClick={handleToggle}>
        <AddIcon />
      </IconButton>
    </>
  );
};
