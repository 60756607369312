/*
 * Brazilianize date
 * @param date - Date string, number or Date object
 * @param time - Boolean to include time
 */
export const brazilianizeDate = (d: string | number | Date, time = false): string => {
  const date = new Date(d);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hour = String(date.getHours()).padStart(2, '0');
  const minute = String(date.getMinutes()).padStart(2, '0');
  const second = String(date.getSeconds()).padStart(2, '0');

  if (time) {
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  }
  return `${day}/${month}/${year}`;
};
