import { memo } from 'react';

// * MUI
import { TableCell, Input, Checkbox } from '@mui/material';

// * Components
import { Datepicker } from 'components/Inputs';

// * Interfaces
import type { IContract } from '.';

interface IRenderEditComponentProps {
  name: keyof IContract;
  contractsLength: number;
  row: IContract;
  onChange: (name: keyof IContract, value: number | Date | boolean | null, id: number) => void;
}

const renderDate = (date: Date | null) => (date ? new Date(date).toLocaleDateString('pt-BR') : '');

function RenderEditComponent({ name, contractsLength, row, onChange }: IRenderEditComponentProps): JSX.Element {
  const isHour = name === 'hours';
  const isEmpty = row[name] === 0;

  const dateSetValue = (value: Date | null) => onChange(name, value, row.id);

  switch (name) {
    case 'date':
      // * If there is only one contract, render a datepicker
      // * If there is more than one contract, render the date as a string (dd/mm/yyyy)
      // * Because only the first contract can have the date set
      if (contractsLength > 1) return <>{renderDate(row[name])}</>;

      return <Datepicker value={row[name]} setValue={dateSetValue} />;

    case 'validity':
      return (
        <Datepicker
          label={!row.is_indeterminate && row[name] === null ? '* Obrigatório' : undefined}
          value={row[name]}
          setValue={dateSetValue}
          disabled={row.is_indeterminate}
        />
      );

    case 'is_indeterminate':
      return <Checkbox checked={row[name]} onChange={(e) => onChange(name, e.target.checked, row.id)} />;

    default:
      return (
        <Input
          type="number"
          required
          placeholder={isHour && isEmpty ? '* Obrigatório' : undefined}
          name={name}
          value={isHour && isEmpty ? null : row[name]}
          color={isHour && isEmpty ? 'error' : 'primary'}
          onChange={(e) => onChange(name, parseFloat(e.target.value), row.id)}
        />
      );
  }
}

interface IRenderValueProps {
  name: keyof IContract;
  row: IContract;
}

function RenderValue({ name, row }: IRenderValueProps): JSX.Element {
  switch (name) {
    case 'date':
      return <>{renderDate(row[name])}</>;

    case 'validity':
      return <>{row[name] instanceof Date ? renderDate(row[name]) : 'Indeterminado'}</>;

    case 'is_indeterminate':
      return <>{row[name] ? 'Sim' : 'Não'}</>;

    default:
      return <>{row[name]}</>;
  }
}

interface ICustomTableCellProps extends IRenderEditComponentProps {
  isEditable: boolean;
}

function CustomTableCell({ contractsLength, row, name, onChange, isEditable }: ICustomTableCellProps): JSX.Element {
  return (
    <TableCell>
      {isEditable ? (
        row.isEditMode ? (
          <RenderEditComponent name={name} contractsLength={contractsLength} row={row} onChange={onChange} />
        ) : (
          <RenderValue name={name} row={row} />
        )
      ) : (
        <RenderValue name={name} row={row} />
      )}
    </TableCell>
  );
}

export default memo(CustomTableCell);
