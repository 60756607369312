// * MUI
import { IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';

interface IDeleteButtonProps {
  id: number;
  onDelete: () => void;
  disabled?: boolean;
  cannotDelete?: boolean;
}

const DeleteButton = ({ onDelete, disabled, cannotDelete }: IDeleteButtonProps) => (
  <Tooltip title={cannotDelete ? 'Não é possível excluir o único contrato' : 'Excluir contrato'}>
    <div style={{ display: 'unset' }}>
      <IconButton onClick={onDelete} disabled={disabled || cannotDelete}>
        <Delete />
      </IconButton>
    </div>
  </Tooltip>
);

export default DeleteButton;
