// * MUI
import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          borderLeft: '0px !important',
        },
      },
    },
  },
  // MuiButton: {
  //   styleOverrides: {
  //     contained: {
  //       'backgroundColor': '#005CFF',
  //       '&:hover': {
  //         backgroundColor: '#00244F',
  //       },
  //     },
  //     outlined: {
  //       'color': '#00244F',
  //       'borderColor': '#00244F',
  //       '&:hover': {
  //         borderColor: '#005CFF',
  //       },
  //     },
  //   },
  // },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    easing: {
      easeOut: '0.4s ease-out',
      easeIn: '0.4s ease-in',
    },
  },
});
