// * MUI
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopTimePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';

// * Hooks & Utils
import brLocale from 'date-fns/locale/pt-BR';

interface IProps {
  value: Date | null;
  setValue: (value: Date | null) => void;
  label?: string;
  disabled?: boolean;
}

function Timepicker({ value, setValue, label, disabled }: IProps): JSX.Element {
  const inputFormat = 'HH:mm';

  const formattedValue = value ? new Date(value) : null;

  const props = { value: formattedValue, onChange: setValue, label, disabled, format: inputFormat };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
      {window.innerWidth < 768 ? <MobileTimePicker {...props} /> : <DesktopTimePicker {...props} />}
    </LocalizationProvider>
  );
}

export default Timepicker;
