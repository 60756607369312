import { useState } from 'react';

// * MUI
import { Button, IconButton, Modal } from '@mui/material';
import { Cancel, Close, Edit, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

// * Components
import { Inputs, DataFormActions } from '@/components';

// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

// * Models
import { MediaData } from '@/models';

// * Services
import { updateMediaOrder } from '@/services';

interface IScreenshotsReorderProps {
  screenshots: MediaData[];
  snackbar: ICustomSnackbar;
  queryKey: Array<string | { [x: string]: string | undefined }>;
}

export const ScreenshotsReorder = ({ screenshots, snackbar, queryKey }: IScreenshotsReorderProps) => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(updateMediaOrder);
  const [open, setOpen] = useState(false);

  const initialValues =
    Array.isArray(screenshots) && screenshots.length > 0
      ? screenshots.reduce((acc, { id, order = 0 }) => ({ ...acc, [`media-${id}`]: order }), {})
      : {};

  const errorLog = (error: any) => {
    console.error(error);
    snackbar.showError('Erro ao reordenar screenshot(s).');
  };

  const handleOpen = () => setOpen(!open);

  const handleSubmit = async (values: { [key: string]: number }, { setSubmitting }: any) => {
    setSubmitting(true);

    const orderedMedias = Object.keys(values).map((key) => ({
      id: Number(key.split('-')[1]),
      order: values[key],
    }));

    for (let i = 0, len = Object.keys(values).length; i < len; i++) {
      const { id, order } = orderedMedias[i];

      try {
        await mutateAsync({ id, order })
          .then(() => snackbar.showSuccess('Screenshot(s) reordenado(s) com sucesso.'))
          .catch((error) => errorLog(error));
      } catch (error) {
        setSubmitting(false);
        errorLog(error);
      }
    }

    queryClient.invalidateQueries(queryKey);
    setSubmitting(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(_, reason) => reason !== 'backdropClick' && handleOpen()}
        className="delete-modal-wrapper"
      >
        <div className="delete-modal-container">
          <div className="delete-modal-head">
            <h1>Reordenar screenshots</h1>

            <div>
              <IconButton onClick={handleOpen}>
                <Close />
              </IconButton>
            </div>
          </div>

          <div className="delete-modal-content" style={{ display: 'block' }}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                      gap: '1rem',
                      textAlign: 'center',
                    }}
                  >
                    {screenshots.map((screenshot) => (
                      <div key={screenshot.id}>
                        <img src={screenshot.file} alt={screenshot.name} width={150} height={150} />

                        <Inputs.Input
                          type="number"
                          name={`media-${screenshot.id}`}
                          label="Ordem:"
                          id={`media-${screenshot.id}`}
                          values={values}
                          handleChange={setFieldValue}
                        />
                      </div>
                    ))}
                  </div>

                  <DataFormActions>
                    <LoadingButton
                      type="submit"
                      loading={isSubmitting}
                      loadingPosition="center"
                      variant="contained"
                      startIcon={<Save />}
                    >
                      Salvar
                    </LoadingButton>

                    <Button variant="outlined" onClick={handleOpen} disabled={isSubmitting} startIcon={<Cancel />}>
                      Cancelar
                    </Button>
                  </DataFormActions>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>

      <Button sx={{ ml: 2 }} variant="contained" startIcon={<Edit />} onClick={handleOpen}>
        Reordenar
      </Button>
    </>
  );
};
