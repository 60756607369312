// * MUI
import { styled } from '@mui/system';

export const HeaderAccountDropdownItemClickable = styled('div')({
  'display': 'flex',
  'flexDirection': 'row-reverse',
  'justifyContent': 'space-between',
  'alignItems': 'center',
  'textDecoration': 'none',
  'cursor': 'pointer',
  // 'color': '#000000',
  'color': '#17162E',
  'padding': '0.4rem 1rem',
  'gap': '1rem',
  'transition': '0.2s ease-in',
  '&:hover': {
    backgroundColor: '#E1E1E170',
    // color: '#00244F',
    color: '#3F19FF',
    transition: '0.2s ease-in',
  },
});
