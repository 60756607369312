// * MUI
import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

// * Components
import { ScheduleItemContainer, ScheduleItemContainerTitle, ScheduleItemTooltipContainer } from './components';

// * Hooks & Utils
import { usePermission } from '@/hooks';
import { toHoursAndMinutes } from '@/helpers';
import format from 'date-fns/format';

// * Models
import { JobData } from '@/models';

// * Permissions
import { JOBS_PERMISSIONS } from '@/constants/permissions';

type IScheduleItemTooltipContainerItemProps = {
  label: string;
  value: string | undefined;
};

const ScheduleItemTooltipContainerItem = ({ label, value }: IScheduleItemTooltipContainerItemProps) => (
  <li>
    <b>{label}:</b> {value}
  </li>
);

interface IScheduleItemProps {
  job: JobData;
}

export const ScheduleItem = ({ job }: IScheduleItemProps) => {
  const { verifyPermission } = usePermission();
  const {
    id,
    title: jobTitle,
    budget,
    customer,
    project: jobProject,
    package: jobPackage,
    start,
    internal_term,
    customer_term,
    qtd_days,
    minutes_per_day,
    estimated_time,
    status,
  } = job;
  const title = `${budget ? '[ORC]' : ''} ${jobTitle}`;
  const resource = jobProject?.name ?? jobPackage?.name;
  const onClickDetails = () => window.open(`/jobs/details/${id}`, '_blank');
  const onClickEdit = () => window.open(`/jobs/edit/${id}`, '_blank');
  const buttonSx = { svg: { width: 18, height: 18 } };

  return (
    <Tooltip
      title={
        <ScheduleItemTooltipContainer>
          <ScheduleItemTooltipContainerItem label="Título" value={title} />

          <ScheduleItemTooltipContainerItem label="Cliente" value={customer.name} />

          <ScheduleItemTooltipContainerItem label="Projeto/Pacote" value={resource} />

          <ScheduleItemTooltipContainerItem label="Inicío" value={format(new Date(start), 'dd/MM/yyyy')} />

          <ScheduleItemTooltipContainerItem
            label="Prazo interno"
            value={format(new Date(internal_term), 'dd/MM/yyyy')}
          />

          <ScheduleItemTooltipContainerItem
            label="Prazo cliente"
            value={format(new Date(customer_term), 'dd/MM/yyyy')}
          />

          <ScheduleItemTooltipContainerItem label="Duração" value={`${qtd_days} dia(s) útil`} />

          <ScheduleItemTooltipContainerItem label="Horas Diárias" value={toHoursAndMinutes(minutes_per_day)} />

          <ScheduleItemTooltipContainerItem label="Tempo Estimado" value={toHoursAndMinutes(estimated_time)} />

          <ScheduleItemTooltipContainerItem label="Status" value={status.name} />
        </ScheduleItemTooltipContainer>
      }
      disableInteractive
      arrow
    >
      <ScheduleItemContainer bgColor={status.color} onClick={onClickDetails}>
        <ScheduleItemContainerTitle>{title}</ScheduleItemContainerTitle>

        {verifyPermission([JOBS_PERMISSIONS.CHANGE]) && (
          <IconButton disableRipple sx={buttonSx} onClick={onClickEdit} size="small">
            <Edit />
          </IconButton>
        )}
      </ScheduleItemContainer>
    </Tooltip>
  );
};
