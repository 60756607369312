import { RequestPaginated, CustomerData, CustomerFilterData, CustomerPostData, CustomerPutData } from '@/models';
import { sortBetweenName } from '@/helpers';
import api from '@/services/api';

const ENDPOINT = '/customers';

export const fetchCustomers = async (page = 0, pageSize = 10): Promise<RequestPaginated<CustomerData>> => {
  const { data } = await api.get(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}`);
  return data;
};

export const fetchCustomerById = async (id: number): Promise<CustomerData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

export const fetchAllCustomers = async (): Promise<CustomerData[]> => {
  const { data } = await api.get(`${ENDPOINT}?paginate=false`);
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const fetchAllActiveCustomers = async (): Promise<CustomerData[]> => {
  const { data } = await api.get(`${ENDPOINT}?paginate=false&is_active=true`);
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const fetchCustomersToFilter = async (): Promise<CustomerFilterData[]> => {
  const { data } = await api.get('/filters/customers');
  const sortedData = sortBetweenName(data);
  return sortedData;
};

export const createCustomer = async (data: CustomerPostData): Promise<CustomerData> => {
  const { data: response } = await api.post(ENDPOINT, data);
  return response;
};

export const updateCustomer = async (data: CustomerPutData): Promise<CustomerData> => {
  const { data: response } = await api.put(`${ENDPOINT}/${data.id}`, data);
  return response;
};
