import { RequestPaginated, RequestStatusData, RequestStatusFilterData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/requeststatus';

export const fetchRequestStatuses = async (page = 0, pageSize = 10): Promise<RequestPaginated<RequestStatusData>> => {
  const { data } = await api.get(`${ENDPOINT}?p=${page + 1}&page_size=${pageSize}`);
  return data;
};

export const fetchRequestStatusesToFilter = async (): Promise<RequestStatusFilterData[]> => {
  const { data } = await api.get('/filters/requeststatus');
  return data;
};

export const fetchRequestStatusById = async (id: number): Promise<RequestStatusData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

export const fetchAllRequestStatuses = async (): Promise<RequestStatusData[]> => {
  const { data } = await api.get(`${ENDPOINT}?paginate=false`);
  const sortedData = data.sort((a: { [x: string]: number }, b: { [x: string]: number }) => {
    return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
  });
  return sortedData;
};
