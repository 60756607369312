import { useState } from 'react';

// * MUI
import { Close, Cancel, Add as AddIcon, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Modal, IconButton, Button } from '@mui/material';

// * Components
import { Inputs } from '@/components';

// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { showError } from '@/helpers';
import { Formik, Form, FormikConfig } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

// * Constants
import { noteValidation } from '@/constants/validations/note';

// * Services
import { createNote } from '@/services';

interface IProps {
  jobId: number;
  snackbar: ICustomSnackbar;
}

interface IFormValues {
  description: string;
  date: Date;
  start: Date;
  finish: Date;
}

export const AddModal = ({ jobId, snackbar }: IProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(createNote);
  const [toggleAdd, setToggleAdd] = useState(false);

  const handleSubmit: FormikConfig<IFormValues>['onSubmit'] = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const valueDate = new Date(values.date);
    const date = new Date(valueDate.getFullYear(), valueDate.getMonth(), valueDate.getDate());

    const start = new Date(values.start);
    const finish = new Date(values.finish);

    const startDateTime = new Date(date);
    startDateTime.setHours(start.getHours());
    startDateTime.setMinutes(start.getMinutes());

    const finishDateTime = new Date(date);
    finishDateTime.setHours(finish.getHours());
    finishDateTime.setMinutes(finish.getMinutes());

    const data = {
      job: jobId,
      description: values.description,
      start: startDateTime,
      finish: finishDateTime,
    };

    mutate(data, {
      onSuccess: () => {
        snackbar.showSuccess('Apontamento inserido com sucesso.');
        resetForm();
      },
      onError: (error: any) => {
        setSubmitting(false);
        showError({ error, snackbar, message: 'Erro ao inserir apontamento' });
      },
      onSettled: (_data, _error, variables) =>
        queryClient.invalidateQueries(['notes', { jobId: String(variables.job) }]),
    });
  };

  const handleToggle = () => setToggleAdd(!toggleAdd);

  const initialValues = {
    description: '',
    date: new Date(),
    start: null as unknown as Date,
    finish: null as unknown as Date,
  };

  return (
    <>
      <Modal open={!!toggleAdd} onClose={handleToggle} className="delete-modal-wrapper">
        <div className="delete-modal-container">
          <div className="delete-modal-head">
            <h1>Inserir Apontamento</h1>

            <div>
              <IconButton onClick={handleToggle}>
                <Close />
              </IconButton>
            </div>
          </div>

          <div className="delete-modal-content" style={{ display: 'block' }}>
            <Formik<IFormValues>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={noteValidation}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Inputs.Input
                    type="datepicker"
                    name="date"
                    label="Data:"
                    values={values}
                    handleChange={(newValue) => setFieldValue('date', newValue)}
                  />

                  <Inputs.Input
                    type="timepicker"
                    name="start"
                    label="Início:"
                    values={values}
                    handleChange={(newValue) => setFieldValue('start', newValue)}
                  />

                  <Inputs.Input
                    type="timepicker"
                    name="finish"
                    label="Fim:"
                    values={values}
                    handleChange={(newValue) => setFieldValue('finish', newValue)}
                  />

                  <Inputs.Input
                    type="text"
                    name="description"
                    label="Descrição:"
                    placeholder="Ex: Foi realizado um teste de integração..."
                    id="description"
                    values={values}
                    handleChange={(newValue: string) => setFieldValue('description', newValue)}
                  />

                  <div style={{ display: 'flex', gap: 5, justifyContent: 'flex-end' }}>
                    <LoadingButton
                      type="submit"
                      loading={isSubmitting}
                      loadingPosition="center"
                      variant="contained"
                      startIcon={<Save />}
                    >
                      Salvar
                    </LoadingButton>

                    <Button variant="outlined" onClick={handleToggle} startIcon={<Cancel />}>
                      Cancelar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>

      <IconButton className="data-grid-add-icon" onClick={handleToggle}>
        <AddIcon />
      </IconButton>
    </>
  );
};
