// * Mui
import { styled } from '@mui/material';

export const InputWrapper = styled('div')({
  display: 'flex',
  alignContent: 'flex-start',
  justifyContent: 'flex-start',
  marginBottom: '1rem',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '0.4rem',
  flex: '30% 1',
  width: '100%',
});

export const InputWrapperLabelContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: 34,
  label: { fontSize: '0.9rem', fontWeight: 500, padding: '0.4rem' },
});

export const InputWrapperContainer = styled('div')({
  'backgroundColor': '#FEFEFE',
  'width': '100%',
  'height': 40,
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'border': '1px solid #E1E1E1',
  'borderRadius': 4,
  '&.switch, &.checkbox': { width: 'max-content', height: 40, margin: 0, border: 'none' },
  '&.editor': { height: 'max-content' },
  '&.editor, &.datepicker, &.timepicker': { justifyContent: 'flex-start' },
  'input': {
    'border': 'none',
    'borderLeft': '1px solid #E1E1E1',
    'width': '100%',
    'height': '100%',
    'paddingLeft': '0.8rem',
    'fontSize': 16,
    '&::placeholder': { color: 'grey' },
  },
  '.css-i4bv87-MuiSvgIcon-root': { margin: '0 0.8rem', width: 18, height: '100%', color: '#262D33' },
  '.input-toggle-password': {
    'cursor': 'pointer',
    'margin': 0,
    'width': 40,
    'height': 38,
    'padding': 10,
    'transition': '0.1s ease-in',
    '&:hover': { backgroundColor: '#f9f9f9', transition: '0.1s ease-in' },
  },
});

export const Container = styled('div')({
  display: 'flex',
  alignContent: 'flex-start',
  justifyContent: 'flex-start',
  marginBottom: '1rem',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const InputContainer = styled('div')({
  'backgroundColor': '#FEFEFE',
  'width': '100%',
  'height': 40,
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'border': '1px solid #E1E1E1',
  'borderRadius': 4,
  '&.switch, &.checkbox': { width: 'max-content', height: 40, margin: 0, border: 'none' },
  '&.editor': { height: 'max-content' },
  '&.editor, &.datepicker, &.timepicker': { justifyContent: 'flex-start' },
  'input': {
    'border': 'none',
    'borderLeft': '1px solid #E1E1E1',
    'width': '100%',
    'height': '100%',
    'paddingLeft': '0.8rem',
    'fontSize': 16,
    '&::placeholder': { color: 'grey' },
  },
  '.css-i4bv87-MuiSvgIcon-root': { margin: '0 0.8rem', width: 18, height: '100%', color: '#262D33' },
  '.input-toggle-password': {
    'cursor': 'pointer',
    'margin': 0,
    'width': 40,
    'height': 38,
    'padding': 10,
    'transition': '0.1s ease-in',
    '&:hover': { backgroundColor: '#F9F9F9', transition: '0.1s ease-in' },
  },
});
