import { useMemo } from 'react';

import { useSnackbar } from 'material-ui-snackbar-provider';

type CustomSnackbarParameters = (
  message: string,
  action?: string | undefined,
  handleAction?: (() => void) | undefined,
  customParameters?: any,
  handleHideWithoutAction?: (() => void) | undefined,
) => any;

export const useCustomSnackbar = () => {
  const snackbar = useSnackbar();

  return useMemo(() => {
    const showMessage =
      (type: string): CustomSnackbarParameters =>
      (message, action, handleAction, customParameters, handleHideWithoutAction) =>
        snackbar.showMessage(message, action, handleAction, { ...customParameters, type }, handleHideWithoutAction);

    return {
      ...snackbar,
      showMessage: showMessage('info'),
      showInfo: showMessage('info'),
      showWarning: showMessage('warning'),
      showError: showMessage('error'),
      showSuccess: showMessage('success'),
    };
  }, [snackbar]);
};

export type ICustomSnackbar = ReturnType<typeof useCustomSnackbar>;
