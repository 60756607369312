import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Events = loadable(async () => import('pages/app/Events'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      home: components.EventsHome,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `events-${props.page}`,
});
