// * ReactDOM
import { createRoot } from 'react-dom/client';

// * Components
import Layout from './Layout';

// * Functions
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<Layout />);

reportWebVitals();
