import { Suspense, useState } from 'react';

// * Components
import { Spinner } from '@/components';
import { Header, Menu } from './components';

// * Hooks & Utils
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { ErrorFallback } from 'routes/ErrorFallback';

export const AppLayout = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Header toggleMenu={setIsOpen} isOpen={isOpen} />

      <div className="main-content-wrapper">
        <Menu toggleMenu={setIsOpen} isOpen={isOpen} />

        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<Spinner />} />

          <Outlet />
        </ErrorBoundary>
      </div>
    </>
  );
};
