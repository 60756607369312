// * MUI
import { styled } from '@mui/material';

export const ScheduleItemTooltipContainer = styled('ul')({
  margin: 0,
  padding: '0.6rem 0.8rem',
  listStyle: 'none',
  fontSize: '0.8rem',
  li: { margin: '0.2rem 0', fontWeight: 300, b: { fontWeight: 600 } },
});

interface IScheduleItemContainerProps {
  bgColor?: string;
}

export const ScheduleItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<IScheduleItemContainerProps>((props) => ({
  backgroundColor: props.bgColor,
  cursor: 'pointer',
  width: '100%',
  boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',
  maxHeight: 20,
  borderRadius: '0.2rem',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ScheduleItemContainerTitle = styled('span')({
  height: '100%',
  margin: '0 0.6rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  fontSize: '0.7rem',
});
