import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Services = loadable(async () => import('pages/app/Data'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      home: components.DataServicesHome,
      details: components.DataServicesDetails,
      add: components.DataServicesAdd,
      edit: components.DataServicesEdit,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `services-${props.page}`,
});
