import { InsightData, InsightPutData } from '@/models';
import api from '@/services/api';

export const fetchInsights = async (): Promise<Array<InsightData>> => {
  const { data } = await api.get(`/users/insights`);
  return data;
};

export const fetchInsightById = async (id: number): Promise<InsightData> => {
  const { data } = await api.get(`/insights/${id}`);
  return data;
};

export const fetchInsightsByCustomerId = async (customerId: string): Promise<Array<InsightData>> => {
  const { data } = await api.get(`/customers/${customerId}/insights`);
  return data;
};

export const updateInsights = async (data: {
  insights: Array<InsightPutData>;
  customerId: string;
}): Promise<InsightData> => {
  const { data: insight } = await api.put(`/customers/${data.customerId}/insights`, [...data.insights]);
  return insight;
};
