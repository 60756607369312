import { PriorityData, PriorityFilterData } from '@/models';
import api from '@/services/api';

const ENDPOINT = '/priorities';

export const fetchPrioritiesToFilter = async (): Promise<PriorityFilterData[]> => {
  const { data } = await api.get('/filters/priorities');
  return data;
};

export const fetchPriorityById = async (id: number): Promise<PriorityData> => {
  const { data } = await api.get(`${ENDPOINT}/${id}`);
  return data;
};

export const fetchAllPriorities = async (): Promise<PriorityData[]> => {
  const { data } = await api.get(`${ENDPOINT}?paginate=false`);
  const sortedData = data.sort((a: { [x: string]: number }, b: { [x: string]: number }) => {
    return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
  });
  return sortedData;
};
