// * Hooks & Utils
import { ICustomSnackbar } from '@/hooks';
import { UseMutationResult } from 'react-query';

// * Services
import { StartResponse, StartProps, DoResponse, DoProps, FinishResponse, FinishProps } from '@/services';

// * Models
import {
  AppData,
  AppPostData,
  IMediaProps,
  RequestData,
  RequestPostData,
  ServiceData,
  ServicePostData,
} from '@/models';

// * Interfaces
import { AxiosProgressEvent } from 'axios';
import { uploadFile } from './uploadFile';

type StartMutation = UseMutationResult<StartResponse, unknown, StartProps, unknown>;
type DoMutation = UseMutationResult<DoResponse, unknown, DoProps, unknown>;
type FinishMutation = UseMutationResult<FinishResponse, unknown, FinishProps, unknown>;

type Datas = AppData | ServiceData | RequestData;
type PostDatas = AppPostData | ServicePostData | RequestPostData;

interface IUploadFilesProps<T extends Datas, U extends PostDatas> {
  resource: 'app' | 'service' | 'request';
  creationResource: UseMutationResult<T, unknown, U, unknown>;
  resourceValues: U;
  setResource: React.Dispatch<React.SetStateAction<T | undefined>>;
  startMutation: StartMutation;
  doMutation: DoMutation;
  finishMutation: FinishMutation;
  snackbar: ICustomSnackbar;
  medias: IMediaProps[];
  setMedias: React.Dispatch<React.SetStateAction<IMediaProps[]>>;
  setSubmitted: (value: React.SetStateAction<boolean>) => void;
}

export const createResourceAndUploadMedias = async <T extends Datas, U extends PostDatas>({
  resource,
  creationResource,
  resourceValues,
  setResource,
  startMutation,
  doMutation,
  finishMutation,
  snackbar,
  medias,
  setMedias,
  setSubmitted,
}: IUploadFilesProps<T, U>) => {
  try {
    const createdResource = await creationResource.mutateAsync(resourceValues);
    medias.length === 0 && setResource(createdResource as T);

    medias.map(async (media, i) => {
      const { name, file } = media;
      media.isUploading = true;

      const doProgressCallback = (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 0));
        media.percent = percentCompleted;
        const newArray = Object.assign([...medias], { [i]: media });

        if (percentCompleted === 100) media.isUploading = false;

        setMedias(newArray);
      };

      const onError = () => {
        setSubmitted(false);
        snackbar.showError('Erro ao adicionar arquivo(s).');
      };

      await uploadFile({
        endpointName: resource,
        endpointId: createdResource.id,
        startMutation,
        doMutation,
        finishMutation,
        doProgressCallback,
        onError,
        file,
        name,
      });

      const uploadeds = medias.filter((med) => med.isUploading === false);

      if (uploadeds.length === medias.length) setResource(createdResource as T);
    });
  } catch (error: any) {
    const errors: any[] = error.response.data;
    const keys = Object.keys(errors);
    setSubmitted(false);
    snackbar.showError(
      `Erro ao inserir ${resource}: ${keys.map((key: any) => Object.values(errors[key]).map((e) => e))}`,
    );
  }
};
