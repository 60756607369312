// * MUI
import { Alert, Button, ButtonProps, Snackbar, SnackbarProps } from '@mui/material';

interface ICustomSnackbarProps {
  message?: string;
  action?: string;
  ButtonProps?: Partial<ButtonProps>;
  SnackbarProps: Partial<SnackbarProps>;
  customParameters: any;
}

export const CustomSnackbar = ({
  message,
  action,
  ButtonProps,
  SnackbarProps,
  customParameters,
}: ICustomSnackbarProps) => {
  return (
    <Snackbar {...SnackbarProps}>
      <Alert
        severity={customParameters?.type}
        action={
          action != null && (
            <Button color="inherit" size="small" {...ButtonProps}>
              {action}
            </Button>
          )
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
