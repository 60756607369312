// * MUI
import { Select, MenuItem, ListSubheader } from '@mui/material';

// * Components
import { InputWrapper, InputWrapperLabelContainer, InputWrapperContainer } from '../components';

interface Data {
  id: string;
  category: string;
  options: any[];
}

interface IProps {
  id: string;
  label: string;
  readOnly?: boolean;
  name: string;
  placeholder: string;
  values: any;
  handleChange?: any;
  excludeEmpty?: boolean;
  data: Data[];
}

export const GroupedSelect = ({
  id,
  label,
  readOnly,
  name,
  placeholder,
  values,
  handleChange,
  excludeEmpty,
  data,
}: IProps) => {
  // * Push one empty option to the beginning of the each options array
  const options = data.map(({ id, category, options }) => ({
    id,
    category,
    options: [{ id: '', name: '', label: category }].concat(options),
  }));

  const generateGroups = () => {
    if (!options || typeof options !== 'object') return;

    return options.map((category) =>
      category.options.map((option, i) => {
        if (i === 0) {
          return (
            <ListSubheader key={option.id} disableSticky>
              {option.label}
            </ListSubheader>
          );
        }

        return (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        );
      }),
    );
  };

  return (
    <InputWrapper>
      {label && (
        <InputWrapperLabelContainer>
          <label htmlFor={id}>{label}</label>
        </InputWrapperLabelContainer>
      )}

      <InputWrapperContainer className="select">
        <Select
          disabled={!!readOnly}
          name={name}
          placeholder={placeholder}
          value={values?.[name] || ''}
          onChange={handleChange}
          label={label}
          id={id}
        >
          {!excludeEmpty && <MenuItem value="" />}
          {generateGroups()}
        </Select>
      </InputWrapperContainer>
    </InputWrapper>
  );
};
