// * MUI
import { Modal as MuiModal, IconButton, styled } from '@mui/material';

export const Modal = styled(MuiModal)({ display: 'grid', placeItems: 'center' });

export const Container = styled('div')({
  'width': '80vw',
  'height': 'auto',
  'background': '#FEFEFE',
  'borderRadius': 4,
  'boxShadow': '0px 2px 6px rgba(0, 0, 0, 0.1)',
  'padding': '1.4rem',
  // '.MuiButton-root': { height: 40 },
  '.MuiButton-root': { 'height': 40, 'backgroundColor': '#3F19FF', '&:hover': { backgroundColor: '#2A10AF' } },
  '.MuiButton-outlined': {
    'color': '#3F19FF',
    'borderColor': '#3F19FF',
    'backgroundColor': 'transparent',
    '&:hover': { color: '#FEFEFE', borderColor: '#2A10AF', backgroundColor: '#2A10AF' },
  },
  '.MuiAlert-root': { marginTop: '-0.6rem', marginBottom: '1.2rem' },
});

export const Head = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  // color: '#000000',
  color: '#17162E',
  marginBottom: '1.4rem',
  h1: { margin: 0 },
});

export const CloseIcon = styled(IconButton)({
  'transition': '0.2s ease-in',
  'cursor': 'pointer',
  // 'color': '#000000',
  'color': '#17162E',
  'svg': { fontSize: '1.8rem' },
  '&:hover': { transition: '0.2s ease-in', color: 'hsl(0, 60%, 65%)' },
});

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  paddingBottom: '1rem',
  alignItems: 'center',
  justifyContent: 'left',
});

export const ModalActions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '2rem',
});

export const Wrapper = styled('div')({
  'display': 'flex',
  'width': '100%',
  ':not(:last-child)': { marginBottom: '1.4rem' },
  '& .MuiAutocomplete-root': { width: '75%' },
});
export const Value = styled('div')({ width: '25%', textAlign: 'center' });
