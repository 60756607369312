/**
 * Handler to check if filter value is valid
 * @param value
 * @returns boolean
 */

export const isFilterValid = (value: string | number | Date | undefined) => {
  if (value === undefined) return false;

  if (typeof value === 'string') return value !== '';

  if (typeof value === 'number') return value !== 0;

  if (value instanceof Date) return value.toString() !== 'Invalid Date';

  return false;
};
