import loadable from '@loadable/component';
import { LoadableProps } from 'routes/common';

export const Recover = loadable(async () => import('pages/auth'), {
  resolveComponent: (components, props: LoadableProps) => {
    const componentMap = {
      home: components.RecoverHome,
      token: components.RecoverToken,
    };
    return componentMap[props.page as keyof typeof componentMap];
  },
  cacheKey: (props: LoadableProps) => `recover-${props.page}`,
});
